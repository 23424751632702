import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NxDialogService, NxModalRef } from '@aposin/ng-aquila/modal';
import { ContactUsDialogComponent } from 'src/app/core/dialogs/contact-us-dialog/contact-us-dialog.component';
import { UserService } from '@agentmax/shared/services/user/user.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactUsComponent {
  private componentDialogRef?: NxModalRef<unknown>;

  constructor(
    private readonly userService: UserService,
    private readonly dialogService: NxDialogService,
  ) {}

  public isNonInternalUser(): boolean {
    return this.userService.userHasPermissionForComponent('cIdExceptInternalUser');
  }

  public openContactModal(): void {
    this.componentDialogRef = this.dialogService.open(ContactUsDialogComponent, {
      ariaLabel: 'Contact Us Dialog',
      showCloseIcon: true,
      disableClose: true,
    });
  }
}
