import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BUConfig, Link } from '../../../shared/types/ui-config.type';
import { DataAccessorService } from '../../../shared/services/data-accessor/data-accessor.service';
import { UserService } from '../../../shared/services/user/user.service';
import { ErrorService } from '@agentmax/shared/services/error/error.service';
import { ConfigurationServiceInterface } from './configuration-service.interface';

const CONFIG_KEYS = {
  constantSymbol: '#',
  currencyConfigKey: 'currencyConfig',
  currencyAmountKey: 'amount',
  currencySymbolKey: 'currency',
  currencyFormatKey: 'currencyFormat',
  currencyHtmlFormatKey: 'currencyHtmlFormat',
  footerLinksConfigKey: 'footerLinksConfig',
  excludedTravelCountriesKey: 'excludedTravelCountries',
  marketingLinksKey: 'marketingMaterial',
};

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService implements ConfigurationServiceInterface {
  constructor(
    private readonly dataAccessorService: DataAccessorService,
    private readonly translateService: TranslateService,
    private readonly userService: UserService,
    private readonly errorService: ErrorService,
  ) {}

  public getConfigCurrencyOrDefault(defaultCurrency: string): string {
    return this.getProperty<string>(
      `${CONFIG_KEYS.currencyConfigKey}.${CONFIG_KEYS.currencySymbolKey}`,
      defaultCurrency,
    );
  }

  public getConfigCurrencyFormat(): string {
    return this.getProperty<string>(
      `${CONFIG_KEYS.currencyConfigKey}.${CONFIG_KEYS.currencyFormatKey}`,
    );
  }

  public shouldShowQuoteOfferFeature(): boolean {
    return this.getProperty<boolean>('saveQuotation.show', false);
  }

  public showMarketingLinks(): boolean {
    return this.getProperty<boolean>(`${CONFIG_KEYS.marketingLinksKey}.show`);
  }

  public getCopyrightText(): string {
    if (this.userService.isLoggedIn()) {
      const copyright = this.getProperty<string>(
        `${CONFIG_KEYS.footerLinksConfigKey}.copyrightText`,
      );
      return this.translateService.instant(copyright);
    }
    return 'footer.0.FOOTER';
  }

  public shouldShowFooterLinks(): boolean {
    if (this.userService.isLoggedIn()) {
      return this.getProperty<boolean>(`${CONFIG_KEYS.footerLinksConfigKey}.show`);
    }
    return false;
  }

  public getFooterLinksHtml(): Link[] {
    if (this.userService.isLoggedIn()) {
      return this.getProperty<Link[]>(`${CONFIG_KEYS.footerLinksConfigKey}.links`, []);
    }
    return [];
  }

  //Getter functions

  private getConfigFile(): BUConfig {
    return this.dataAccessorService.getConfigFile();
  }

  //finds a property given a path
  private findProperty<T = unknown>(path: string): T | undefined {
    let currentPath = this.getConfigFile();
    if (currentPath === undefined) return undefined;
    let pathParts = path.split('.');

    for (let pathPart of pathParts) {
      //As the function is not aware of the type we are traveling we need to cast this as any
      currentPath = (currentPath as any)[pathPart];
      if (!currentPath) return undefined;
    }

    return currentPath as T;
  }

  //finds a property given a path from BU, if not exists then from default
  private getProperty<T = unknown>(path: string, defaultValue?: T): T {
    if (!defaultValue) {
      defaultValue = undefined;
    }
    let property = this.findProperty<T>(path);

    if (property === '1' || property === '0') {
      property = (property == '1') as T;
    }

    if (property === undefined) {
      property = defaultValue;
    }

    if (property === undefined) {
      this.errorService.throwError(
        this.constructor.name,
        `We have no config for that path: ${path} and you didnt provide a defaultValue!`,
      );
    }

    return property;
  }
}
