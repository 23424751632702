import { Injectable } from '@angular/core';
import * as sha from 'sha.js';

import { ShaServiceInterface } from './sha-service.interface';

@Injectable({
  providedIn: 'root',
})
export class ShaService implements ShaServiceInterface {
  public toSHA256(value: string): string {
    return sha('sha256').update(value).digest('hex');
  }
}
