@if (_languages(); as languages) {
  <nx-dropdown
    [value]="languages.selectedCode"
    (selectionChange)="selectLanguage($event.value)"
  >
    @for (language of languages.options | keyvalue; track language.key) {
      <nx-dropdown-item [value]="language.key">{{
    language.value
      }}</nx-dropdown-item>
    }
  </nx-dropdown>
}
