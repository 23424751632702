<div class="feature-toggles-view">
  <table
    nxTable
    aria-describedby="Application Feature Toggles Table"
  >
    <thead>
      <tr nxTableRow>
        <th nxHeaderCell>Key</th>
      </tr>
    </thead>

    <tbody>
      <ng-container *ngIf="_featureToggleList.length !== 0; else emptyListView">
        <tr
          class="table-row"
          nxTableRow
          *ngFor="
            let featureToggle of _featureToggleList;
            trackBy: trackByFeatureToggleKey
          "
        >
          <td
            class="table-cell"
            nxTableCell
          >
            <nx-checkbox
              class="table-cell__checkbox"
              [checked]="featureToggle.value"
              (checkedChange)="
                handleFeatureToggleCheckedChange(featureToggle.key, $event)
              "
            ></nx-checkbox>
            <span class="table-cell__label">{{ featureToggle.name }}</span>
            <span
              class="table-cell__local-text"
              *ngIf="featureToggle.isLocal"
              >(Local)</span
            >
          </td>
        </tr>
      </ng-container>

      <ng-template #emptyListView>
        <tr
          class="table-row"
          nxTableRow
        >
          <td
            class="table-cell"
            nxTableCell
          >
            The list is empty.
          </td>
        </tr>
      </ng-template>
    </tbody>
  </table>
</div>
