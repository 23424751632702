import { SharedService } from '@agentmax/shared/services/compare/compare.service';
import { SearchPolicyTableMapperService } from '@agentmax/core/mappers/search-policy/search-policy-table-mapper.service';
import { DISPLAY_DATE_FORMAT } from '@agentmax/shared/dependencies/display-date-format';
import { CurrencyFormatPipe } from '@agentmax/shared/pipes/currency-format/currency-format.pipe';
import { SearchPolicyResponse } from '@agentmax/shared/types/search-policy-response.type';
import { SearchPolicyTableView } from '@agentmax/shared/types/search-policy-table-view.type';
import { DatePipe, NgFor } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { NxBadgeModule } from '@aposin/ng-aquila/badge';
import { NxLinkModule } from '@aposin/ng-aquila/link';
import { NxPaginationModule } from '@aposin/ng-aquila/pagination';
import { NxTableModule, SortEvent } from '@aposin/ng-aquila/table';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-search-policy-response-view',
  templateUrl: './search-policy-response-view.component.html',
  styleUrls: ['./search-policy-response-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NxTableModule,
    TranslateModule,
    NxLinkModule,
    NxPaginationModule,
    NxBadgeModule,
    NgFor,
    DatePipe,
    CurrencyFormatPipe,
  ],
})
export class SearchPolicyResponseViewComponent {
  private readonly sharedService = inject(SharedService);
  private readonly searchPolicyTableMapper = inject(SearchPolicyTableMapperService);
  protected readonly DISPLAY_DATE_FORMAT = inject(DISPLAY_DATE_FORMAT);

  @Input()
  public set policyList(value: SearchPolicyResponse) {
    this._policyList = this.searchPolicyTableMapper.fromResponseValueToDataView(value);
    this.updatePage();
  }

  protected _policyList: SearchPolicyTableView = [];
  protected elementsPerPage = 20;
  public page = 1;
  protected currentlyShownPageElements!: SearchPolicyTableView;

  public sortTable(sort: SortEvent) {
    this._policyList = this._policyList.sort(
      (a: { [key: string]: any }, b: { [key: string]: any }) => {
        if (sort.active in a && sort.active in b) {
          return this.sharedService.compare(a[sort.active], b[sort.active], sort.direction);
        }
        return 0;
      },
    );

    this.updatePage();
  }

  public goToPrevPage(): void {
    this.page--;
    this.updatePage();
  }

  public goToNextPage(): void {
    this.page++;
    this.updatePage();
  }

  private updatePage(): void {
    const indexMin = (this.page - 1) * this.elementsPerPage;
    const indexMax = indexMin + this.elementsPerPage;
    this.currentlyShownPageElements = this._policyList.filter(
      (x, index) => index >= indexMin && index < indexMax,
    );
  }

  public goToPage(n: number): void {
    this.page = n;
    this.updatePage();
  }

  public trackByFn(index: number, item: { externalContractNumber: string }): string {
    return item.externalContractNumber;
  }
}
