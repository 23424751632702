<table
  class="application-key-value-table"
  nxTable
  aria-describedby="Application Info Table"
>
  <thead>
    <tr nxTableRow>
      <th nxHeaderCell>{{ "versioninfo.app" | translate }}</th>
      <th nxHeaderCell>{{ "versioninfo.key" | translate }}</th>
      <th nxHeaderCell>{{ "versioninfo.value" | translate }}</th>
    </tr>
  </thead>

  <tbody>
    <tr
      nxTableRow
      *ngFor="let item of _tableItems"
    >
      <td nxTableCell>{{ item.application }}</td>
      <td nxTableCell>{{ item.key | translate }}</td>
      <td nxTableCell>{{ item.value }}</td>
    </tr>
  </tbody>
</table>
