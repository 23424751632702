<div *ngIf="isNonInternalUser()">
  <aside
    class="widget-support blue-color"
    (click)="openContactModal()"
  >
    <nx-icon
      name="mail-circle"
      size="m"
    ></nx-icon>
    <span class="fs-16">{{ 'tripdetailpage.0.CONTACTUS' | translate }}</span>
  </aside>
</div>
