import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { HttpEndpointsService } from '../../../shared/services/http-endpoints/http-endpoints.service';

import { LoginHttpServiceInterface } from './login-http-service.interface';
import { LoginDto } from '../../../shared/dtos/login.dto';
import { LoginResponseData } from '../../../shared/types/login-response-data.type';

@Injectable({
  providedIn: 'root',
})
export class LoginHttpService implements LoginHttpServiceInterface {
  constructor(
    private readonly _httpClient: HttpClient,
    private readonly _httpEndpointsService: HttpEndpointsService,
  ) {}

  public login(loginDto: LoginDto): Observable<LoginResponseData> {
    return this._httpClient.post<LoginResponseData>(
      this._httpEndpointsService.buildLoginUrl(),
      loginDto,
    );
  }
}
