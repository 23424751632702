import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class DateService {
  public getToday(): string {
    return moment().toISOString();
  }

  public formatDate(date: Date, dateFormat: string): string {
    return moment(date).format(dateFormat);
  }

  public getDateAs(date: string, newFormat: string, withTimezone = false): string {
    const formatter = withTimezone ? moment : moment.utc;
    return formatter(date).format(newFormat);
  }

  public getDateAsISOString(date: string, currentDateFormat: string, withTimezone = false): string {
    const formatter = withTimezone ? moment : moment.utc;
    return formatter(date, currentDateFormat).toISOString();
  }

  public removeTime(date: string): string {
    return date.split('T')[0];
  }
}
