import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { IFrameService } from '../../../shared/services/iframe/iframe.service';
import { DataAccessorService } from '../../../shared/services/data-accessor/data-accessor.service';
import { LanguageCode } from '../../../shared/enums/language-code.enum';
import { ErrorService } from '@agentmax/shared/services/error/error.service';

// TODO: Should go from CONFIG
const ONE_WEB_PRODUCTS_PAGE_ROUTE = '/news';

@Component({
  selector: 'app-news-page',
  templateUrl: './news-page.component.html',
  styleUrls: ['./news-page.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewsPageComponent {
  // TODO: A temporary solution while hybrid app exists
  @Input()
  public set oneWebLanguage(value: LanguageCode | undefined) {
    if (value === undefined) {
      this.errorService.throwError(this.constructor.name, 'oneWebLanguage must be defined!');
    }

    this.iFrameURL$.next(
      this.iFrameService.generateOneWebURL(
        ONE_WEB_PRODUCTS_PAGE_ROUTE,
        value,
        this.clusterId,
        this.brandForProductsPage,
      ),
    );
  }

  public get iFrameURLStream(): Observable<string> {
    return this.iFrameURL$.asObservable();
  }

  private readonly brandForProductsPage = this.dataAccessorService.getBrandForNewsAndProductsPage();
  private readonly clusterId = this.dataAccessorService.getClusterId();
  private readonly iFrameURL$ = new BehaviorSubject<string>(
    this.iFrameService.generateOneWebURL(
      ONE_WEB_PRODUCTS_PAGE_ROUTE,
      this.dataAccessorService.getCurrentLanguageCode(),
      this.clusterId,
      this.brandForProductsPage,
    ),
  );

  constructor(
    private readonly iFrameService: IFrameService,
    private readonly dataAccessorService: DataAccessorService,
    private readonly errorService: ErrorService,
  ) {}
}
