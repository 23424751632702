import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { Languages, LANGUAGES_STATE, LanguagesStateModel } from '@agentmax/store/languages';
import { NewLanguageCode } from '@agentmax/shared/enums/language-code.enum';

@Component({
  selector: 'app-select-language',
  templateUrl: './select-language.component.html',
  styleUrls: ['./select-language.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectLanguageComponent {
  private readonly _store = inject(Store);

  @Select(LANGUAGES_STATE)
  private readonly _languages$!: Observable<LanguagesStateModel>;
  protected readonly _languages = toSignal(this._languages$);

  protected selectLanguage(selectedLanguageCode: NewLanguageCode): void {
    this._store.dispatch(new Languages.Select(selectedLanguageCode));
  }
}
