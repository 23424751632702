import { Injectable, inject } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken, createSelector } from '@ngxs/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, tap } from 'rxjs';

import { LanguagesInitializationService } from './languages-initialization/languages-initialization.service';
import { PageUrlLanguagesInitializationService } from './languages-initialization/page-url-languages-initialization.service';
import { ENVIRONMENT_LANGUAGE_OPTIONS } from '@agentmax/shared/dependencies/environment-language-options';
import { SelectedLanguageCodeSessionStorageService } from '@agentmax/shared/session-storage/selected-language-code-session-storage.service';
import { LanguageOptionsNameSessionStorageService } from '@agentmax/shared/session-storage/language-options-name-session-storage.service';

import { ILanguagesState } from './languages.state.interface';
import { Languages } from './languages.actions';
import { NewLanguageCode } from '@agentmax/shared/enums/language-code.enum';
import { LanguageOptions } from '@agentmax/shared/types/language-options.type';
import { LanguageOptionsName } from '@agentmax/shared/enums/language-options-name.enum';

export type LanguagesStateModel = Readonly<{
  options: LanguageOptions;
  optionsName: LanguageOptionsName;
  selectedCode: NewLanguageCode;
}>;

export const LANGUAGES_STATE = new StateToken<LanguagesStateModel>('LANGUAGES_STATE');

@State({
  name: LANGUAGES_STATE,
  defaults: {
    options: { [NewLanguageCode.ENGLISH]: 'UK- English' },
    optionsName: LanguageOptionsName.EN,
    selectedCode: NewLanguageCode.ENGLISH,
  },
})
@Injectable({
  providedIn: 'root',
})
export class LanguagesState implements ILanguagesState {
  private readonly _languagesInitializationService = inject(LanguagesInitializationService);
  private readonly _pageUrlLanguagesInitializationService = inject(
    PageUrlLanguagesInitializationService,
  );
  private readonly _environmentLanguageOptions = inject(ENVIRONMENT_LANGUAGE_OPTIONS);
  private readonly _translateService = inject(TranslateService);
  private readonly _selectedLanguageCode = inject(SelectedLanguageCodeSessionStorageService);
  private readonly _languageOptionsName = inject(LanguageOptionsNameSessionStorageService);

  @Selector()
  public static options(state: LanguagesStateModel): LanguageOptions {
    return state.options;
  }

  @Selector()
  public static selectedCode(state: LanguagesStateModel): NewLanguageCode {
    return state.selectedCode;
  }

  public static selectedIsNotFromTheList(languageCodeList: ReadonlyArray<NewLanguageCode>) {
    return createSelector([LANGUAGES_STATE], (state: LanguagesStateModel) => {
      return !languageCodeList.includes(state.selectedCode);
    });
  }

  public ngxsOnInit(context: StateContext<LanguagesStateModel>): void {
    context.dispatch(
      new Languages.Update(
        this._languagesInitializationService.getSelectedLanguageCode(),
        this._languagesInitializationService.getLanguageOptionsName(),
      ),
    );
  }

  @Action(Languages.Select, { cancelUncompleted: true })
  public selectLanguage(
    context: StateContext<LanguagesStateModel>,
    { selectedLanguageCode }: Languages.Select,
  ): Observable<void> {
    this._selectedLanguageCode.set(selectedLanguageCode);

    return this._translateService
      .use(selectedLanguageCode)
      .pipe(tap(() => context.patchState({ selectedCode: selectedLanguageCode })));
  }

  @Action(Languages.Update)
  public updateLanguages(
    context: StateContext<LanguagesStateModel>,
    { selectedLanguageCode, languageOptionsName }: Languages.Update,
  ): Observable<void> {
    this._selectedLanguageCode.set(selectedLanguageCode);
    this._languageOptionsName.set(languageOptionsName);

    this._translateService.setDefaultLang(selectedLanguageCode);
    return this._translateService.use(selectedLanguageCode).pipe(
      tap(() =>
        context.setState({
          options: this._environmentLanguageOptions[languageOptionsName],
          optionsName: languageOptionsName,
          selectedCode: selectedLanguageCode,
        }),
      ),
    );
  }

  @Action(Languages.Reset)
  public resetLanguages(context: StateContext<LanguagesStateModel>): Observable<void> {
    this._selectedLanguageCode.remove();
    this._languageOptionsName.remove();

    const selectedLanguageCode =
      this._pageUrlLanguagesInitializationService.getSelectedLanguageCode();
    const languageOptionsName =
      this._pageUrlLanguagesInitializationService.getLanguageOptionsName();

    this._translateService.setDefaultLang(selectedLanguageCode);
    return this._translateService.use(selectedLanguageCode).pipe(
      tap(() =>
        context.setState({
          options: this._environmentLanguageOptions[languageOptionsName],
          optionsName: languageOptionsName,
          selectedCode: selectedLanguageCode,
        }),
      ),
    );
  }
}
