import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { NonNullableFormBuilder, Validators } from '@angular/forms';
import { tap, catchError, takeUntil } from 'rxjs';
import { DestroyEmitter } from 'src/app/shared/classes/destroy-emitter.class';
import { CustomValidators } from '@agentmax/shared/validators/custom-validators.class';
import { CreatePasswordMapperService } from '../../../core/mappers/create-password/create-password-mapper.service';
import { CreatePasswordHttpService } from '../../../core/http/create-password/create-password-http.service';
import { BaseResponseData } from '@agentmax/shared/types/base-response-data.type';

const QUESTION_LIST = ['QU_01', 'QU_02', 'QU_03', 'QU_04', 'QU_05', 'QU_06'];

@Component({
  selector: 'app-create-password-form',
  templateUrl: './create-password-form.component.html',
  styleUrls: ['./create-password-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreatePasswordFormComponent extends DestroyEmitter implements OnInit {
  @Input()
  public set userEmail(value: string) {
    this._userEmail = value;
  }

  @Output()
  public readonly resolve = new EventEmitter<BaseResponseData>();

  protected readonly _questionList = QUESTION_LIST;
  protected _userEmail = '';
  protected _onePortalMessageCode: string | null = null;
  protected readonly _createPasswordForm = this._formBuilder.group(
    {
      password: ['', [Validators.required, CustomValidators.passwordFormat]],
      confirmPassword: ['', [Validators.required]],
      question1: ['', [Validators.required]],
      answer1: ['', [Validators.required]],
      question2: ['', [Validators.required]],
      answer2: ['', [Validators.required]],
    },
    {
      validators: [
        CustomValidators.controlsMatch('password', 'confirmPassword'),
        CustomValidators.controlsDoNotMatch('question1', 'question2'),
      ],
    },
  );

  constructor(
    private readonly _formBuilder: NonNullableFormBuilder,
    private readonly _cdRef: ChangeDetectorRef,
    private readonly _createPasswordMapperService: CreatePasswordMapperService,
    private readonly _createPasswordHttpService: CreatePasswordHttpService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.subToCreatePasswordFormValueChanges();
  }

  protected handleCreatePasswordFormSubmit(): void {
    if (this._createPasswordForm.invalid) {
      return;
    }

    const createPasswordDto = this._createPasswordMapperService.fromFormValueAndUserEmailToDto(
      this._createPasswordForm.getRawValue(),
      this._userEmail,
    );
    this._createPasswordHttpService
      .createPassword(createPasswordDto)
      .pipe(
        tap((data) => this.resolve.emit(data)),
        catchError((error: Error) => {
          this._onePortalMessageCode = error.message;
          this._cdRef.markForCheck();
          throw error;
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private subToCreatePasswordFormValueChanges(): void {
    this._createPasswordForm.valueChanges
      .pipe(
        tap(() => {
          if (this._onePortalMessageCode !== null) {
            this._onePortalMessageCode = null;
          }
        }),
      )
      .subscribe();
  }
}
