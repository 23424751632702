import { Injectable } from '@angular/core';
import { CookieService as NgxCookieService } from 'ngx-cookie-service';

import { CookieKey } from '../../enums/cookie-key.enum';

@Injectable({
  providedIn: 'root',
})
export class CookieService {
  constructor(private readonly ngxCookieService: NgxCookieService) {}

  public getRawValue(key: CookieKey): string {
    return this.ngxCookieService.get(key);
  }

  public setApplicableValue(key: CookieKey, applicableValue: string): void {
    this.ngxCookieService.set(key, applicableValue);
  }
}
