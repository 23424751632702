<div>
  <div class="scroll-container">
    <table
      nxTable
      [condensed]="true"
      nxSort
      (sortChange)="sortTable($event)"
      aria-describedby="Policy Search Results"
    >
      <thead>
        <tr nxTableRow>
          <th
            nxHeaderCell
            scope="col"
          >
            {{ 'poladmin.0.POLNUM' | translate }}
          </th>
          <th
            nxHeaderCell
            scope="col"
            nxSortHeaderCell="name"
          >
            {{ 'poladmin.0.POLHOLDER' | translate }}
          </th>
          <th
            nxHeaderCell
            scope="col"
            nxSortHeaderCell="purchaseDate"
          >
            {{ 'poladmin.0.SOLDON' | translate }}
          </th>
          <th
            nxHeaderCell
            scope="col"
            nxSortHeaderCell="contractStartDate"
          >
            {{ 'poladmin.0.STARTDATE' |
                        translate }}
          </th>
          <th
            nxHeaderCell
            scope="col"
            nxSortHeaderCell="contractEndDate"
          >
            {{ 'poladmin.0.ENDDATE' | translate
            }}
          </th>
          <th
            nxHeaderCell
            scope="col"
            nxSortHeaderCell="grossPremium"
          >
            {{ 'poladmin.0.PREMIUM' | translate }}
          </th>
          <th
            nxHeaderCell
            scope="col"
          >
            {{ 'poladmin.0.STATUS' | translate }}
          </th>
          <th
            nxHeaderCell
            scope="col"
            nxSortHeaderCell="productOfferingName"
          >
            {{ 'poladmin.0.PRODUCT' |
                        translate }}
          </th>
          <th
            nxHeaderCell
            scope="col"
            nxSortHeaderCell="policyType"
          >
            {{ 'poladmin.0.TYPE' | translate }}
          </th>
          <th
            nxHeaderCell
            scope="col"
            nxSortHeaderCell="issuedBy"
          >
            {{ 'poladmin.0.SOLDBY' | translate }}
          </th>
          <th
            nxHeaderCell
            scope="col"
          >
            {{ 'poladmin.0.COMMRECPT' | translate }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          nxTableRow
          *ngFor="let item of currentlyShownPageElements; trackBy: trackByFn"
        >
          <td nxTableCell>
            <nx-link
              ><a>{{item.externalContractNumber}}</a></nx-link
            >
          </td>
          <td nxTableCell>{{item.name}}</td>
          <td nxTableCell>{{item.purchaseDate | date: DISPLAY_DATE_FORMAT}}</td>
          <td nxTableCell>{{item.contractStartDate | date: DISPLAY_DATE_FORMAT}}</td>
          <td nxTableCell>{{item.contractEndDate | date: DISPLAY_DATE_FORMAT}}</td>
          <td nxTableCell>{{item.grossPremium | currencyFormat : item.currency}}</td>
          <td nxTableCell>
            <nx-badge [type]="item.statusType">{{item.status |
                            translate}}</nx-badge>
          </td>
          <td nxTableCell>{{item.productOfferingName}}</td>
          <td nxTableCell>{{item.policyType | translate}}</td>
          <td nxTableCell>{{item.issuedBy}}</td>
          <td nxTableCell>{{item.externalAgent}}</td>
        </tr>
      </tbody>
    </table>
  </div>

  <nx-pagination
    class="pagination"
    [count]="_policyList.length"
    [page]="page"
    type="advanced"
    [perPage]="elementsPerPage"
    (goPrev)="goToPrevPage()"
    (goNext)="goToNextPage()"
    (goPage)="goToPage($event)"
  >
  </nx-pagination>
</div>
