<form
  class="search-policy-form"
  [formGroup]="_searchPolicyForm"
  nxLayout="grid"
  (ngSubmit)="handleSearchPolicyFormSubmit()"
>
  <div nxRow>
    <div nxCol="4">
      <app-text-field-control
        [label]="'poladmin.0.POLNUM' | translate"
        [placeholder]="'poladmin.0.POLNUM' | translate"
        formControlName="policyNumber"
      ></app-text-field-control>
    </div>

    <div nxCol="4">
      <app-text-field-control
        [label]="'poladmin.0.POLHOLFNAME' | translate"
        [placeholder]="'poladmin.0.POLHOLFNAME' | translate"
        formControlName="beneficiaryFirstName"
      ></app-text-field-control>
    </div>

    <div nxCol="4">
      <app-text-field-control
        [label]="'poladmin.0.POLHOLLNAME' | translate"
        [placeholder]="'poladmin.0.POLHOLLNAME' | translate"
        formControlName="beneficiaryLastName"
      ></app-text-field-control>
    </div>
  </div>

  <div nxRow>
    <div nxCol="4">
      <nx-formfield
        [label]="'poladmin.0.POLICYTYPE' | translate"
        appearance="outline"
      >
        <nx-dropdown
          [placeholder]="'poladmin.0.POLICYTYPE' | translate"
          formControlName="policyType"
        >
          <nx-dropdown-item
            *ngFor="let option of _searchPolicyTypeOptions"
            [value]="option.value"
            >{{ option.label | translate }}</nx-dropdown-item
          >
        </nx-dropdown>
      </nx-formfield>
    </div>

    <div nxCol="4">
      <nx-formfield
        [label]="'poladmin.0.POLSTATUS' | translate"
        appearance="outline"
      >
        <nx-dropdown
          [placeholder]="'poladmin.0.POLSTATUS' | translate"
          formControlName="policyStatus"
        >
          <nx-dropdown-item
            *ngFor="let option of _searchPolicyStatusOptions"
            [value]="option.value"
            >{{ option.label | translate }}</nx-dropdown-item
          >
        </nx-dropdown>
      </nx-formfield>
    </div>

    <div nxCol="4">
      <app-text-field-control
        [label]="'settings.0.PRODNAME' | translate"
        [placeholder]="'settings.0.PRODNAME' | translate"
        formControlName="productName"
      ></app-text-field-control>
    </div>
  </div>

  <div nxRow>
    <div nxCol="4">
      <app-date-control
        [label]="'poladmin.0.POLSTARTDATE' | translate"
        [placeholder]="'placeholders.DATE' | translate"
        formControlName="policyStartDateFrom"
      ></app-date-control>
    </div>

    <div nxCol="4">
      <app-date-control
        [label]="'poladmin.0.TO' | translate"
        [placeholder]="'placeholders.DATE' | translate"
        formControlName="policyStartDateTo"
      ></app-date-control>
    </div>

    <div nxCol="4">
      <app-text-field-control
        [label]="'poladmin.0.PARTNAME' | translate"
        [placeholder]="'poladmin.0.PARTNAME' | translate"
        formControlName="partnerName"
        *ngIf="isInternal"
      ></app-text-field-control>
    </div>
  </div>

  <div nxRow>
    <div nxCol="4">
      <app-date-control
        [label]="'poladmin.0.PURDATE' | translate"
        [placeholder]="'placeholders.DATE' | translate"
        formControlName="purchaseDateFrom"
      ></app-date-control>
    </div>

    <div nxCol="4">
      <app-date-control
        [label]="'poladmin.0.TO' | translate"
        [placeholder]="'placeholders.DATE' | translate"
        formControlName="purchaseDateTo"
      ></app-date-control>
    </div>

    <div nxCol="4">
      <app-text-field-control
        [label]="'poladmin.0.PARTCODE' | translate"
        [placeholder]="'poladmin.0.PARTCODE' | translate"
        formControlName="partnerNumber"
        *ngIf="isInternal"
      ></app-text-field-control>
    </div>
  </div>

  <div
    nxRow
    rowJustify="end"
  >
    <div class="actions-row-container">
      <button
        [disabled]="_searchPolicyForm.invalid"
        nxButton="primary"
        type="submit"
      >
        <span>{{ "poladmin.0.SEARCH" | translate }}</span>
      </button>
    </div>
  </div>
</form>
