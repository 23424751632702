import { Injectable } from '@angular/core';

import { SessionStorageService } from '../session-storage/session-storage.service';
import { CookieService } from '../cookie/cookie.service';

import { SessionStorageKey } from '../../enums/session-storage-key.enum';
import { CookieKey } from '../../enums/cookie-key.enum';
import { BusinessUnits } from '../../types/business-units.type';
import { AccountDetails } from 'src/app/shared/types/account-details.type';
import { CurrentUser } from '../../types/current-user.type';
import { UserInformation } from '../../types/user-information.type';
import { LanguageCode } from '../../enums/language-code.enum';
import { BookingApp } from '../../types/booking-app.type';
import { BUConfig } from '../../types/ui-config.type';
import { AccountInformation } from '../../types/account-information.type';
import { Agency } from '../../types/agency.type';
import { Policy } from '../../types/policy.type';
import { Quotation } from '../../types/quotation.type';
import { QuoteOfferDetailsResponse } from '../../types/success-quote-offer-details-data.type';
import { AppFeatureToggles } from 'src/app/shared/types/app-feature-toggles.type';
import { environment } from 'src/environments/environment';
import { MyAccountInfo } from 'src/app/shared/types/login-response-data.type';

@Injectable({
  providedIn: 'root',
})
export class DataAccessorService {
  constructor(
    private readonly sessionStorageService: SessionStorageService,
    private readonly cookieService: CookieService,
  ) {}

  public getUserCountryCode(): string {
    return this.sessionStorageService.getParsedValue<string>(SessionStorageKey.USER_COUNTRY_CODE);
  }

  public getUserCountryCodeOrNull(): string | null {
    return this.sessionStorageService.getParsedValueOrNull<string>(
      SessionStorageKey.USER_COUNTRY_CODE,
    );
  }

  public getUserEmail(): string {
    if (environment.standalone) {
      const userInfo = this.getCurrentUsersInformation();
      return userInfo.email;
    }
    return this.sessionStorageService.getParsedValue<string>(SessionStorageKey.EMAIL_ID);
  }

  public getBusinessUnits(): BusinessUnits {
    return this.sessionStorageService.getParsedValue<BusinessUnits>(
      SessionStorageKey.BUSINESS_UNITS,
    );
  }

  public getAccountDetails(): AccountDetails {
    return this.sessionStorageService.getParsedValue<AccountDetails>(
      SessionStorageKey.ACCOUNT_DETAILS,
    );
  }

  public getMyAccountInfo(): MyAccountInfo {
    return this.sessionStorageService.getParsedValue<MyAccountInfo>(
      SessionStorageKey.MY_ACCOUNT_INFO,
    );
  }

  public getCurrentUser(): CurrentUser | null {
    return this.sessionStorageService.getParsedValueOrNull<CurrentUser>(
      SessionStorageKey.CURRENT_USER,
    );
  }

  public getUserId(): string {
    return this.sessionStorageService.getParsedValue<CurrentUser>(SessionStorageKey.CURRENT_USER)
      .UserId;
  }

  public getPartnerCode(): string | null {
    return this.sessionStorageService.getParsedValueOrNull<string>(SessionStorageKey.PARTNER_CODE);
  }

  public getCurrentUsersInformation(): UserInformation {
    return this.sessionStorageService.getParsedValue<UserInformation>(
      SessionStorageKey.USER_INFORMATION,
    );
  }

  public getUserPermission(): string {
    return this.sessionStorageService.getParsedValue<CurrentUser>(SessionStorageKey.CURRENT_USER)
      .user_permissions;
  }

  public getAccountInformation(): AccountInformation {
    return this.sessionStorageService.getParsedValue<BookingApp>(SessionStorageKey.BOOKING_APP)
      .myAccoutInformation;
  }

  public getBrandForNewsAndProductsPage(): string | null {
    return this.sessionStorageService.getParsedValueOrNull<string>(
      SessionStorageKey.BRAND_FOR_NEWS_AND_PRODUCTS,
    );
  }

  public getCurrentLanguageCode(): LanguageCode {
    // TODO: Probably `LanguageCode` enum does not have all possible language codes
    return this.cookieService.getRawValue(CookieKey.CURRENT_LANGUAGE) as LanguageCode;
  }

  public getCustomerNumber(): string {
    return this.sessionStorageService.getParsedValue<string>(SessionStorageKey.CUSTOMER_NUMBER);
  }

  public getCustomerNumberOrNull(): string | null {
    return this.sessionStorageService.getParsedValueOrNull<string>(
      SessionStorageKey.CUSTOMER_NUMBER,
    );
  }

  public getConfigFileOrNull(): BUConfig | null {
    return this.sessionStorageService.getParsedValueOrNull<BUConfig>(SessionStorageKey.UI_CONFIG);
  }

  public getConfigFile(): BUConfig {
    return this.sessionStorageService.getParsedValue<BUConfig>(SessionStorageKey.UI_CONFIG);
  }

  public getRefreshToken(): string {
    return this.sessionStorageService.getParsedValue<string>(SessionStorageKey.REFRESH_TOKEN);
  }

  public getAccessToken(): string {
    return this.sessionStorageService.getParsedValue(SessionStorageKey.ACCESS_TOKEN);
  }

  public getAccessTokenOrNull(): string | null {
    return this.sessionStorageService.getParsedValueOrNull(SessionStorageKey.ACCESS_TOKEN);
  }

  public getSelectedAgency(): Agency {
    return this.sessionStorageService.getParsedValue<Agency>(SessionStorageKey.SELECTED_AGENCY);
  }

  public getSelectedLanguage(): string | null {
    return this.sessionStorageService.getParsedValueOrNull<string>(
      SessionStorageKey.SELECTED_LANGUAGE,
    );
  }

  public getCurrency(): string {
    return this.sessionStorageService.getParsedValue<string>(SessionStorageKey.CURRENCY);
  }

  public getSelectedProductForSaveOffer(): Policy {
    return this.sessionStorageService.getParsedValue<Policy>(SessionStorageKey.PRODUCT_SAVE_OFFER);
  }

  public getQuotationForSaveOffer(): Quotation {
    return this.sessionStorageService.getParsedValue<Quotation>(
      SessionStorageKey.QUOTATION_SAVE_OFFER,
    );
  }

  public getQuoteOfferDetails(): QuoteOfferDetailsResponse {
    return this.sessionStorageService.getParsedValue<QuoteOfferDetailsResponse>(
      SessionStorageKey.SELECTED_OFFER_DETAILS,
    );
  }

  public getFeatureToggles(): AppFeatureToggles {
    return this.sessionStorageService.getParsedValue<AppFeatureToggles>(
      SessionStorageKey.FEATURE_TOGGLES,
    );
  }

  public getOriginalFeatureToggles(): AppFeatureToggles {
    return this.sessionStorageService.getParsedValue<AppFeatureToggles>(
      SessionStorageKey.ORIGINAL_FEATURE_TOGGLES,
    );
  }

  public getClusterId(): string | null {
    return this.sessionStorageService.getParsedValueOrNull<string>(SessionStorageKey.CLUSTER_ID);
  }

  public getSpringProfileOrNull(): string | null {
    return this.sessionStorageService.getParsedValueOrNull<string>(SessionStorageKey.ENVIRONMENT);
  }

  public getSpringProfile(): string {
    return this.sessionStorageService.getParsedValue<string>(SessionStorageKey.ENVIRONMENT);
  }

  public getBUBrand(): string | null {
    return this.sessionStorageService.getParsedValueOrNull<string>(SessionStorageKey.BU_BRAND);
  }

  public getWhiteLabelFlag(): boolean {
    return this.sessionStorageService.getParsedValue<boolean>(SessionStorageKey.WHITE_LABEL);
  }

  public getBUAgencyLogo(): string | null {
    return this.sessionStorageService.getParsedValueOrNull<string>(
      SessionStorageKey.BU_AGENCY_LOGO,
    );
  }

  public getUserType(): string {
    return this.sessionStorageService.getParsedValue<string>(SessionStorageKey.USER_TYPE);
  }

  public getQuoteOfferComment(): string | null {
    return this.sessionStorageService.getParsedValue<string | null>(
      SessionStorageKey.QUOTE_OFFER_COMMENT,
    );
  }

  public removeQuoteOfferDetails(): void {
    this.sessionStorageService.removeValue(SessionStorageKey.SELECTED_OFFER_DETAILS);
  }

  public removeQuoteOfferComment(): void {
    this.sessionStorageService.removeValue(SessionStorageKey.QUOTE_OFFER_COMMENT);
  }
}
