import { Injectable, Inject, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DataAccessorService } from '../data-accessor/data-accessor.service';
import { LOCATION } from '../../../core/deprecated-global-dependencies/location.global-dependency';
import { LanguageCode } from '../../enums/language-code.enum';
import { CountryCode } from '../../enums/country-code.enum';
import { FileExtension } from '../../enums/file-extension.enum';
import { ErrorService } from '@agentmax/shared/services/error/error.service';
import { doesObjectHaveKey } from '@agentmax/shared/type-assertions/does-object-have-key/does-object-have-key.type-assertion';

const SUB_BU = {
  AT: {
    'AT_MO1_C012_01200002_South Tyrol': {
      code: 'IT',
      name: 'Italy',
      oneWebURLPrefix: 'ST',
    },
  },
};
const ONE_WEB = {
  DE: 'germany',
  AT: 'austria',
  ES: 'spain',
  HU: 'hungary',
  IT: 'italy',
  CH: 'switzerland',
  RO: 'romania',
  FR: 'france',
  BG: 'bulgaria',
  CZ: 'czechrepublic',
  ST: 'southtyrol',
  GB: 'uk',
};

export interface IFrameServiceInterface {
  generateOneWebURL(
    oneWebRoute: string,
    countryCode?: string,
    clusterId?: string | null,
    brand?: string | null,
  ): string;
}

@Injectable({
  providedIn: 'root',
})
export class IFrameService implements IFrameServiceInterface {
  constructor(
    @Inject(LOCATION)
    private readonly location: Location,
    private readonly domSanitizer: DomSanitizer,
    private readonly dataAccessorService: DataAccessorService,
    private readonly errorService: ErrorService,
  ) {}

  public generateOneWebURL(
    oneWebRoute: string,
    countryCode?: string,
    clusterId?: string | null,
    brand?: string | null,
  ): string {
    let oneWebUrl;
    const oneWebBaseURL = this.createOneWebBaseURL();
    const subBuOneWebURLPrefix = this.getSubBuOneWebURLPrefix();

    if (!doesObjectHaveKey(ONE_WEB, subBuOneWebURLPrefix)) {
      this.errorService.throwError(
        this.constructor.name,
        `Object '${JSON.stringify(ONE_WEB)}' does not have '${subBuOneWebURLPrefix}' key!`,
      );
    }

    const userCountryCode = this.dataAccessorService.getUserCountryCodeOrNull();
    const oneWebLanguage = subBuOneWebURLPrefix.toLowerCase() || LanguageCode.ENGLISH;
    const oneWebURLBrandPathPart = this.determineOneWebURLBrandPathPart(brand);
    let languageCode = countryCode?.toLowerCase() || LanguageCode.ENGLISH;

    if (
      languageCode === CountryCode.AUSTRIA.toLowerCase() ||
      languageCode === LanguageCode.SWISS_GERMAN
    ) {
      languageCode = LanguageCode.GERMAN;
    } else if (languageCode === CountryCode.CZECH.toLowerCase()) {
      languageCode = LanguageCode.CZECH;
    } else if (languageCode === LanguageCode.SWISS_ITALIAN) {
      languageCode = LanguageCode.ITALIAN;
    } else if (languageCode === LanguageCode.SWISS_FRENCH) {
      languageCode = LanguageCode.FRENCH;
    } else if (languageCode === LanguageCode.SWISS_ENGLISH) {
      languageCode = LanguageCode.ENGLISH;
    }

    if (
      (oneWebLanguage === LanguageCode.ITALIAN || oneWebLanguage === LanguageCode.ENGLISH) &&
      (userCountryCode === CountryCode.ITALY || userCountryCode === CountryCode.SPAIN) &&
      clusterId
    ) {
      oneWebUrl = `${oneWebBaseURL}/${
        ONE_WEB[subBuOneWebURLPrefix]
      }/${oneWebURLBrandPathPart}/${clusterId.toLowerCase()}/${languageCode}${oneWebRoute}.${
        FileExtension.HTML
      }`;
    } else {
      oneWebUrl = `${oneWebBaseURL}/${ONE_WEB[subBuOneWebURLPrefix]}/${oneWebURLBrandPathPart}/${languageCode}${oneWebRoute}.${FileExtension.HTML}`;
    }

    return this.sanitizeUrl(oneWebUrl);
  }

  private determineOneWebURLBrandPathPart(brandCode?: string | null): string {
    const mondialBrandCode = 'MD';
    const mondialOneWebPathPart = 'mondial';
    const allianzOneWebPathPart = 'allianz';

    if (brandCode === mondialBrandCode) {
      return mondialOneWebPathPart;
    }

    return allianzOneWebPathPart;
  }

  private getSubBuOneWebURLPrefix(): string {
    const userCountryCode = this.dataAccessorService.getUserCountryCode();
    const businessUnits = this.dataAccessorService.getBusinessUnits();
    const finalUserCountryCode = userCountryCode as keyof typeof SUB_BU;
    const finalBuUniqueId =
      businessUnits.buUniqueId as keyof (typeof SUB_BU)[typeof finalUserCountryCode];

    return this.buHasSubBu()
      ? SUB_BU[finalUserCountryCode][finalBuUniqueId].oneWebURLPrefix
      : userCountryCode;
  }

  private buHasSubBu(userCountryCode?: string, buUniqueId?: string): boolean {
    const finalUserCountryCode = userCountryCode || this.dataAccessorService.getUserCountryCode();
    const businessUnits = this.dataAccessorService.getBusinessUnits();
    const finalBuUniqueId = buUniqueId || businessUnits.buUniqueId;

    return finalBuUniqueId
      ? doesObjectHaveKey(SUB_BU, finalUserCountryCode) &&
          doesObjectHaveKey(SUB_BU[finalUserCountryCode], finalBuUniqueId)
      : false;
  }

  private sanitizeUrl(url: string): string {
    const sanitizedUrl = this.domSanitizer.sanitize(SecurityContext.URL, url);

    if (sanitizedUrl === null) {
      this.errorService.throwError(this.constructor.name, 'Url sanitizing is wrong!');
    }

    return sanitizedUrl;
  }

  private createOneWebBaseURL(oneWebUrlPrefix = 'https://'): string {
    return `${oneWebUrlPrefix}${this.location.hostname}`;
  }
}
