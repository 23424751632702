import { inject, Injectable } from '@angular/core';
import { Action, NgxsOnInit, State, StateContext, StateToken } from '@ngxs/store';

import { CurrentUserSessionStorageService } from '@agentmax/shared/session-storage/current-user-session-storage.service';

import { CurrentUserType } from '@agentmax/shared/types/current-user-type.type';
import { CurrentUser } from './current-user.actions';

export type CurrentUserStateModel = CurrentUserType | null;

export const CURRENT_USER_STATE = new StateToken<CurrentUserStateModel>('CURRENT_USER_STATE');

@State({
  name: CURRENT_USER_STATE,
  defaults: null,
})
@Injectable({
  providedIn: 'root',
})
export class CurrentUserState implements NgxsOnInit {
  private readonly currentUser = inject(CurrentUserSessionStorageService);

  public ngxsOnInit(context: StateContext<CurrentUserStateModel>): void {
    const currentUser = this.currentUser.getOrReturnNull();

    if (currentUser === null) {
      return;
    }

    context.dispatch(new CurrentUser.Restore(currentUser));
  }

  @Action(CurrentUser.Save)
  public save(
    context: StateContext<CurrentUserStateModel>,
    { currentUser }: CurrentUser.Save,
  ): void {
    this.currentUser.set(currentUser);
    context.setState(currentUser);
  }

  @Action(CurrentUser.Clear)
  public clear(context: StateContext<CurrentUserStateModel>): void {
    this.currentUser.remove();
    context.setState(null);
  }

  @Action(CurrentUser.Restore)
  public restore(
    context: StateContext<CurrentUserStateModel>,
    { currentUser }: CurrentUser.Restore,
  ): void {
    context.setState(currentUser);
  }
}
