<nx-footer>
  <nx-footer-copyright>
    {{ copyrightText | translate }}
  </nx-footer-copyright>

  <nx-footer-navigation *ngIf="shouldShowFooterLinks">
    <nx-footer-link *ngFor="let link of footerLinks">
      <a
        [href]="link.link"
        target="_blank"
        >{{ link.text | translate }}</a
      >
    </nx-footer-link>
  </nx-footer-navigation>
</nx-footer>
