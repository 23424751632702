import { Injectable, InjectionToken, inject } from '@angular/core';

import { LOCATION_HOST } from '@agentmax/shared/dependencies/location-host';

import { ILanguagesInitializationService } from './languages-initialization.service.interface';
import { NewLanguageCode, isLanguageCode } from '@agentmax/shared/enums/language-code.enum';
import {
  LanguageOptionsName,
  isLanguageOptionsName,
} from '@agentmax/shared/enums/language-options-name.enum';
import { ENVIRONMENT_LANGUAGE_OPTIONS } from '@agentmax/shared/dependencies/environment-language-options';

export const BULGARIAN_HOST = new InjectionToken('BULGARIAN_HOST', {
  providedIn: 'root',
  factory: () => 'allianztravel-agentmaxbg.com' as const,
});

@Injectable({
  providedIn: 'root',
})
export class ProdPageUrlLanguagesInitializationService implements ILanguagesInitializationService {
  private readonly _locationHost = inject(LOCATION_HOST);
  private readonly _bulgarianHost = inject(BULGARIAN_HOST);
  private readonly _environmentLanguageOptions = inject(ENVIRONMENT_LANGUAGE_OPTIONS);

  private get _isBulgarianHost(): boolean {
    return this._locationHost === this._bulgarianHost;
  }

  private get _locationHostTopLevelDomain(): string {
    return this._locationHost.slice(this._locationHost.lastIndexOf('.') + 1);
  }

  public getLanguageOptionsName(): LanguageOptionsName {
    if (this._isBulgarianHost) {
      return LanguageOptionsName.BG;
    }

    const locationHostTopLevelDomain = this._locationHostTopLevelDomain;

    if (!isLanguageOptionsName(locationHostTopLevelDomain)) {
      throw new Error(`Unknown language options name: '${locationHostTopLevelDomain}'!`);
    }

    return locationHostTopLevelDomain;
  }

  public getSelectedLanguageCode(): NewLanguageCode {
    const languageOptions = this._environmentLanguageOptions[this.getLanguageOptionsName()];
    const [[firstLanguageCode]] = Object.entries(languageOptions);

    if (!isLanguageCode(firstLanguageCode)) {
      throw new Error(`Unknown language code: '${firstLanguageCode}'!`);
    }

    return firstLanguageCode;
  }
}
