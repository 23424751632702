import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { LoginPageView } from '../enums/login-page-view.enum';

@Injectable({
  providedIn: 'root',
})
export class LoginPageViewService {
  private readonly _currentView$ = new BehaviorSubject<LoginPageView>(LoginPageView.LOGIN);

  public readonly currentView$ = this._currentView$.asObservable();

  public switchToLoginView(): void {
    this.setView(LoginPageView.LOGIN);
  }

  public switchToCreatePasswordView(): void {
    this.setView(LoginPageView.CREATE_PASSWORD);
  }

  public switchToPasswordSuccessfullyCreatedView(): void {
    this.setView(LoginPageView.PASSWORD_SUCCESSFULLY_CREATED);
  }

  public switchToForgotPasswordView(): void {
    this.setView(LoginPageView.FORGOT_PASSWORD);
  }

  public switchToForcePasswordResetView(): void {
    this.setView(LoginPageView.FORCE_PASSWORD_RESET);
  }

  public switchToPasswordSuccessfullyResetView(): void {
    this.setView(LoginPageView.PASSWORD_SUCCESSFULLY_RESET);
  }

  public switchToResetPasswordView(): void {
    this.setView(LoginPageView.RESET_PASSWORD);
  }

  private setView(newView: LoginPageView): void {
    this._currentView$.next(newView);
  }
}
