import { Injectable, inject } from '@angular/core';
import { tap } from 'rxjs';

import { PasswordExpiresSoonService } from '../../core/services/password-expires-soon/password-expires-soon.service';

import { SuccessStrategy } from './success.strategy';
import { LoginResponseData } from '../../shared/types/login-response-data.type';

@Injectable({
  providedIn: 'root',
})
export class PasswordAlmostExpiredStrategy extends SuccessStrategy {
  // We inject this dependency in such a specific way because if to use constructor
  // here instead we will have to duplicate all the child dependencies here
  private readonly _passwordExpiresSoonService = inject(PasswordExpiresSoonService);

  public override execute(data: LoginResponseData): void {
    this._passwordExpiresSoonService
      .openPasswordExpiresSoonDialog(data.passwordExpiryDaysLeft)
      .afterClosed()
      .pipe(tap(() => super.execute(data)))
      .subscribe();
  }
}
