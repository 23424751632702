<div class="login-form">
  <div class="top-message-container">
    <span
      class="success-logout-message"
      *ngIf="_successLogoutMessageIsShown"
      >{{
      "loginpage.0.LOGOUT" | translate
      }}</span
    >

    <span
      class="error-switch-account-message"
      *ngIf="_errorSwitchAccountMessageIsShown"
      >{{
      "settings.0.SWITCHACCERROR" | translate
      }}</span
    >
  </div>

  <form
    class="form"
    [formGroup]="_loginForm"
    (ngSubmit)="handleLoginFormSubmit()"
  >
    <nx-formfield
      class="form__item"
      [label]="'loginpage.0.EMAIL' | translate"
      appearance="outline"
    >
      <span nxFormfieldPrefix>
        <nx-icon name="product-mail"></nx-icon>
      </span>

      <input
        nxInput
        [placeholder]="'loginpage.0.EMAIL' | translate"
        formControlName="email"
      />

      <nx-error
        *ngIf="_loginForm.controls.email.errors | hasError : 'required'"
        appearance="text"
        nxFormfieldError
      >
        <span>{{ "loginpage.0.EMAILREQD" | translate }}</span>
      </nx-error>

      <nx-error
        *ngIf="_loginForm.controls.email.errors | hasError : 'email'"
        appearance="text"
        nxFormfieldError
      >
        <span>{{ "loginpage.0.INVPASS" | translate }}</span>
      </nx-error>
    </nx-formfield>

    <nx-formfield
      class="form__item"
      [label]="'loginpage.0.PASS' | translate"
      appearance="outline"
    >
      <span nxFormfieldPrefix>
        <nx-icon name="product-lock"></nx-icon>
      </span>

      <input
        nxInput
        [placeholder]="'loginpage.0.PASS' | translate"
        type="password"
        autocomplete="on"
        formControlName="password"
      />

      <nx-error
        *ngIf="_loginForm.controls.password.errors | hasError : 'required'"
        appearance="text"
        nxFormfieldError
      >
        <span>{{ "loginpage.0.PASSREQD" | translate }}</span>
      </nx-error>
    </nx-formfield>

    <button
      class="form__item"
      [disabled]="_loginForm.invalid || onePortalMessageCode !== null"
      nxButton="primary"
      type="submit"
    >
      <span>{{ "loginpage.0.LOGIN" | translate }}</span>
    </button>
  </form>

  <nx-error
    class="backend-error-container"
    *ngIf="onePortalMessageCode !== null"
    appearance="message"
  >
    <span>{{ "loginpage.0." + onePortalMessageCode | translate }}</span>
  </nx-error>

  <nx-link
    class="forgotten-password-link"
    (click)="forgottenPasswordClick.emit()"
  >
    <span>{{ "loginpage.0.FORGOTPASS" | translate }}</span>
  </nx-link>
</div>
