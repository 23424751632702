import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { Observable, of } from 'rxjs';

import { Languages } from './languages.actions';
import { NewLanguageCode } from '@agentmax/shared/enums/language-code.enum';
import { LanguageOptionsName } from '@agentmax/shared/enums/language-options-name.enum';

import { ILanguagesState } from './languages.state.interface';
import { LANGUAGES_STATE, LanguagesStateModel } from './languages.state';

@State({
  name: LANGUAGES_STATE,
  defaults: {
    options: { [NewLanguageCode.ENGLISH]: 'UK- English' },
    optionsName: LanguageOptionsName.EN,
    selectedCode: NewLanguageCode.ENGLISH,
  },
})
@Injectable()
export class LanguagesStateMock implements ILanguagesState {
  public ngxsOnInit(_context: StateContext<LanguagesStateModel>): void {}

  @Action(Languages.Select, { cancelUncompleted: true })
  public selectLanguage(
    _context: StateContext<LanguagesStateModel>,
    _action: Languages.Select,
  ): Observable<void> {
    return of();
  }

  @Action(Languages.Update)
  public updateLanguages(
    _context: StateContext<LanguagesStateModel>,
    _action: Languages.Update,
  ): Observable<void> {
    return of();
  }

  @Action(Languages.Reset)
  public resetLanguages(_context: StateContext<LanguagesStateModel>): Observable<void> {
    return of();
  }
}
