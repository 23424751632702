import { Injectable } from '@angular/core';

import { BaseSessionStorageService } from './base-session-storage.service';
import { NewLanguageCode } from '@agentmax/shared/enums/language-code.enum';
import { SessionStorageKey } from '@agentmax/shared/enums/session-storage-key.enum';

@Injectable({
  providedIn: 'root',
})
export class SelectedLanguageCodeSessionStorageService extends BaseSessionStorageService<NewLanguageCode> {
  protected readonly key = SessionStorageKey.SELECTED_LANGUAGE_CODE;
}
