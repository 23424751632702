import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NxAccordionModule } from '@aposin/ng-aquila/accordion';
import { TranslateModule } from '@ngx-translate/core';
import { NxTooltipModule } from '@aposin/ng-aquila/tooltip';
import { SearchPolicyFormComponent } from '../../components/search-policy-form/search-policy-form.component';
import { NxIconModule } from '@aposin/ng-aquila/icon';
import { SearchPolicyResponse } from '@agentmax/shared/types/search-policy-response.type';
import { BehaviorSubject } from 'rxjs';
import { NxErrorModule } from '@aposin/ng-aquila/base';
import { SearchPolicyResponseViewComponent } from 'src/app/policy-admin/components/search-policy-response-view/search-policy-response-view.component';
import { AsyncPipe, NgIf } from '@angular/common';

@Component({
  selector: 'app-policy-admin-page',
  templateUrl: './policy-admin-page.component.html',
  styleUrls: ['./policy-admin-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NxAccordionModule,
    NxTooltipModule,
    NxIconModule,
    TranslateModule,
    SearchPolicyFormComponent,
    NxErrorModule,
    SearchPolicyResponseViewComponent,
    NgIf,
    AsyncPipe,
  ],
})
export class PolicyAdminPageComponent {
  protected _errorMessage = '';

  private readonly _searchResult$ = new BehaviorSubject<SearchPolicyResponse | null>(null);

  public readonly searchResult$ = this._searchResult$.asObservable();

  protected handleSearchResult(data: SearchPolicyResponse): void {
    if (data.length === 0) {
      this._errorMessage = 'poladmin.0.NOPOLFOUND';
      this._searchResult$.next(null);
      return;
    }
    this._searchResult$.next(data);
  }

  protected handleSearchError(error: string): void {
    this._searchResult$.next(null);
    this._errorMessage = error;
  }
}
