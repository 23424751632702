import { PolicyStatusValue } from '@agentmax/shared/enums/policy-status-value.enum';
import { SearchPolicyResponse } from '@agentmax/shared/types/search-policy-response.type';
import { SearchPolicyTableView } from '@agentmax/shared/types/search-policy-table-view.type';
import { Injectable } from '@angular/core';
import { DateService } from '../../../shared/services/date/date.service';

const POLICY_TYPES = {
  E: 'tripdetailpage.0.ANNUAL',
  K: 'tripdetailpage.0.SINGLETRIP',
  LP: 'tripdetailpage.0.ANNUAL',
};

const POLICY_STATUS = {
  A: 'poladmin.0.EFFECTIVE',
  S: 'poladmin.0.CANCELLED',
};

const STATUS_EFFECTIVE = 'A';
const BADGE_POSITIVE = 'positive';
const BADGE_NEGATIVE = 'critical';

@Injectable({
  providedIn: 'root',
})
export class SearchPolicyTableMapperService {
  constructor(private readonly dateService: DateService) {}

  public fromResponseValueToDataView(
    searchPolicyResponse: SearchPolicyResponse,
  ): SearchPolicyTableView {
    return searchPolicyResponse
      .map((policy) => {
        const name = this.buildName(policy.contractHolder);
        const policyType = this.buildPolicyType(policy);
        const status = POLICY_STATUS[policy.contractStatus as keyof typeof POLICY_STATUS];
        const grossPremium = this.getGrossPremium(policy.grossPremium);
        const currency = this.getCurrency(policy.grossPremium);
        const statusType =
          policy.contractStatus === STATUS_EFFECTIVE ? BADGE_POSITIVE : BADGE_NEGATIVE;

        const purchaseDate = policy.extEntity.purchaseDate;
        const contractStartDate = this.dateService.removeTime(
          policy.contractInterval.startDateTime,
        );
        const contractEndDate = this.buildContractEndDate(policy);

        return {
          externalContractNumber: policy.extEntity.externalContractNumber,
          productOfferingName: policy.extEntity.productOfferingName,
          issuedBy: policy.extEntity.issuedBy,
          externalAgent: policy.extEntity.externalAgent,
          name,
          purchaseDate,
          contractStartDate,
          contractEndDate,
          policyType,
          status,
          statusType,
          grossPremium,
          currency,
        };
      })
      .sort((a, b) =>
        a.purchaseDate > b.purchaseDate ? -1 : a.purchaseDate < b.purchaseDate ? 1 : 0,
      );
  }

  private buildName(contractHolder: { firstName?: string; name: string }): string {
    const firstName = contractHolder.firstName ? contractHolder.firstName : '';
    const lastName = contractHolder.name ? contractHolder.name : '';
    return `${firstName} ${lastName}`;
  }

  private buildPolicyType(policy: { contractSubType: string; contractType: string }): string {
    const policyTypeValue =
      policy.contractSubType && policy.contractSubType != ' '
        ? policy.contractSubType
        : policy.contractType;
    return POLICY_TYPES[policyTypeValue as keyof typeof POLICY_TYPES];
  }

  private buildContractEndDate(policy: {
    contractStatus: string;
    inceptionDate: string;
    contractInterval: { startDateTime: string };
  }): string {
    return policy.contractStatus === PolicyStatusValue.CANCELLED
      ? policy.inceptionDate
      : this.dateService.removeTime(policy.contractInterval.startDateTime);
  }

  private getGrossPremium(grossPremium: string): number {
    return parseFloat(grossPremium.split(' ')[1]);
  }

  private getCurrency(grossPremium: string): string {
    return grossPremium.split(' ')[0];
  }
}
