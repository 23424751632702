import { Injectable } from '@angular/core';

import { BaseSessionStorageService } from './base-session-storage.service';
import { LanguageOptionsName } from '@agentmax/shared/enums/language-options-name.enum';
import { SessionStorageKey } from '@agentmax/shared/enums/session-storage-key.enum';

@Injectable({
  providedIn: 'root',
})
export class LanguageOptionsNameSessionStorageService extends BaseSessionStorageService<LanguageOptionsName> {
  protected readonly key = SessionStorageKey.LANGUAGE_OPTIONS_NAME;
}
