<app-change-language-helper></app-change-language-helper>
<div class="u-text-center">
  <form
    class="form"
    [formGroup]="_statusForm"
    (ngSubmit)="handleStatusFormSubmit()"
    *ngIf="!(updateRequestStatus$ | async); else requestResult"
  >
    <div nxRow>
      <div nxCol="9">
        <nx-formfield
          class="form__item"
          [label]="'viewofferdetails.0.UPDATESTATUS' | translate | uppercase"
          appearance="outline"
        >
          <nx-dropdown formControlName="status">
            <nx-dropdown-item
              *ngFor="let status of allowedStatus"
              [value]="status"
              >{{ 'viewofferdetails.0.' + status | translate | uppercase }}</nx-dropdown-item
            >
          </nx-dropdown>
        </nx-formfield>
      </div>
      <div
        class="vertical-centered"
        nxCol="3"
      >
        <button
          nxButton="primary"
          type="submit"
          [disabled]="_statusForm.invalid"
        >
          {{ 'viewofferdetails.0.SAVE' | translate }}
        </button>
      </div>
    </div>
  </form>
  <ng-template #requestResult>
    <div
      nxRow
      *ngIf="(updateRequestStatus$ | async) === 'success'"
    >
      <div nxCol="12">
        <nx-message
          context="success"
          class="nx-margin-bottom-xs"
        >
          {{ 'viewofferdetails.0.SUCCESS' | translate }}
        </nx-message>
      </div>
    </div>
    <div
      nxRow
      *ngIf="(updateRequestStatus$ | async) === 'error'"
    >
      <div nxCol="12">
        <nx-message
          context="error"
          class="nx-margin-bottom-xs"
        >
          {{ 'viewofferdetails.0.ERROR' | translate }}
        </nx-message>
      </div>
    </div>
  </ng-template>
</div>
