import { Injectable } from '@angular/core';
import {
  ALLIANZ_PARTNERS_HOSTS,
  MONDIAL_ASSISTANCE_HOSTS,
  GLOBAL_ASSISTANCE_HOSTS,
  ALLIANZ_TRAVEL_HOSTS,
} from '@agentmax/core/deprecated-global-dependencies/hosts';
import { Environment } from 'src/app/shared/enums/environment.enum';

const PROD_DOMAINS = ['allianztravel', 'agentmax', 'allianzpartners'];

@Injectable({
  providedIn: 'root',
})
export class HostService {
  public isAllianzPartnersHost(host: string): boolean {
    const hostWithoutPrefix = this.removeWwwPrefix(host);

    return ALLIANZ_PARTNERS_HOSTS.some((h) => {
      return h === hostWithoutPrefix;
    });
  }

  public isMondialAssistanceHost(host: string): boolean {
    const hostWithoutPrefix = this.removeWwwPrefix(host);

    return MONDIAL_ASSISTANCE_HOSTS.some((h) => {
      return h === hostWithoutPrefix;
    });
  }

  public isGlobalAssistanceHost(host: string): boolean {
    const hostWithoutPrefix = this.removeWwwPrefix(host);

    return GLOBAL_ASSISTANCE_HOSTS.some((h) => {
      return h === hostWithoutPrefix;
    });
  }

  public isAllianzTravelHost(host: string): boolean {
    const hostWithoutPrefix = this.removeWwwPrefix(host);

    return ALLIANZ_TRAVEL_HOSTS.some((h) => {
      return h === hostWithoutPrefix;
    });
  }

  public getDomainInfo(host: string): string {
    const isProdDomain = PROD_DOMAINS.some((prodDomain) => host.includes(prodDomain));

    if (isProdDomain) {
      return Environment.PROD;
    }

    if (host.includes('.pp.')) {
      return Environment.PREPROD;
    }

    if (host.includes('.uat.')) {
      return Environment.UAT;
    }

    return Environment.DEV;
  }

  private removeWwwPrefix(host: string): string {
    return host.replace('www.', '');
  }
}
