import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { DataAccessorService } from '../../../shared/services/data-accessor/data-accessor.service';

import { BUConfig } from '../../../shared/types/ui-config.type';

@Component({
  selector: 'app-bu-configuration',
  templateUrl: './bu-configuration.component.html',
  styleUrls: ['./bu-configuration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BuConfigurationComponent implements OnInit {
  protected _buConfigBySections: ReadonlyArray<{
    title: string;
    data: unknown;
  }> | null = null;

  constructor(private readonly _dataAccessorService: DataAccessorService) {}

  public ngOnInit(): void {
    this.divideBuConfigToSections(this._dataAccessorService.getConfigFileOrNull());
  }

  private divideBuConfigToSections(buConfig: BUConfig | null): void {
    if (buConfig === null) {
      return;
    }

    this._buConfigBySections = Object.entries(buConfig).map(([key, value]) => ({
      title: key,
      data: value,
    }));
  }
}
