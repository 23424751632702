import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, catchError } from 'rxjs';

import { HttpEndpointsService } from '../../../shared/services/http-endpoints/http-endpoints.service';

import { isObject } from '../../../shared/type-assertions/is-object/is-object.type-assertion';
import { isString } from '../../../shared/type-assertions/is-string/is-string.type-assertion';
import { CreatePasswordDto } from '../../../login/dtos/create-password.dto';
import { BaseResponseData } from '@agentmax/shared/types/base-response-data.type';

@Injectable({
  providedIn: 'root',
})
export class CreatePasswordHttpService {
  constructor(
    private readonly _httpClient: HttpClient,
    private readonly _httpEndpointsService: HttpEndpointsService,
  ) {}

  public createPassword(createPasswordDto: CreatePasswordDto): Observable<BaseResponseData> {
    return this._httpClient
      .post<BaseResponseData>(this._httpEndpointsService.buildResetPasswordUrl(), createPasswordDto)
      .pipe(
        catchError((httpErrorResponse: HttpErrorResponse) => {
          const { error } = httpErrorResponse;

          if (
            isObject(error) &&
            isString(error['onePortalMessageCode']) &&
            error['onePortalMessageCode'] !== ''
          ) {
            throw new Error(error['onePortalMessageCode']);
          }

          throw new Error('Unknown error');
        }),
      );
  }
}
