/**
 * @description Represents all possible UI environments names. Do not mix it up with
 * the environment info we get once we make a /configuration http request.
 */
export enum UiEnvironmentName {
  INT = 'int',
  INT2 = 'int2',
  UAT = 'uat',
  UAT2 = 'uat2',
  DEV = 'dev',
  PROD = 'prod',
  PRE_PROD = 'pre-prod',
  WEB_COMPONENT = 'web-component',
}
