import { ChangeDetectionStrategy, Component, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-change-language-helper',
  templateUrl: './change-language-helper.component.html',
  styleUrls: ['./change-language-helper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeLanguageHelperComponent {
  //This component will be the one in charge to detect language changes on angularJS app

  constructor(private readonly translateService: TranslateService) {}

  //This event is the one we receive from AngularJS app to change the language
  @HostListener('window:changedLanguage', ['$event'])
  onChangedLanguage(event: CustomEvent) {
    this.translateService.use(event.detail);
  }
}
