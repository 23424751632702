<div class="quotation-stepper-layout">
  <div class="stepper-container">
    <nx-multi-stepper class="stepper">
      <nx-step
        *ngFor="let step of _steps"
        [label]="step"
      ></nx-step>
    </nx-multi-stepper>
  </div>

  <div class="content">
    <router-outlet></router-outlet>
  </div>
</div>
