import { Injectable } from '@angular/core';

import { LoginPageViewService } from '../services/login-page-view.service';
import { DataRecordingService } from '../../shared/services/data-recording/data-recording.service';
import { UserInfoMapperService } from '../../core/mappers/user-info/user-info-mapper.service';

import { LoginStrategy } from '../classes/login.strategy';
import { LoginResponseData } from '../../shared/types/login-response-data.type';

@Injectable({
  providedIn: 'root',
})
export class PasswordExpiredStrategy extends LoginStrategy {
  constructor(
    private readonly _loginPageViewService: LoginPageViewService,
    private readonly _dataRecordingService: DataRecordingService,
    private readonly _userInfoMapperService: UserInfoMapperService,
  ) {
    super();
  }

  public execute(data: LoginResponseData): void {
    this._dataRecordingService.saveAccessToken(data.accessToken);
    this._dataRecordingService.saveRefreshToken(data.refreshToken);
    this._dataRecordingService.saveUserInformation(
      this._userInfoMapperService.fromLoginDataToUserInformation(data),
    );
    this._loginPageViewService.switchToForcePasswordResetView();
  }
}
