import { Injectable } from '@angular/core';
import { tap } from 'rxjs';
import { UserIdleService } from 'angular-user-idle';

import { DataRecordingService } from '../../shared/services/data-recording/data-recording.service';
import { AppInitializationService } from '../../core/services/app-initialization/app-initialization.service';
import { NavigationService } from '../../core/services/navigation/navigation.service';

import { LoginStrategy } from '../classes/login.strategy';
import { LoginResponseData } from '../../shared/types/login-response-data.type';

@Injectable({
  providedIn: 'root',
})
export class SuccessStrategy extends LoginStrategy {
  constructor(
    private readonly _dataRecordingService: DataRecordingService,
    private readonly _appInitializationService: AppInitializationService,
    private readonly _userIdleService: UserIdleService,
    private readonly _navigationService: NavigationService,
  ) {
    super();
  }

  public execute(data: LoginResponseData): void {
    this._dataRecordingService.saveAccessToken(data.accessToken);
    this._dataRecordingService.saveRefreshToken(data.refreshToken);
    this._appInitializationService
      .initialize(data)
      .pipe(
        tap(() => {
          this._userIdleService.startWatching();
          this._navigationService.navigateToApp();
        }),
      )
      .subscribe();
  }
}
