import { Injectable, inject } from '@angular/core';

import { UserService } from '../../../shared/services/user/user.service';

import { NavLinksFilter } from './nav-links-filter.interface';
import { NavLinkListType } from '../../../shared/types/nav-links.type';
import { NavLinkId } from '../../../shared/enums/nav-link-id.enum';

@Injectable()
export class HeadReportLinkPermissionFilter implements NavLinksFilter {
  private readonly _userService = inject(UserService);

  public filter(navLinks: NavLinkListType): NavLinkListType {
    if (!this._userService.userHasPermissionForComponent('cIdHeadReportLink')) {
      return navLinks.filter((link) => link.id !== NavLinkId.REPORTS);
    }

    return navLinks;
  }
}
