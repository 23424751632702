import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

import { ApplicationKeyValueType } from '../../../shared/types/application-key-value.type';

@Component({
  selector: 'app-application-key-value-table',
  templateUrl: 'application-key-value-table.component.html',
  styleUrls: ['./application-key-value-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplicationKeyValueTableComponent {
  @Input()
  public set tableItems(value: ReadonlyArray<ApplicationKeyValueType>) {
    this._tableItems = value;
  }

  protected _tableItems: ReadonlyArray<ApplicationKeyValueType> = [];
}
