import { Injectable } from '@angular/core';

import { ShaService } from '../../../shared/services/sha/sha.service';

import { CreatePasswordDto } from '../../../login/dtos/create-password.dto';
import { CreatePasswordFormValueType } from '../../../login/types/create-password-form-value.type';

@Injectable({
  providedIn: 'root',
})
export class CreatePasswordMapperService {
  constructor(private readonly _shaService: ShaService) {}

  public fromFormValueAndUserEmailToDto(
    createPasswordFormValue: CreatePasswordFormValueType,
    userEmail: string,
  ): CreatePasswordDto {
    const { password, question1, answer1, question2, answer2 } = createPasswordFormValue;
    return {
      password: this._shaService.toSHA256(password),
      questions: [
        {
          question: question1,
          answer: this._shaService.toSHA256(answer1),
        },
        {
          question: question2,
          answer: this._shaService.toSHA256(answer2),
        },
      ],
      userId: userEmail,
    };
  }
}
