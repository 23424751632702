<app-change-language-helper></app-change-language-helper>
<div nxLayout="grid">
  <button
    nxButton="secondary"
    (click)="goBackToQuoteAdmin()"
    class="nx-margin-bottom-m"
    type="button"
  >
    {{
        'viewofferdetails.0.BACK' | translate }}
  </button>
  <h1
    nxHeadline="page"
    class="nx-margin-bottom-5xl"
  >
    {{ 'viewofferdetails.0.QUOTEDETAILS' | translate | uppercase }}
  </h1>

  <div
    nxRow
    class="nx-margin-bottom-2m"
  >
    <div nxCol="6">
      <button
        nxButton="secondary"
        type="button"
        *ngIf="canEdit | async"
        (click)="openUpdateStatusModal()"
      >
        {{
                'viewofferdetails.0.EDITSTATUS' | translate }}
      </button>
    </div>
    <div nxCol="6">
      <button
        nxButton="primary"
        class="right-button"
        type="button"
      >
        {{ 'viewofferdetails.0.PURCHASEQUOTE' |
                translate }}
      </button>
    </div>
  </div>
  <div
    nxRow
    class="nx-margin-bottom-2m"
  >
    <div nxCol="6">
      <div class="details-box">
        <div
          nxRow
          class="nx-margin-bottom-2m"
        >
          <div nxCol="12">
            <nx-data-display>
              <nx-data-display-label>
                {{ 'viewofferdetails.0.QUOTEDETAILS' | translate | uppercase }}
              </nx-data-display-label>
            </nx-data-display>
          </div>
        </div>
        <div
          nxRow
          class="nx-margin-bottom-m"
        >
          <div nxCol="6">
            <nx-data-display>
              {{ 'viewofferdetails.0.FIRSTNAME' | translate | uppercase }}
            </nx-data-display>
          </div>
          <div nxCol="6">
            <nx-data-display class="float-right">
              {{ quoteOfferDetails.snrContract.contactName | uppercase }}
            </nx-data-display>
          </div>
        </div>
        <div
          nxRow
          class="nx-margin-bottom-m"
        >
          <div nxCol="6">
            <nx-data-display>
              {{ 'viewofferdetails.0.LASTNAME' | translate | uppercase }}
            </nx-data-display>
          </div>
          <div nxCol="6">
            <nx-data-display class="float-right">
              {{ quoteOfferDetails.snrContract.contactSurname | uppercase }}
            </nx-data-display>
          </div>
        </div>
        <div
          nxRow
          class="nx-margin-bottom-m"
        >
          <div nxCol="6">
            <nx-data-display>
              {{ 'viewofferdetails.0.EMAILADDRESS' | translate | uppercase }}
            </nx-data-display>
          </div>
          <div nxCol="6">
            <nx-data-display
              class="float-right"
              class="long-text"
            >
              {{ quoteOfferDetails.snrContract.contactEmail | uppercase }}
            </nx-data-display>
          </div>
        </div>
        <div nxRow>
          <div nxCol="6">
            <nx-data-display>
              {{ 'viewofferdetails.0.CONSENT' | translate | uppercase }}
            </nx-data-display>
          </div>
          <div nxCol="6">
            <nx-data-display class="float-right">
              {{ 'viewofferdetails.0.YES' | translate | uppercase }}
            </nx-data-display>
          </div>
        </div>
      </div>
    </div>
    <div nxCol="6">
      <div class="details-box">
        <div
          nxRow
          class="nx-margin-bottom-2m"
        >
          <div nxCol="12">
            <nx-data-display>
              <nx-data-display-label>
                {{ 'viewofferdetails.0.BOOKINGDETAILS' | translate | uppercase }}
              </nx-data-display-label>
            </nx-data-display>
          </div>
        </div>
        <div
          nxRow
          class="nx-margin-bottom-m"
        >
          <div nxCol="6">
            <nx-data-display>
              {{ 'viewofferdetails.0.OFFERID' | translate | uppercase }}
            </nx-data-display>
          </div>
          <div nxCol="6">
            <nx-data-display class="float-right">
              {{ quoteOfferDetails.snrContract.quoteId | uppercase }}
            </nx-data-display>
          </div>
        </div>
        <div
          nxRow
          class="nx-margin-bottom-m"
        >
          <div nxCol="6">
            <nx-data-display>
              {{ 'viewofferdetails.0.SAVEDON' | translate | uppercase }}
            </nx-data-display>
          </div>
          <div nxCol="6">
            <nx-data-display class="float-right">
              {{ createdDate }}
            </nx-data-display>
          </div>
        </div>
        <div
          nxRow
          class="nx-margin-bottom-m"
        >
          <div nxCol="6">
            <nx-data-display>
              {{ 'viewofferdetails.0.SAVEDBY' | translate | uppercase }}
            </nx-data-display>
          </div>
          <div nxCol="6">
            <nx-data-display class="float-right">
              {{ quoteOfferDetails.snrContract.createdBy | uppercase }}
            </nx-data-display>
          </div>
        </div>
        <div nxRow>
          <div nxCol="6">
            <nx-data-display>
              {{ 'viewofferdetails.0.TOTALPREMIUM' | translate | uppercase }}
            </nx-data-display>
          </div>
          <div nxCol="6">
            <nx-data-display class="float-right">
              {{ currency + " " + quoteOfferDetails.saveQuoteDetail.premium }}
            </nx-data-display>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    nxRow
    class="nx-margin-bottom-2m"
  >
    <div nxCol="6"></div>
    <div nxCol="6">
      <div class="details-box">
        <div
          nxRow
          class="nx-margin-bottom-2m"
        >
          <div nxCol="12">
            <nx-data-display>
              <nx-data-display-label
                >{{ 'viewofferdetails.0.TRIPDETAILS' | translate | uppercase }}
              </nx-data-display-label>
            </nx-data-display>
          </div>
        </div>
        <div
          nxRow
          class="nx-margin-bottom-m"
        >
          <div nxCol="6">
            <nx-data-display>
              {{ 'viewofferdetails.0.POLICYTYPE' | translate | uppercase }}
            </nx-data-display>
          </div>
          <div nxCol="6">
            <nx-data-display class="float-right">
              <p *ngFor="let voyageType of voyageTypeText">
                {{ voyageType | translate | uppercase}}
              </p>
            </nx-data-display>
          </div>
        </div>
        <div
          nxRow
          class="nx-margin-bottom-m"
        >
          <div nxCol="6">
            <nx-data-display>
              {{ 'viewofferdetails.0.DESTNREGION' | translate | uppercase }}
            </nx-data-display>
          </div>
          <div nxCol="6">
            <nx-data-display class="float-right">
              {{ 'tripdetailpage.0.'+ travelRegion | translate | uppercase }}
            </nx-data-display>
          </div>
        </div>
        <div
          nxRow
          class="nx-margin-bottom-m"
        >
          <div nxCol="6">
            <nx-data-display>
              {{ 'viewofferdetails.0.TRIPSTARTDATE' | translate | uppercase }}
            </nx-data-display>
          </div>
          <div nxCol="6">
            <nx-data-display class="float-right">
              {{ quoteOfferDetails.saveQuoteDetail.tripStartDate }}
            </nx-data-display>
          </div>
        </div>
        <div
          nxRow
          class="nx-margin-bottom-m"
        >
          <div nxCol="6">
            <nx-data-display>
              {{ 'viewofferdetails.0.TRIPENDDATE' | translate | uppercase }}
            </nx-data-display>
          </div>
          <div nxCol="6">
            <nx-data-display class="float-right">
              {{ quoteOfferDetails.saveQuoteDetail.tripEndDate }}
            </nx-data-display>
          </div>
        </div>
        <div
          nxRow
          class="nx-margin-bottom-m"
        >
          <div nxCol="6">
            <nx-data-display>
              {{ 'viewofferdetails.0.INSUREDTYPE' | translate | uppercase }}
            </nx-data-display>
          </div>
          <div nxCol="6">
            <nx-data-display class="float-right">
              {{ insuredType | translate | uppercase }}
            </nx-data-display>
          </div>
        </div>
        <div
          nxRow
          class="nx-margin-bottom-m"
        >
          <div nxCol="6">
            <nx-data-display>
              {{ 'viewofferdetails.0.NUMTRAVELLERS' | translate | uppercase }}
            </nx-data-display>
          </div>
          <div nxCol="6">
            <nx-data-display class="float-right">
              {{ quoteOfferDetails.saveQuoteDetail.request.contract.travels[0].travellers.length }}
            </nx-data-display>
          </div>
        </div>
        <div nxRow>
          <div nxCol="6">
            <nx-data-display>
              {{ 'viewofferdetails.0.PRODUCTNAME' | translate | uppercase }}
            </nx-data-display>
          </div>
          <div nxCol="6">
            <nx-data-display class="float-right">
              {{ quoteOfferDetails.saveQuoteDetail.product }}
            </nx-data-display>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    nxRow
    class="nx-margin-bottom-2m"
  >
    <div nxCol="12">
      <div class="details-box">
        <div nxRow>
          <div nxCol="12">
            <nx-data-display>
              <nx-data-display-label
                >{{ 'viewofferdetails.0.COMMENT' | translate | uppercase }}
              </nx-data-display-label>
            </nx-data-display>
          </div>
        </div>
        <div nxRow>
          <div nxCol="12">
            <nx-data-display>
              <nx-data-display-label class="long-text"
                ><span #commentLabel>{{ comment$ | async}}</span>
              </nx-data-display-label>
            </nx-data-display>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div nxRow>
    <div nxCol="6">
      <button
        nxButton="secondary"
        type="button"
        (click)="openUpdateCommentModal(commentLabel.textContent)"
      >
        {{
                'viewofferdetails.0.UPDATECOMMENT' | translate }}
      </button>
    </div>
    <div nxCol="6">
      <button
        nxButton="primary"
        class="right-button"
        type="button"
        (click)="declineOffer()"
        *ngIf="canDecline | async"
      >
        {{ 'viewofferdetails.0.DECLINEOFFER' | translate }}
      </button>
    </div>
  </div>
</div>
