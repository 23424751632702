import { ChangeDetectionStrategy, Component } from '@angular/core';

import { NavHeaderService } from '../../services/nav-header/nav-header.service';

@Component({
  selector: 'app-nav-header',
  templateUrl: './nav-header.component.html',
  styleUrls: ['./nav-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavHeaderComponent {
  protected readonly _linkList = this._navHeaderService.generateNavLinks();

  constructor(private readonly _navHeaderService: NavHeaderService) {}
}
