import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { NgIf, NgFor } from '@angular/common';
import { ReactiveFormsModule, FormControl, FormGroup } from '@angular/forms';
import { NxGridModule } from '@aposin/ng-aquila/grid';
import { NxFormfieldModule } from '@aposin/ng-aquila/formfield';
import { NxDropdownModule } from '@aposin/ng-aquila/dropdown';
import { NxButtonModule } from '@aposin/ng-aquila/button';
import { TranslateModule } from '@ngx-translate/core';
import { tap, takeUntil, catchError, throwError } from 'rxjs';
import { Moment } from 'moment';

import { TextFieldControlComponent } from '../../../shared/components/text-field-control/text-field-control.component';
import { DateControlComponent } from '../../../shared/components/date-control/date-control.component';

import { SearchPolicyMapperService } from '../../../core/mappers/search-policy/search-policy-mapper.service';
import { SearchPolicyHttpService } from '../../../core/http/search-policy/search-policy-http.service';
import { CustomValidators } from '../../../shared/validators/custom-validators.class';

import { DestroyEmitter } from '../../../shared/classes/destroy-emitter.class';
import {
  SEARCH_POLICY_STATUS_OPTIONS,
  SEARCH_POLICY_STATUS_OPTIONS_VALUE,
  SEARCH_POLICY_TYPE_OPTIONS,
  SEARCH_POLICY_TYPE_OPTIONS_VALUE,
} from './search-policy-form.config';
import { PolicyTypeValue } from '../../../shared/enums/policy-type-value.enum';
import { PolicyStatusValue } from '../../../shared/enums/policy-status-value.enum';
import { UserService } from '@agentmax/shared/services/user/user.service';
import { SearchPolicyResponse } from '@agentmax/shared/types/search-policy-response.type';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-search-policy-form',
  templateUrl: './search-policy-form.component.html',
  styleUrls: ['./search-policy-form.component.scss'],
  providers: [
    {
      provide: SEARCH_POLICY_TYPE_OPTIONS,
      useValue: SEARCH_POLICY_TYPE_OPTIONS_VALUE,
    },
    {
      provide: SEARCH_POLICY_STATUS_OPTIONS,
      useValue: SEARCH_POLICY_STATUS_OPTIONS_VALUE,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgFor,
    NgIf,
    ReactiveFormsModule,
    NxGridModule,
    NxFormfieldModule,
    NxDropdownModule,
    NxButtonModule,
    TranslateModule,
    TextFieldControlComponent,
    DateControlComponent,
  ],
})
export class SearchPolicyFormComponent extends DestroyEmitter implements OnInit {
  protected readonly _searchPolicyTypeOptions = inject(SEARCH_POLICY_TYPE_OPTIONS);
  protected readonly _searchPolicyStatusOptions = inject(SEARCH_POLICY_STATUS_OPTIONS);
  private readonly _searchPolicyMapperService = inject(SearchPolicyMapperService);
  private readonly _searchPolicyHttpService = inject(SearchPolicyHttpService);
  private readonly _userService = inject(UserService);
  private readonly _cdRef = inject(ChangeDetectorRef);

  public readonly isInternal = this._userService.isInternalUser();

  @Output()
  public readonly resolve = new EventEmitter<SearchPolicyResponse>();

  @Output()
  public readonly searchError = new EventEmitter<string>();

  protected readonly _searchPolicyForm = new FormGroup(
    {
      policyNumber: new FormControl('', { nonNullable: true }),
      beneficiaryFirstName: new FormControl('', { nonNullable: true }),
      beneficiaryLastName: new FormControl('', { nonNullable: true }),
      policyType: new FormControl<'' | PolicyTypeValue>('', {
        nonNullable: true,
      }),
      policyStatus: new FormControl<'' | PolicyStatusValue>('', {
        nonNullable: true,
      }),
      productName: new FormControl('', { nonNullable: true }),
      policyStartDateFrom: new FormControl<Moment | null>(null, { nonNullable: true }),
      policyStartDateTo: new FormControl<Moment | null>(null, { nonNullable: true }),
      partnerName: new FormControl('', { nonNullable: true }),
      purchaseDateFrom: new FormControl<Moment | null>(null, { nonNullable: true }),
      purchaseDateTo: new FormControl<Moment | null>(null, { nonNullable: true }),
      partnerNumber: new FormControl('', { nonNullable: true }),
    },
    { validators: [CustomValidators.nonEmptyForm] },
  );

  ngOnInit(): void {
    if (!this.isInternal) {
      this.searchPolicy();
    }
  }

  protected handleSearchPolicyFormSubmit(): void {
    if (this._searchPolicyForm.invalid) {
      return;
    }

    this.searchPolicy();
  }

  private searchPolicy(): void {
    const searchPolicyFormValue = this._searchPolicyForm.getRawValue();
    const searchPolicyDto =
      this._searchPolicyMapperService.fromFormValueToDto(searchPolicyFormValue);
    this._searchPolicyHttpService
      .searchPolicy(searchPolicyDto)
      .pipe(
        tap((data) => this.resolve.emit(data)),
        catchError((httpErrorResponse: HttpErrorResponse) => {
          this.handleServerError(httpErrorResponse);
          this._cdRef.markForCheck();
          return throwError(() => httpErrorResponse);
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private handleServerError(httpErrorResponse: HttpErrorResponse): void {
    let erroCode = 'SP_001';
    if (httpErrorResponse.error && httpErrorResponse.error.onePortalMessageCode) {
      erroCode = httpErrorResponse.error.onePortalMessageCode;
    }
    this.searchError.emit(`poladmin.0.${erroCode}`);
  }
}
