import { Injectable, Inject } from '@angular/core';
import { LOGOS_MAP } from '@agentmax/core/deprecated-global-dependencies/logos';
import { LogoDataType, LogosMapType } from 'src/app/shared/types/logos-map.type';
import { HostService } from '../../../shared/services/host/host.service';
import { Environment } from 'src/app/shared/enums/environment.enum';
import { LOCATION } from '../../deprecated-global-dependencies/location.global-dependency';
import { DataAccessorService } from '../../../shared/services/data-accessor/data-accessor.service';

@Injectable({
  providedIn: 'root',
})
export class LogoService {
  constructor(
    private readonly hostService: HostService,
    @Inject(LOCATION) private readonly location: Location,
    private readonly dataAccessorService: DataAccessorService,
  ) {}

  public getLogoData(logoName: string): LogoDataType {
    return LOGOS_MAP[logoName as keyof LogosMapType];
  }

  public determineLogoNameByHostAndEnvName(): string {
    const host = this.location.host;
    const envName = this.hostService.getDomainInfo(host);

    if (this.hostService.isAllianzPartnersHost(host)) {
      return 'allianzPartnersLogo';
    }

    if (this.hostService.isMondialAssistanceHost(host)) {
      return 'mondialAssistanceLogo';
    }

    if (this.hostService.isGlobalAssistanceHost(host) && envName === Environment.PROD) {
      return 'globalAssistanceLogo';
    }

    if (this.hostService.isAllianzTravelHost(host)) {
      return 'allianzTravelLogo';
    }

    return 'agentmaxLogo';
  }

  public determineInnerLeftLogoSrc(): string {
    const agencyBrandLogo = this.dataAccessorService.getBUBrand();
    const whiteLabel = this.dataAccessorService.getWhiteLabelFlag();
    const agencyLogo = this.dataAccessorService.getBUAgencyLogo();

    if (whiteLabel && !agencyBrandLogo && agencyLogo) {
      return `assets/logos/${agencyLogo}.png`;
    }

    if (!whiteLabel && agencyBrandLogo) {
      return `assets/logos/brand/${agencyBrandLogo}.png`;
    }

    return '';
  }

  public determineInnerRightLogoSrc(): string {
    const agencyBrandLogo = this.dataAccessorService.getBUBrand();
    const whiteLabel = this.dataAccessorService.getWhiteLabelFlag();
    const agencyLogo = this.dataAccessorService.getBUAgencyLogo();

    if (!whiteLabel && agencyBrandLogo && agencyLogo) {
      return `assets/logos/${agencyLogo}.png`;
    }

    return '';
  }
}
