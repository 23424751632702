import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Link } from 'src/app/shared/types/ui-config.type';
import { ConfigurationService } from '../../services/configuration/configuration.service';
import { AppConfigurationService } from '@agentmax/core/services/app-configuration/app-configuration.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent implements OnInit {
  public copyrightText = '';
  public shouldShowFooterLinks = false;
  public footerLinks: Link[] = [];

  constructor(
    private readonly configurationService: ConfigurationService,
    private readonly appConfigurationService: AppConfigurationService,
  ) {}

  ngOnInit() {
    this.appConfigurationService.configuration$.subscribe(() => {
      this.getFooterDataFromConfiguration();
    });
  }

  private getFooterDataFromConfiguration(): void {
    this.copyrightText = this.configurationService.getCopyrightText();
    this.shouldShowFooterLinks = this.configurationService.shouldShowFooterLinks();
    this.footerLinks = this.configurationService.getFooterLinksHtml();
  }
}
