import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { HttpEndpointsService } from '../../../shared/services/http-endpoints/http-endpoints.service';

import { VerifyUserEmailResponseData } from '../../../shared/types/verify-user-email-response-data.type';

@Injectable({
  providedIn: 'root',
})
export class VerifyUserEmailHttpService {
  constructor(
    private readonly _httpClient: HttpClient,
    private readonly _httpEndpointsService: HttpEndpointsService,
  ) {}

  public verifyUserEmail(emailId: string): Observable<VerifyUserEmailResponseData> {
    return this._httpClient.get<VerifyUserEmailResponseData>(
      this._httpEndpointsService.buildVerifyUserEmailUrl(emailId),
    );
  }
}
