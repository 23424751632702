import { NewLanguageCode } from '@agentmax/shared/enums/language-code.enum';
import { LanguageOptionsName } from '@agentmax/shared/enums/language-options-name.enum';

export namespace Languages {
  export class Select {
    public static readonly type = '[Languages] Select Language';

    constructor(public readonly selectedLanguageCode: NewLanguageCode) {}
  }

  export class Update {
    public static readonly type = '[Languages] Update Languages';

    constructor(
      public readonly selectedLanguageCode: NewLanguageCode,
      public readonly languageOptionsName: LanguageOptionsName,
    ) {}
  }

  export class Reset {
    public static readonly type = '[Languages] Reset Languages';
  }
}
