import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { DataAccessorService } from '@agentmax/shared/services/data-accessor/data-accessor.service';
import { QuoteOfferDetailsResponse } from '../../../shared/types/success-quote-offer-details-data.type';
import { TravelRegions } from '../../../shared/enums/travel-regions.enum';
import { VoyageTypeService } from 'src/app/quote-admin/services/voyage-type/voyage-type.service';
import { DateService } from '@agentmax/shared/services/date/date.service';
import { UpdateOfferCommentDialogComponent } from 'src/app/angularjs/dialogs/update-offer-comment-dialog/update-offer-comment-dialog.component';
import { NxDialogService, NxModalRef } from '@aposin/ng-aquila/modal';
import { OfferStatus } from 'src/app/shared/enums/offer-status.enum';
import { UpdateOfferService } from 'src/app/quote-admin/services/update-offer/update-offer.service';
import { UpdateOfferStatusDialogComponent } from '../../../core/dialogs/update-offer-status-dialog/update-offer-status-dialog.component';
import { BehaviorSubject, catchError, Observable, of } from 'rxjs';
import { UpdateOfferCommentService } from 'src/app/quote-admin/services/update-offer-comment/update-offer-comment.service';
import { SessionStorageService } from '../../../shared/services/session-storage/session-storage.service';
import { SessionStorageKey } from 'src/app/shared/enums/session-storage-key.enum';
import { DISPLAY_DATE_FORMAT } from '@agentmax/shared/dependencies/display-date-format';

@Component({
  selector: 'app-view-offer',
  templateUrl: './view-offer.component.html',
  styleUrls: ['./view-offer.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewOfferComponent implements OnInit {
  protected readonly quoteOfferDetails: QuoteOfferDetailsResponse =
    this.dataAccessorService.getQuoteOfferDetails();
  protected readonly currency: string = this.dataAccessorService.getCurrency();
  @Output() public readonly backToSearchOffer = new EventEmitter<string>();
  protected travelRegion = '';
  protected createdDate = '';
  protected insuredType = '';
  protected voyageTypeText: string[] = [];
  private updateCommentDialogRef?: NxModalRef<UpdateOfferCommentDialogComponent>;
  private allowedStatus: OfferStatus[] = [];
  private componentDialogRef?: NxModalRef<UpdateOfferStatusDialogComponent>;
  private readonly _canDecline$ = new BehaviorSubject<boolean>(false);
  public readonly canDecline = this._canDecline$.asObservable();
  private readonly _canEdit$ = new BehaviorSubject<boolean>(false);
  public readonly canEdit = this._canEdit$.asObservable();
  public comment$ = this.updateOfferCommentService.comment$;
  protected readonly DISPLAY_DATE_FORMAT = inject(DISPLAY_DATE_FORMAT);

  constructor(
    private readonly dataAccessorService: DataAccessorService,
    private readonly voyageTypeService: VoyageTypeService,
    private readonly dateService: DateService,
    private readonly updateOfferService: UpdateOfferService,
    private readonly dialogService: NxDialogService,
    private readonly updateOfferCommentService: UpdateOfferCommentService,
    private readonly sessionStorageService: SessionStorageService,
  ) {}

  ngOnInit() {
    this.travelRegion =
      TravelRegions[
        this.quoteOfferDetails.saveQuoteDetail.request.contract.travels[0].destinations[0]
          .destinationLocationName as keyof typeof TravelRegions
      ];
    this.createdDate = this.dateService.getDateAs(
      this.quoteOfferDetails.snrContract.createdDate,
      this.DISPLAY_DATE_FORMAT,
    );
    this.voyageTypeText = this.voyageTypeService.getVoyageTypeTranslation(
      this.quoteOfferDetails.saveQuoteDetail.request.contract.travels[0].travelType,
    );
    this.insuredType = `productspage.0.${this.quoteOfferDetails.saveQuoteDetail.response.customFieldType}`;
    this.allowedStatus = this.quoteOfferDetails.allowedStatus;
    this.updateOfferCommentService.setComment(
      this.sessionStorageService.getRawValueOrNull(SessionStorageKey.QUOTE_OFFER_COMMENT) !==
        null && this.dataAccessorService.getQuoteOfferComment()
        ? this.dataAccessorService.getQuoteOfferComment()
        : this.quoteOfferDetails.comment,
    );
    this._canDecline$.next(this.canDeclineOffer());
    this._canEdit$.next(this.canEditStatus());
  }

  public goBackToQuoteAdmin(): void {
    this.dataAccessorService.removeQuoteOfferDetails();
    if (
      this.sessionStorageService.getRawValueOrNull(SessionStorageKey.QUOTE_OFFER_COMMENT) !== null
    ) {
      this.dataAccessorService.removeQuoteOfferComment();
    }
    this.backToSearchOffer.emit('successGoBack');
  }
  public openUpdateCommentModal(comment: string | null): void {
    this.updateCommentDialogRef = this.dialogService.open(UpdateOfferCommentDialogComponent, {
      data: {
        comment: comment,
      },
      showCloseIcon: true,
      ariaLabel: 'Update Comment Dialog',
    });
  }
  private canDeclineOffer(): boolean {
    return this.allowedStatus.includes(OfferStatus.DECLINED);
  }

  private canEditStatus(): boolean {
    return this.allowedStatus.length > 0;
  }

  public openUpdateStatusModal(): void {
    const updateOfferStatusModalData = {
      allowedStatus: this.allowedStatus,
      quoteId: this.quoteOfferDetails.snrContract.quoteId,
    };
    this.componentDialogRef = this.dialogService.open(UpdateOfferStatusDialogComponent, {
      ariaLabel: 'Update Status Dialog',
      showCloseIcon: true,
      data: updateOfferStatusModalData,
    });

    this.componentDialogRef.afterClosed().subscribe((newAllowedStatus) => {
      this.allowedStatus = newAllowedStatus;
      this._canDecline$.next(this.canDeclineOffer());
      this._canEdit$.next(this.canEditStatus());
    });
  }

  public declineOffer(): void {
    this.updateOfferService
      .updateStatus(OfferStatus.DECLINED, this.quoteOfferDetails.snrContract.quoteId)
      .pipe(
        catchError((error: Error) => {
          throw error;
        }),
      )
      .subscribe((data) => {
        if (data.operationStatus === 'success') {
          this.allowedStatus = this.quoteOfferDetails.allowedStatus.filter(
            (statusItem) => statusItem !== OfferStatus.DECLINED,
          );
          this._canDecline$.next(this.canDeclineOffer());
          this._canEdit$.next(this.canEditStatus());
        }
      });
  }
}
