import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { HttpEndpointsService } from '../../../shared/services/http-endpoints/http-endpoints.service';
import { HttpWrapperService } from '../../../shared/services/http-wrapper/http-wrapper.service';
import { DataAccessorService } from '../../../shared/services/data-accessor/data-accessor.service';
import {
  SaveOfferParameters,
  SelectedOfferParameters,
  SnRDetailsParameters,
} from '../../../shared/types/save-offer.type';
import { SuccessSaveOfferResponseData } from '../../../shared/types/success-save-offer-response-data.type';
import { isDOBTravelerCategory, Quotation } from '../../../shared/types/quotation.type';
import { Policy } from '../../../shared/types/policy.type';
import { CustomValidators } from '../../../shared/validators/custom-validators.class';
import {
  EqsQuotationRequestParameters,
  TravellersParameters,
  TravelsParameters,
} from 'src/app/shared/types/eqs-quotation-request.type';
import { DateService } from '@agentmax/shared/services/date/date.service';
import { catchError, of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorService } from '@agentmax/shared/services/error/error.service';

//TODO add changedetection strategy
@Component({
  selector: 'app-save-offer',
  templateUrl: './save-offer.component.html',
  styleUrls: ['./save-offer.component.css'],
})
export class SaveOfferComponent {
  saveOfferForm = this.formBuilder.group({
    firstName: [''],
    lastName: [''],
    toEmail: this.formBuilder.control('', {
      validators: [Validators.required, CustomValidators.validateEmail],
      updateOn: 'blur',
    }),
    ccEmail: this.formBuilder.control(this.dataAccessorService.getAccountInformation().email, {
      validators: [CustomValidators.validateEmail],
      updateOn: 'blur',
    }),
    tyc: [false, Validators.requiredTrue],
  });
  errorOnSave = false;
  successOnSave = false;
  quoteId = '';

  constructor(
    private readonly dataAccessorService: DataAccessorService,
    private readonly httpWrapperService: HttpWrapperService,
    private readonly httpEndpointsService: HttpEndpointsService,
    private readonly formBuilder: FormBuilder,
    private readonly dateService: DateService,
    private readonly errorService: ErrorService,
  ) {}

  formSubmit(): void {
    this.errorOnSave = false;
    this.saveOfferForm.markAsTouched();
    if (!this.saveOfferForm.valid) {
      return;
    }

    const saveOfferRequest = this.generateSaveOfferRequest();
    this.saveOffer(saveOfferRequest);
  }

  //TODO all this functions should be in a service
  saveOffer(params: SaveOfferParameters): void {
    const saveOfferUrl = this.httpEndpointsService.buildSaveOfferUrl();
    this.httpWrapperService
      .post<SuccessSaveOfferResponseData>(saveOfferUrl, params)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.errorOnSave = true;
          return of(null);
        }),
      )
      .subscribe((data) => {
        if (data && data.snrContract.operationStatus === 'success') {
          this.successOnSave = true;
          this.quoteId = data.snrContract.quoteId;
        } else {
          this.errorOnSave = true;
        }
      });
  }

  generateSaveOfferRequest(): SaveOfferParameters {
    const quotation = this.dataAccessorService.getQuotationForSaveOffer();
    const selectedProduct = this.dataAccessorService.getSelectedProductForSaveOffer();
    return {
      snrDetails: this.generateSnRDetails(),
      eqsRequest: this.generateEqsRequest(quotation),
      selectedOffer: this.generateSelectedOffer(quotation, selectedProduct),
    };
  }

  generateSnRDetails(): SnRDetailsParameters {
    const partnerCode = this.getPartnerCode();
    return {
      progressDetails: {
        source: 'AgentMax',
        type: 'Offer',
        deletionDuration: '120',
        contactFirstName: this.saveOfferForm.controls['firstName'].value || '',
        contactLastName: this.saveOfferForm.controls['lastName'].value || '',
        contactEmail: this.saveOfferForm.controls['toEmail'].value || '',
        contactEmailCC: this.saveOfferForm.controls['ccEmail'].value || '',
      },
      agentMaxDetails: {
        selectedAgency: partnerCode,
      },
    };
  }

  generateEqsRequest(quotation: Quotation): EqsQuotationRequestParameters {
    const today = this.dateService.getToday();
    const contractIntervalStartDate = this.dateService.getDateAsISOString(
      quotation.voyage.departureDate,
      'DD.MM.YYYY',
    );
    const contractIntervalEndDate = this.dateService.getDateAsISOString(
      quotation.voyage.arrivalDate,
      'DD.MM.YYYY',
    );
    const partnerCode = this.getPartnerCode();

    return {
      contract: {
        marketScope: this.dataAccessorService.getUserCountryCode(),
        businessPartner: partnerCode,
        salesChannelInformation: {
          salesChannelCode: 'B2B',
          salesChannel: 'B2B',
        },
        language: this.dataAccessorService.getCurrentLanguageCode(),
        currency: this.dataAccessorService.getCurrency(),
        requestDate: today,
        contractInterval: {
          start: contractIntervalStartDate,
          end: contractIntervalEndDate,
        },
        travels: this.generateTravels(quotation),
        productOfferingPurpose: quotation.voyage.travelReason,
        coverages: [],
        insuredProperties: [], //TODO IN FUTURE
      },
    };
  }

  generateTravels(quotation: Quotation): TravelsParameters[] {
    const contractIntervalStartDate = this.dateService.getDateAsISOString(
      quotation.voyage.departureDate,
      'DD.MM.YYYY',
    );
    const contractIntervalEndDate = this.dateService.getDateAsISOString(
      quotation.voyage.arrivalDate,
      'DD.MM.YYYY',
    );

    return [
      {
        travelType: quotation.voyage.voyageType,
        originDate: contractIntervalStartDate,
        destinations: [
          {
            destinationDate: contractIntervalEndDate,
            destinationLocationType: 'zone',
            destinationLocationName: quotation.voyage.destination[0].area,
          },
        ],
        travellers: this.generateTravellers(quotation),
        residence: {
          countryCode: this.dataAccessorService.getUserCountryCode(),
        },
      },
    ];
  }

  generateTravellers(quotation: Quotation): TravellersParameters[] {
    const travellersArray: TravellersParameters[] = [];

    for (const travelerCategory of quotation.voyage.travelersCategories) {
      if (!isDOBTravelerCategory(travelerCategory)) {
        travellersArray.push({
          categoryName: [
            {
              perTravelerWithAge: {
                self: 1,
                medicalScreening: {
                  score: 0,
                },
                age: travelerCategory.age,
                numberOfTravelers: 1,
              },
            },
          ],
        });
      } else {
        travellersArray.push({
          categoryName: [
            {
              perTravelerWithDob: {
                self: 1,
                medicalScreening: {
                  score: 0,
                },
                dateOfBirth: travelerCategory.dateOfBirth,
                numberOfTravelers: 1,
              },
            },
          ],
        });
      }
    }
    return travellersArray;
  }

  generateSelectedOffer(quotation: Quotation, selectedProduct: Policy): SelectedOfferParameters {
    return {
      offerName: selectedProduct.offeringDisplayName,
      offerCode: selectedProduct.productOfferingSign,
      premium: `${this.dataAccessorService.getCurrency()} ${selectedProduct.grossPremium}`,
      totalTaxAmount: `${selectedProduct.totalTaxAmount}`,
      customFieldCode: quotation.contractFilter[0].customFieldCode,
      customFieldType: quotation.contractFilter[0].customFieldType,
      documents: selectedProduct.docs,
      publicationId: '', //TODO IN FUTURE
    };
  }

  private getPartnerCode(): string {
    const partnerCode = this.dataAccessorService.getSelectedAgency().partnerCode;
    if (partnerCode === null) {
      this.errorService.throwError(this.constructor.name, 'Selected Agency partner code is null!');
    }
    return partnerCode;
  }
}
