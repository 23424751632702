import { Injectable, ErrorHandler, inject } from '@angular/core';

import { CONSOLE } from '../../global/console';

@Injectable()
export class ProdErrorHandler implements ErrorHandler {
  private readonly _console = inject(CONSOLE);

  public handleError(_error: unknown): void {
    this._console.error('PRODUCTION ERROR', 'An unexpected error has occurred.');
  }
}
