export enum UserPermission {
    HOME = 'cIdHome',
    HEAD_LINK = 'cIdHeadLink',
    USER_LINK = 'cIdUserLink',
    HEADREPORT_LINK = 'cIdHeadReportLink',
    INTERNAL_USERS = 'cIdInternalUsers',
    SUPER_USER = 'cIdSuperUser',
    EXTERNAL_USERS = 'cIdExternalUsers',
    CREATE_NEW_ACCOUNT = 'cIdCrenewacc',
    SHOW_AGENCYID = 'cIdShowAgencyId',
    ACCESS_MANAGEMENT_TAB = 'cIdAcceMaTab',
    DEFAULT_AGENCYID = 'cIdDefaultAgencyId',
    CREATE_ADMIN_ACCOUNT = 'cIdCreateAdminAcc',
    EXCEPT_INTERNAL_USER = 'cIdExceptInternalUser',
    STANDARD_USER = 'cIdStandardUser',
    STANDARD_USER_WITH_ADMIN_RIGHTS = 'cIdStandarUserWithAdminRights',
    REPORT_LINK = 'cIdReportLink',
    INTERNAL_USER_WITH_ADMIN_RIGHTS = 'cIdInternalUserWithAdminRights',
    INTERNAL_USER_WITH_ADMIN_RIGHTS_SUPER_USER = 'cIdInternalUserWithAdminRights&cIdSuperUser',
    EXTERNAL_CLAIM_ONLY_USER = 'cIdExternalClaimOnlyUser',
    POLICY_MODIFY_USER = 'cIdPolicyModifiyOnlyUser',
    MANAGE_CLAIN_ONLY = 'cIdManageClaimOnly'
}  