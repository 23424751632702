import { Injectable } from '@angular/core';

import { BuLanguageService } from './bu-language.service';

import { NewLanguageCode } from '@agentmax/shared/enums/language-code.enum';

@Injectable({
  providedIn: 'root',
})
export class UKLanguageService extends BuLanguageService {
  public override getDefaultLanguageCode(
    _rawDefaultLanguageCode = '',
    _rawCountryCode = '',
  ): NewLanguageCode {
    return NewLanguageCode.ENGLISH;
  }
}
