import { Injectable } from '@angular/core';

import { ShaService } from '../../../shared/services/sha/sha.service';

import { LoginMapperServiceInterface } from './login-mapper-service.interface';
import { LoginFormValueType } from '../../../shared/types/login-form-value.type';
import { LoginDto } from '../../../shared/dtos/login.dto';

@Injectable({
  providedIn: 'root',
})
export class LoginMapperService implements LoginMapperServiceInterface {
  constructor(private readonly _shaService: ShaService) {}

  public fromFormValueToDto(loginFormValue: LoginFormValueType): LoginDto {
    return {
      userName: loginFormValue.email,
      password: this._shaService.toSHA256(loginFormValue.password),
    };
  }
}
