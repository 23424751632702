import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { ConfigurationService } from '../../../core/services/configuration/configuration.service';
import { DataAccessorService } from '../../../shared/services/data-accessor/data-accessor.service';
import { IFrameService } from '../../../shared/services/iframe/iframe.service';
import { UserService } from '../../../shared/services/user/user.service';
import { WINDOW } from '../../../core/deprecated-global-dependencies/window.global-dependency';
import { EnvironmentService } from '../../../core/services/environment/environment.service';
import { Select } from '@ngxs/store';
import { LanguagesState } from '@agentmax/store/languages';
import { Observable, map } from 'rxjs';
import { NewLanguageCode } from '@agentmax/shared/enums/language-code.enum';

const ONE_WEB_HELP_PAGE_ROUTE = '/help';

@Component({
  selector: 'app-help',
  templateUrl: './help-page.component.html',
  styleUrls: ['./help-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HelpPageComponent {
  @Select(LanguagesState.selectedCode)
  private readonly _selectedLanguageCode$!: Observable<NewLanguageCode>;

  private readonly userInfo = this.userService.getCurrentUsersInfo();
  private readonly brand = this.dataAccessorService.getBrandForNewsAndProductsPage();
  private readonly tenant = this.dataAccessorService.getUserCountryCode();

  protected readonly _iFrameURL$ = this._selectedLanguageCode$.pipe(
    map((selectedLanguageCode) =>
      this.iframeService.generateOneWebURL(
        ONE_WEB_HELP_PAGE_ROUTE,
        selectedLanguageCode,
        this.userInfo.clusterId,
        this.brand,
      ),
    ),
  );
  protected readonly showMarketingMaterial = this.configurationService.showMarketingLinks();

  constructor(
    @Inject(WINDOW)
    private readonly window: Window,
    private readonly userService: UserService,
    private readonly dataAccessorService: DataAccessorService,
    private readonly iframeService: IFrameService,
    private readonly configurationService: ConfigurationService,
    private readonly environmentService: EnvironmentService,
  ) {}

  orderMarketingMaterial(): void {
    const isInternalUser = this.userService.isInternalUser();
    const isExternalUser = this.userService.isExternalUser();
    const bpid = this.dataAccessorService.getPartnerCode();

    if (isExternalUser) {
      const bpidUrl = `bpid=${bpid}&tenant=${this.tenant}`;
      this.encodeAndOpenWindow(bpidUrl);
    } else if (isInternalUser) {
      const userUrl = `tenant=${this.tenant}&user=${this.userInfo['user']}&email=${this.userInfo['email']}`;
      this.encodeAndOpenWindow(userUrl);
    }
  }

  encodeAndOpenWindow(url: string): void {
    const encodedUrl = btoa(url);
    const urlSafeEncoded = encodeURIComponent(encodedUrl);
    this.window.open(
      `${this.environmentService.getBaseUrlMarketingMaterial()}token=${urlSafeEncoded}`,
    );
  }
}
