import { InjectionToken, inject } from '@angular/core';

import { ENVIRONMENT } from '@agentmax/global/environment';
import { ProdPageUrlLanguagesInitializationService } from './prod-page-url-languages-initialization.service';
import { DevPageUrlLanguagesInitializationService } from './dev-page-url-languages-initialization.service';

import { ILanguagesInitializationService } from './languages-initialization.service.interface';

export const PageUrlLanguagesInitializationService =
  new InjectionToken<ILanguagesInitializationService>('PageUrlLanguagesInitializationService', {
    providedIn: 'root',
    factory: () => {
      if (inject(ENVIRONMENT).isProduction) {
        return inject(ProdPageUrlLanguagesInitializationService);
      }

      return inject(DevPageUrlLanguagesInitializationService);
    },
  });
