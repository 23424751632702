import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

import { FeatureToggleList, SingleFeatureToggle } from '../../../shared/types/feature-toggle.type';
import { FeatureToggleStateChangeEvent } from '../../../shared/types/feature-toggle-state-change-event.type';

@Component({
  selector: 'app-feature-toggles-view',
  templateUrl: './feature-toggles-view.component.html',
  styleUrls: ['./feature-toggles-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeatureTogglesViewComponent {
  @Input()
  public set featureToggleList(value: FeatureToggleList) {
    this._featureToggleList = value;
  }

  @Output()
  public readonly featureToggleStateChange = new EventEmitter<FeatureToggleStateChangeEvent>();

  protected _featureToggleList: FeatureToggleList = [];

  protected trackByFeatureToggleKey(_index: number, featureToggle: SingleFeatureToggle): string {
    return featureToggle.key;
  }

  protected handleFeatureToggleCheckedChange(featureToggleKey: string, checked: boolean): void {
    this.featureToggleStateChange.emit({
      key: featureToggleKey,
      value: checked,
    });
  }
}
