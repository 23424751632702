import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  AfterViewInit,
  ViewChild,
  ChangeDetectorRef,
  inject,
} from '@angular/core';
import { UserService } from '../../../shared/services/user/user.service';
import { DataAccessorService } from '../../../shared/services/data-accessor/data-accessor.service';
import { NxSortHeaderIntl, SortEvent } from '@aposin/ng-aquila/table';
import { QuoteItemList } from '../../../shared/types/quote-details-list.type';
import { AgentmaxIntlService } from '../../../core/services/ndbx-table-sorting/AgentmaxIntlService';
import { SharedService } from '../../../shared/services/compare/compare.service';
import {
  SearchQuoteRequestData,
  SnrDetails,
} from '../../../shared/types/search-quote-request.type';
import { NonNullableFormBuilder } from '@angular/forms';
import { HttpWrapperService } from '../../../shared/services/http-wrapper/http-wrapper.service';
import { HttpEndpointsService } from '../../../shared/services/http-endpoints/http-endpoints.service';
import { NxExpansionPanelHeaderComponent } from '@aposin/ng-aquila/accordion';
import { SearchQuoteResponseData } from 'src/app/shared/types/search-quote-response.type';
import { QuoteOfferDetailsResponse } from 'src/app/shared/types/success-quote-offer-details-data.type';
import { DateService } from '@agentmax/shared/services/date/date.service';
import { catchError, of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { DataRecordingService } from '@agentmax/shared/services/data-recording/data-recording.service';
import { SessionStorageService } from '../../../shared/services/session-storage/session-storage.service';
import { SessionStorageKey } from 'src/app/shared/enums/session-storage-key.enum';
import { DISPLAY_DATE_FORMAT } from '@agentmax/shared/dependencies/display-date-format';

const STATUS_CONTACTED = 'Contacted';
const BADGE_POSITIVE = 'positive';
const BADGE_NEGATIVE = 'negative';

@Component({
  selector: 'app-quote-admin-page',
  templateUrl: './quote-admin-page.component.html',
  styleUrls: ['./quote-admin-page.component.scss'],
  providers: [{ provide: NxSortHeaderIntl, useClass: AgentmaxIntlService }],
})
export class QuoteAdminPageComponent implements OnInit, AfterViewInit {
  @ViewChild(NxExpansionPanelHeaderComponent)
  public readonly nxExpansionPanelHeader!: NxExpansionPanelHeaderComponent;
  searchOfferForm = this.nonNullableFormBuilder.group({
    quoteId: [''],
    customerName: [''],
    customerSurname: [''],
    tripStartDate: [''],
    tripEndDate: [''],
    contactEmail: [''],
    createdDate: [''],
    businessPartnerID: [''],
  });
  protected readonly DISPLAY_DATE_FORMAT = inject(DISPLAY_DATE_FORMAT);
  public quoteValidationMessages: string[] = [];
  public quoteDetailsList: QuoteItemList[] = [];
  public currentlyShownPageElements!: QuoteItemList[];
  public page = 1;
  public filterValue = '';
  elementsPerPage = 30;
  @Output() public readonly viewOfferEvent = new EventEmitter<string>();

  constructor(
    private readonly userService: UserService,
    private readonly dataAccessorService: DataAccessorService,
    private readonly nonNullableFormBuilder: NonNullableFormBuilder,
    private readonly sharedService: SharedService,
    private readonly httpWrapperService: HttpWrapperService,
    private readonly httpEndpointsService: HttpEndpointsService,
    private readonly cdRef: ChangeDetectorRef,
    private readonly dateService: DateService,
    private readonly dataRecordingService: DataRecordingService,
    private readonly sessionStorageService: SessionStorageService,
  ) {}

  ngOnInit(): void {
    const isExternalUser = this.userService.isExternalUser();
    if (isExternalUser) {
      this.searchOfferForm.controls['businessPartnerID'].setValue(
        this.dataAccessorService.getPartnerCode() || '',
      );
      this.searchOfferForm.controls['businessPartnerID'].disable();
      this.searchQuote();
    }
  }

  public ngAfterViewInit(): void {
    this.nxExpansionPanelHeader.toggle();
    this.cdRef.detectChanges();
  }

  public sortTable(sort: SortEvent) {
    this.quoteDetailsList = this.quoteDetailsList.sort(
      (a: { [key: string]: any }, b: { [key: string]: any }) => {
        if (sort.active in a && sort.active in b) {
          return this.sharedService.compare(a[sort.active], b[sort.active], sort.direction);
        }
        return 0;
      },
    );

    this.updatePage();
  }

  public prevPage(): void {
    this.page--;
    this.updatePage();
  }

  public nextPage(): void {
    this.page++;
    this.updatePage();
  }

  public goToPage(n: number): void {
    this.page = n;
    this.updatePage();
  }

  public searchQuote(): void {
    this.quoteValidationMessages = [];
    this.quoteDetailsList = [];
    if (Object.values(this.searchOfferForm.controls).some((item) => item.value !== '')) {
      const searchQuoteRequestData = this.generateSearchQuoteRequest();
      const searchQuoteUrl = this.httpEndpointsService.buildSearchQuoteUrl();
      this.httpWrapperService
        .post<SearchQuoteResponseData>(searchQuoteUrl, searchQuoteRequestData)
        .subscribe((data) => {
          if (data.savedQuoteList && data.savedQuoteList.length > 0) {
            this.quoteDetailsList = data.savedQuoteList;
            this.quoteDetailsList.forEach(function (value) {
              value.statusType =
                value.status === STATUS_CONTACTED ? BADGE_POSITIVE : BADGE_NEGATIVE;
            });
            this.updatePage();
          } else {
            this.quoteValidationMessages.push('quoteAdminPage.0.NOOFFERFOUND');
          }
        });
    } else {
      this.quoteValidationMessages.push('quoteAdminPage.0.ENTERREQ');
    }
  }

  public trackByFn(index: number, item: { quoteId: string }): string {
    return item.quoteId;
  }

  public viewOffer(quoteId: string): void {
    this.quoteValidationMessages = [];
    const retrieveOfferDetailsUrl = this.httpEndpointsService.buildOfferDetailsUrl(quoteId);
    this.httpWrapperService
      .get<QuoteOfferDetailsResponse>(retrieveOfferDetailsUrl)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.quoteValidationMessages.push('quoteAdminPage.0.OFFERNOTVALID');
          return of(null);
        }),
      )
      .subscribe((data) => {
        if (data && data.snrContract && data.saveQuoteDetail) {
          this.dataRecordingService.saveQuoteOfferDetails(data);
          if (
            this.sessionStorageService.getRawValueOrNull(SessionStorageKey.QUOTE_OFFER_COMMENT) !==
            null
          ) {
            this.dataAccessorService.removeQuoteOfferComment();
          }
          this.viewOfferEvent.emit('successViewOffer');
        }
      });
  }

  private updatePage(): void {
    const indexMin = (this.page - 1) * this.elementsPerPage;
    const indexMax = indexMin + this.elementsPerPage;
    this.currentlyShownPageElements = this.quoteDetailsList.filter(
      (x, index) => index >= indexMin && index < indexMax,
    );
  }

  private generateSnRDetails(): SnrDetails {
    const createdDate = this.searchOfferForm.controls['createdDate'].value;
    const tripStartDate = this.searchOfferForm.controls['tripStartDate'].value;
    const tripEndDate = this.searchOfferForm.controls['tripEndDate'].value;
    let selectedAgency = '';
    if (
      this.searchOfferForm.controls['businessPartnerID'].value &&
      this.searchOfferForm.controls['businessPartnerID'].value.replace(' ', '') != ''
    ) {
      selectedAgency = this.searchOfferForm.controls['businessPartnerID'].value;
    } else if (this.userService.isExternalUser()) {
      selectedAgency = this.dataAccessorService.getPartnerCode() || '';
    }
    return {
      progressDetails: {
        quoteId: this.searchOfferForm.controls['quoteId'].value,
        createdDate: createdDate
          ? this.dateService.getDateAsISOString(createdDate, 'DD.MM.YYYY')
          : '',
        tripStartDate: tripStartDate
          ? this.dateService.getDateAsISOString(tripStartDate, 'DD.MM.YYYY')
          : '',
        tripEndDate: tripEndDate
          ? this.dateService.getDateAsISOString(tripEndDate, 'DD.MM.YYYY')
          : '',
        source: 'AgentMax',
        type: 'Offer',
        contactFirstName: this.searchOfferForm.controls['customerName'].value,
        contactLastName: this.searchOfferForm.controls['customerSurname'].value,
        contactEmail: this.searchOfferForm.controls['contactEmail'].value,
      },
      agentMaxDetails: {
        selectedAgency: selectedAgency,
      },
    };
  }

  private generateSearchQuoteRequest(): SearchQuoteRequestData {
    return {
      snrDetails: this.generateSnRDetails(),
    };
  }
}
