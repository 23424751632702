import { CurrentAccountsDetailsState } from './authentication/current-accounts-details';
import { CurrentAgencyState } from './authentication/current-agency/current-agency.state';
import { CurrentUserState } from './authentication/current-user';
import { LanguagesState } from './languages';

export const STORE = [
  LanguagesState,
  CurrentUserState,
  CurrentAccountsDetailsState,
  CurrentAgencyState,
];
