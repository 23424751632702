import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpEndpointsService } from '../../../shared/services/http-endpoints/http-endpoints.service';
import { HttpWrapperService } from '../../../shared/services/http-wrapper/http-wrapper.service';

import { SearchPolicyDto } from '../../../shared/dtos/search-policy.dto';
import { SearchPolicyResponse } from '../../../shared/types/search-policy-response.type';

@Injectable({
  providedIn: 'root',
})
export class SearchPolicyHttpService {
  private readonly _httpEndpointsService = inject(HttpEndpointsService);
  private readonly _httpWrapperService = inject(HttpWrapperService);

  public searchPolicy(searchPolicyDto: SearchPolicyDto): Observable<SearchPolicyResponse> {
    return this._httpWrapperService.post<SearchPolicyResponse>(
      this._httpEndpointsService.buildSearchPolicyUrl(),
      searchPolicyDto,
    );
  }
}
