export class MockSessionStorage implements Storage {
  get length(): number {
    return Object.keys(this.storage).length;
  }

  private storage: Record<string, string> = {};

  public getItem(key: string): string | null {
    if (this.storage.hasOwnProperty(key)) {
      return this.storage[key];
    }

    return null;
  }

  public setItem(key: string, value: string): void {
    this.storage[key] = value;
  }

  public removeItem(key: string): void {
    delete this.storage[key];
  }

  public clear(): void {
    this.storage = {};
  }

  public key(_index: number): any {}
}
