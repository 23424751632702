import { Injectable } from '@angular/core';

import { ILanguagesInitializationService } from './languages-initialization.service.interface';
import { NewLanguageCode } from '@agentmax/shared/enums/language-code.enum';
import { LanguageOptionsName } from '@agentmax/shared/enums/language-options-name.enum';

@Injectable({
  providedIn: 'root',
})
export class DevPageUrlLanguagesInitializationService implements ILanguagesInitializationService {
  public getLanguageOptionsName(): LanguageOptionsName {
    return LanguageOptionsName.EN;
  }

  public getSelectedLanguageCode(): NewLanguageCode {
    return NewLanguageCode.ENGLISH;
  }
}
