import { Injectable } from '@angular/core';
import { NxDialogService } from '@aposin/ng-aquila/modal';

import { EnvironmentInfoDialogComponent } from '../../dialogs/environment-info-dialog/environment-info-dialog.component';

import { EnvironmentInfoDialogData } from '../../../shared/types/environment-info-dialog-data.type';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentInfoDialogService {
  constructor(private readonly _dialogService: NxDialogService) {}

  public openDialog(data: EnvironmentInfoDialogData): void {
    this._dialogService.open(EnvironmentInfoDialogComponent, {
      width: '850px',
      maxWidth: '850px',
      showCloseIcon: true,
      panelClass: 'environment-info-dialog-panel',
      data,
    });
  }
}
