export enum AppUrl {
  LOGIN = 'login',
  HOME = 'home',
  NEWS = 'news-resources',
  HELP = 'help',
  SETTING = 'setting',
  SEARCH_POLICY = 'policyAdmin',
  SEARCH_OFFER = 'quoteAdmin',
  REPORTS = 'reports',
}
