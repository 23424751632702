<form
  class="form-example"
  [formGroup]="saveOfferForm"
  (ngSubmit)="formSubmit()"
  *ngIf="!successOnSave"
  class="nx-margin-bottom-m"
>
  <div nxLayout="grid">
    <div
      nxRow
      class="nx-margin-bottom-m"
    >
      <div nxCol="12">
        <nx-label
          size="small"
          class="text-center"
          >{{ 'productspage.0.PROPOSALINFO' | translate }}</nx-label
        >
      </div>
    </div>
    <div nxRow>
      <div nxCol="12,6">
        <nx-formfield
          label="{{ 'productspage.0.PHNAME' | translate }}"
          appearance="outline"
          floatLabel="always"
        >
          <input
            nxInput
            formControlName="firstName"
          />
        </nx-formfield>
      </div>
      <div nxCol="12,6">
        <nx-formfield
          label="{{ 'productspage.0.PHLASTNAME' | translate }}"
          appearance="outline"
          floatLabel="always"
        >
          <input
            nxInput
            formControlName="lastName"
          />
        </nx-formfield>
      </div>
    </div>
    <div nxRow>
      <div nxCol="12">
        <nx-formfield
          label="{{ 'productspage.0.PHEMAIL' | translate }}"
          appearance="outline"
          floatLabel="always"
        >
          <input
            nxInput
            type="email"
            formControlName="toEmail"
          />
        </nx-formfield>
      </div>
    </div>
    <div nxRow>
      <div nxCol="12">
        <nx-label
          size="small"
          class="text-center"
          >{{ 'productspage.0.COPYEMAIL' | translate }}</nx-label
        >
      </div>
    </div>
    <div nxRow>
      <div nxCol="12">
        <nx-formfield
          label="{{ 'productspage.0.PHEMAIL' | translate }}"
          appearance="outline"
          floatLabel="always"
        >
          <input
            nxInput
            formControlName="ccEmail"
          />
        </nx-formfield>
      </div>
    </div>
    <div nxRow>
      <div nxCol="12">
        <nx-checkbox
          class="nx-margin-bottom-s"
          formControlName="tyc"
          required
        >
          {{ 'productspage.0.SAVEOFFERTYC' | translate }}
        </nx-checkbox>
      </div>
    </div>
    <div nxRow>
      <div nxCol="12">
        <nx-message
          context="error"
          *ngIf="errorOnSave || (!this.saveOfferForm.valid && saveOfferForm.touched)"
          class="nx-margin-bottom-xs"
        >
          <nx-label
            size="small"
            *ngIf="saveOfferForm.controls.toEmail.errors"
            >{{ 'travelerinfopage.0.EMAILVAL' | translate }}</nx-label
          >
          <nx-label
            size="small"
            *ngIf="saveOfferForm.controls.tyc.errors && saveOfferForm.touched"
            >{{ 'summarypage.0.TNC' | translate }}
            {{ 'productspage.0.AGREETNCERROR' | translate }}</nx-label
          >
          <nx-label
            *ngIf="errorOnSave"
            size="small"
            >{{ 'productspage.0.PQ_SAVEOFFER' | translate }}</nx-label
          >
        </nx-message>
      </div>
    </div>
    <div nxRow>
      <div
        nxCol="2"
        colOffset="10"
      >
        <button
          nxButton
          type="submit"
          [disabled]="saveOfferForm.invalid"
        >
          {{ 'productspage.0.SENDEMAILQUOTATION' | translate }}
        </button>
      </div>
    </div>
  </div>
</form>

<nx-message
  context="success"
  *ngIf="successOnSave"
>
  <nx-label size="small">{{ 'productspage.0.OFFERID' | translate }}</nx-label>
  <h3>{{ quoteId }}</h3>
  <br />
  <nx-label size="small">{{ 'productspage.0.OFFERSAVED1' | translate }}</nx-label>
  <nx-label size="small">{{ 'productspage.0.OFFERSAVED2' | translate }}</nx-label>
</nx-message>
