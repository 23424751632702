import { Injectable, Inject } from '@angular/core';
import { LOCATION } from '@agentmax/core/deprecated-global-dependencies/location.global-dependency';
import { isProdEnvironment } from '../../classes/environment-utils.class';

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  constructor(@Inject(LOCATION) private readonly location: Location) {}

  public buildMessage(location: string, message: string): string {
    if (isProdEnvironment(this.location.host)) {
      return `An unexpected error has occurred.`;
    }

    return `[${location}]: ${message}`;
  }
}
