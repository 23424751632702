import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, tap } from 'rxjs';
import { UserIdleService } from 'angular-user-idle';

import { HttpEndpointsService } from '../../../shared/services/http-endpoints/http-endpoints.service';
import { DataAccessorService } from '../../../shared/services/data-accessor/data-accessor.service';
import { NavigationService } from '../navigation/navigation.service';
import { SessionStorageService } from '../../../shared/services/session-storage/session-storage.service';
import { SuccessLogoutMessageService } from '../../../login/services/success-logout-message.service';
import { ErrorSwitchAccountMessageService } from 'src/app/login/services/error-switch-account-message.service';

import { LogoutResponseData } from '../../../shared/types/logout-response-data.type';
import { Languages } from '@agentmax/store/languages';

type LogoutParams = Partial<
  Readonly<{
    skipNavigationToLogin: boolean;
    showSuccessMessage: boolean;
    showErrorSwitchAccountMessage: boolean;
  }>
>;

const DEFAULT_LOGOUT_PARAMS: Required<LogoutParams> = {
  skipNavigationToLogin: false,
  showSuccessMessage: true,
  showErrorSwitchAccountMessage: false,
};

@Injectable({
  providedIn: 'root',
})
export class LogoutService {
  private readonly httpClient = inject(HttpClient);
  private readonly httpEndpointsService = inject(HttpEndpointsService);
  private readonly dataAccessorService = inject(DataAccessorService);
  private readonly sessionStorageService = inject(SessionStorageService);
  private readonly navigationService = inject(NavigationService);
  private readonly _successfulLogoutMessageService = inject(SuccessLogoutMessageService);
  private readonly _errorSwitchAccountMessageService = inject(ErrorSwitchAccountMessageService);
  private readonly _userIdleService = inject(UserIdleService);
  private readonly _store = inject(Store);

  public logout(): Observable<LogoutResponseData> {
    const logoutReq = {
      accessToken: this.dataAccessorService.getAccessToken(),
      refreshToken: this.dataAccessorService.getRefreshToken(),
    };
    return this.httpClient.post<LogoutResponseData>(
      this.httpEndpointsService.buildLogoutUrl(),
      logoutReq,
    );
  }

  public logoutAndCloseApp(
    params: LogoutParams = DEFAULT_LOGOUT_PARAMS,
  ): Observable<LogoutResponseData> {
    return this.logout().pipe(
      tap(() => this.closeApp(params)),
      // TODO: Error scenario?
    );
  }

  public closeApp(params: LogoutParams): void {
    if (params.showSuccessMessage) {
      this._successfulLogoutMessageService.show();
    }
    if (params.showErrorSwitchAccountMessage) {
      this._errorSwitchAccountMessageService.show();
    }

    this._store.dispatch(new Languages.Reset());
    this.sessionStorageService.clearStorage();
    this._userIdleService.stopWatching();

    if (!params.skipNavigationToLogin) {
      this.navigationService.navigateToLogin();
    }
  }
}
