import { Injectable, InjectionToken, inject } from '@angular/core';
import { Moment } from 'moment';

import { UserService } from '../../../shared/services/user/user.service';
import { DataAccessorService } from '../../../shared/services/data-accessor/data-accessor.service';

import { SearchPolicyFormValue } from '../../../shared/types/search-policy-form-value.type';
import { SearchPolicyDto } from '../../../shared/dtos/search-policy.dto';

const SEARCH_POLICY_DATE_FORMAT = new InjectionToken('SEARCH_POLICY_DATE_FORMAT', {
  providedIn: 'root',
  factory: () => 'YYYY-MM-DD',
});

@Injectable({
  providedIn: 'root',
})
export class SearchPolicyMapperService {
  private readonly _userService = inject(UserService);
  private readonly _dataAccessorService = inject(DataAccessorService);
  private readonly _searchPolicyDateFormat = inject(SEARCH_POLICY_DATE_FORMAT);

  public fromFormValueToDto(searchPolicyFormValue: SearchPolicyFormValue): SearchPolicyDto {
    const customerNumberOrNull = this._dataAccessorService.getCustomerNumberOrNull();
    const customerNumber =
      !this._userService.isInternalUser() && customerNumberOrNull !== null
        ? customerNumberOrNull
        : '';

    return {
      contractNumber: '', // always an empty string
      policyHolderFirstName: searchPolicyFormValue.beneficiaryFirstName,
      policyHolderLastName: searchPolicyFormValue.beneficiaryLastName,
      policyType: searchPolicyFormValue.policyType,
      status: searchPolicyFormValue.policyStatus,
      productName: searchPolicyFormValue.productName,
      startDateFrom: this.formatDate(searchPolicyFormValue.policyStartDateFrom),
      startDateUntil: this.formatDate(searchPolicyFormValue.policyStartDateTo),
      partnerName: searchPolicyFormValue.partnerName,
      partnerCode: searchPolicyFormValue.partnerNumber,
      purchaseDateFrom: this.formatDate(searchPolicyFormValue.purchaseDateFrom),
      purchaseDateUntil: this.formatDate(searchPolicyFormValue.purchaseDateTo),
      externalContractNumber: searchPolicyFormValue.policyNumber,
      customerNumber,
      externalAgent: '', // always an empty string
      buUniqueId: this._dataAccessorService.getBusinessUnits().buUniqueId,
      countryCode: this._dataAccessorService.getUserCountryCode(),
    };
  }

  private formatDate(date: Moment | null): string {
    if (date === null) {
      return '';
    }

    return date.format(this._searchPolicyDateFormat);
  }
}
