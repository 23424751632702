import { LogosMapType } from '../../shared/types/logos-map.type';

export const LOGOS_MAP: LogosMapType = {
  allianzPartnersLogo: {
    src: 'assets/logos/logo-allianz-partners.png',
    alt: 'Allianz Partners',
  },
  mondialAssistanceLogo: {
    src: 'assets/logos/Mondial-Assistance.png',
    alt: 'Allianz - Worldwide Partners',
  },
  globalAssistanceLogo: {
    src: 'assets/logos/logo-allianz-it.png',
    alt: 'Allianz - Worldwide Partners',
  },
  allianzTravelLogo: {
    src: 'assets/logos/logo-allianz.png',
    alt: 'Allianz - Worldwide Partners',
  },
  agentmaxLogo: {
    src: 'assets/logos/agentmax-logo.png',
    alt: 'Allianz - Worldwide Partners',
  },
};
