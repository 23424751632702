import { ConfigurationService } from '@agentmax/core/services/configuration/configuration.service';
import { Pipe } from '@angular/core';

@Pipe({
  name: 'currencyFormat',
  standalone: true,
})
export class CurrencyFormatPipe {
  constructor(private readonly configurationService: ConfigurationService) {}

  transform(
    value: number,
    currencySignBackUp: string,
    decimalLength: number = 2,
    chunkDelimiter: string = '.',
    decimalDelimiter: string = ',',
    chunkLength: number = 3,
  ): string {
    const currencySign = this.configurationService.getConfigCurrencyOrDefault(currencySignBackUp);
    const currencyFormat = this.configurationService.getConfigCurrencyFormat();

    const numberFormat =
      '\\d(?=(\\d{' + chunkLength + '})+' + (decimalLength > 0 ? '\\D' : '$') + ')';
    const decimalNum = value.toFixed(Math.max(0, ~~decimalLength));
    const formattedNum = (
      decimalDelimiter ? decimalNum.replace('.', decimalDelimiter) : decimalNum
    ).replace(new RegExp(numberFormat, 'g'), '$&' + chunkDelimiter);

    const result = currencyFormat
      .replace('#amount', formattedNum)
      .replace('#currency', currencySign);

    return result;
  }
}
