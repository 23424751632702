import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NxDialogService, NxModalRef } from '@aposin/ng-aquila/modal';
import { PasswordExpiresSoonDialogComponent } from '../../dialogs/password-expires-soon-dialog/password-expires-soon-dialog.component';

const PASSWORD_EXPIRES_DAYS_LEFT_KEY = 'tripdetailpage.0.YOURPASSWORDPOPUP';

@Injectable({
  providedIn: 'root',
})
export class PasswordExpiresSoonService {
  constructor(
    private readonly translateService: TranslateService,
    private readonly dialogService: NxDialogService,
  ) {}

  public getPasswordExpireDaysLeftText(daysLeft: string): string {
    return this.translateService.instant(PASSWORD_EXPIRES_DAYS_LEFT_KEY).replace('%x%', daysLeft);
  }

  public openPasswordExpiresSoonDialog(
    daysLeft: string,
  ): NxModalRef<PasswordExpiresSoonDialogComponent> {
    return this.dialogService.open(PasswordExpiresSoonDialogComponent, {
      ariaLabel: 'Password expire days left dialog',
      showCloseIcon: false,
      disableClose: true,
      data: daysLeft,
    });
  }
}
