import { Injectable, SecurityContext } from '@angular/core';
import { AppFeatureTogglesService } from '../app-feature-toggles/app-feature-toggles.service';
import { DataAccessorService } from '../../../shared/services/data-accessor/data-accessor.service';
import { FeatureToggleKey } from 'src/app/shared/enums/feature-toggle-key.enum';
import { HttpWrapperService } from '../../../shared/services/http-wrapper/http-wrapper.service';
import { HttpEndpointsService } from '../../../shared/services/http-endpoints/http-endpoints.service';
import { Observable } from 'rxjs';
import { SendFeedbackResponseData } from 'src/app/shared/types/send-feedback-response-data.type';
import { DomSanitizer } from '@angular/platform-browser';
import { SendFeedbackRequestData } from 'src/app/shared/types/send-feedback-request-data.type';
import { ErrorService } from '@agentmax/shared/services/error/error.service';

@Injectable({
  providedIn: 'root',
})
export class ContactUsService {
  constructor(
    private readonly appFeatureTogglesService: AppFeatureTogglesService,
    private readonly dataAccessorService: DataAccessorService,
    private readonly httpWrapperService: HttpWrapperService,
    private readonly httpEndpointsService: HttpEndpointsService,
    private readonly sanitizer: DomSanitizer,
    private readonly errorService: ErrorService,
  ) {}

  public sendFeedback(message: string): Observable<SendFeedbackResponseData> {
    const sanitizedMessage = this.sanitizer.sanitize(SecurityContext.HTML, message);
    if (sanitizedMessage === null) {
      this.errorService.throwError(this.constructor.name, `Message may contain malicious content!`);
    }

    const sendFeedbackReq: SendFeedbackRequestData = {
      body: sanitizedMessage,
    };
    if (
      !this.appFeatureTogglesService.getFeatureToggle(FeatureToggleKey.WHITELISTED_EMAIL_SENDER)
    ) {
      const accountInformation = this.dataAccessorService.getCurrentUsersInformation();
      sendFeedbackReq.from = {
        name: `${accountInformation.user} ${accountInformation.lastName}`,
        emailAddress: accountInformation.email,
      };
    }

    const sendFeedbackUrl = this.httpEndpointsService.buildSendFeedbackUrl();
    return this.httpWrapperService.post<SendFeedbackResponseData>(sendFeedbackUrl, sendFeedbackReq);
  }
}
