import { InjectionToken } from "@angular/core";

import { UserPermission } from "../enums/user-permission.enum";

export const ACCESS_ROLE_MATRIX = new InjectionToken<Record<UserPermission, ReadonlyArray<string>>>('ACCESS_ROLE_MATRIX', {
    providedIn: 'root',
    factory: () => ({
        [UserPermission.HOME]: ["1", "2", "5", "6", "8"],
        [UserPermission.HEAD_LINK]: ["1", "2", "5", "6", "8"],
        [UserPermission.USER_LINK]: ["4", "5", "6"],
        [UserPermission.HEADREPORT_LINK]: ["2", "4", "5", "6"],
        [UserPermission.INTERNAL_USERS]: ["4", "5", "6"],
        [UserPermission.SUPER_USER]: ["6"],
        [UserPermission.EXTERNAL_USERS]: ["1", "2", "8"],
        [UserPermission.CREATE_NEW_ACCOUNT]: ["2", "5", "6"],
        [UserPermission.SHOW_AGENCYID]: ["4", "5"],
        [UserPermission.ACCESS_MANAGEMENT_TAB]: ["2", "5"],
        [UserPermission.DEFAULT_AGENCYID]: ["2"],
        [UserPermission.CREATE_ADMIN_ACCOUNT]: ["4", "5", "6", "2"],
        [UserPermission.EXCEPT_INTERNAL_USER]: ["1", "2", "8"],
        [UserPermission.STANDARD_USER]: ["1", "8"],
        [UserPermission.STANDARD_USER_WITH_ADMIN_RIGHTS]: ["2"],
        [UserPermission.REPORT_LINK]: ["2"],
        [UserPermission.INTERNAL_USER_WITH_ADMIN_RIGHTS]: ["5"],
        [UserPermission.INTERNAL_USER_WITH_ADMIN_RIGHTS_SUPER_USER]: ["5", "6"],
        [UserPermission.EXTERNAL_CLAIM_ONLY_USER]: ["7"],
        [UserPermission.POLICY_MODIFY_USER]: ["1", "2", "4", "5", "6", "8"],
        [UserPermission.MANAGE_CLAIN_ONLY]: ["1", "2", "5", "4", "7", "8"]
    })
})