import { inject, Injectable } from '@angular/core';
import { DataAccessorService } from '../data-accessor/data-accessor.service';
import { TypeAssertionService } from '../type-assertion/type-assertion.service';
import { UserInformation } from 'src/app/shared/types/user-information.type';
import { ErrorService } from '@agentmax/shared/services/error/error.service';
import { UserServiceInterface } from './user-service.interface';
import { ACCESS_ROLE_MATRIX } from '@agentmax/shared/dependencies/access-role-matrix';

@Injectable({
  providedIn: 'root',
})
export class UserService implements UserServiceInterface {
  constructor(
    private readonly dataAccessorService: DataAccessorService,
    private readonly typeAssertionService: TypeAssertionService,
    private readonly errorService: ErrorService,
  ) {}

  private readonly _accessRoleMatrix = inject(ACCESS_ROLE_MATRIX);

  public isInternalUser(): boolean {
    return this.userHasPermissionForComponent('cIdInternalUsers');
  }

  public isInternalAdmin(): boolean {
    return this.userHasPermissionForComponent('cIdInternalUserWithAdminRights');
  }

  public isExternalUser(): boolean {
    return this.userHasPermissionForComponent('cIdExternalUsers');
  }

  public isExternalAdmin(): boolean {
    return this.userHasPermissionForComponent('cIdStandarUserWithAdminRights');
  }

  public isSuperUser(): boolean {
    return this.userHasPermissionForComponent('cIdSuperUser');
  }

  public hasDefaultAgencyIdPermissions(): boolean {
    return this.userHasPermissionForComponent('cIdDefaultAgencyId');
  }

  public isLoggedIn(): boolean {
    return !!this.dataAccessorService.getCurrentUser();
  }

  public getCurrentUsersInfo(): UserInformation {
    return this.dataAccessorService.getCurrentUsersInformation();
  }

  public getPermissions(): string {
    const userPermissions = this.dataAccessorService.getUserPermission();
    if (userPermissions !== undefined) {
      return userPermissions;
    }

    this.errorService.throwError(
      this.constructor.name,
      'Current user information should be set at this point',
    );
  }

  public userHasPermissionForComponent(componentId: string): boolean {
    if (!this.isLoggedIn()) {
      return false;
    }

    let found = false;
    const user_permissions = this.getPermissions();

    if (this.typeAssertionService.doesObjectHaveKey(this._accessRoleMatrix, componentId)) {
      this._accessRoleMatrix[componentId].some((element: string) => {
        if (user_permissions.includes(element)) {
          found = true;
        }
      });
    }
    return found;
  }
}
