import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AppConfigurationService } from '../../services/app-configuration/app-configuration.service';

@Component({
  selector: 'app-inner-page-layout',
  templateUrl: './inner-page-layout.component.html',
  styleUrls: ['./inner-page-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InnerPageLayoutComponent {
  protected readonly _isDevEnvironment = this._appConfigurationService.isDevEnvironment();

  constructor(private readonly _appConfigurationService: AppConfigurationService) {}
}
