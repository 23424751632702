import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, catchError, Observable, tap } from 'rxjs';

import { HttpEndpointsService } from '../../../shared/services/http-endpoints/http-endpoints.service';
import { DataRecordingService } from '../../../shared/services/data-recording/data-recording.service';
import { DataAccessorService } from '../../../shared/services/data-accessor/data-accessor.service';
import { ErrorService } from '@agentmax/shared/services/error/error.service';

import { AppConfiguration } from '@agentmax/shared/types/app-configuration.type';
import { TO_AUTHORIZE } from '@agentmax/core/http/contexts/to-authorize';

const DEV_ENVIRONMENTS_NAMES = ['LOCAL', 'DEV', 'INT', 'UAT'];

@Injectable({
  providedIn: 'root',
})
export class AppConfigurationService {
  private readonly _configuration$ = new BehaviorSubject<AppConfiguration | null>(null);

  public readonly configuration$ = this._configuration$.asObservable();

  constructor(
    private readonly httpClient: HttpClient,
    private readonly httpEndpointsService: HttpEndpointsService,
    private readonly dataRecordingService: DataRecordingService,
    private readonly dataAccessorService: DataAccessorService,
    private readonly errorService: ErrorService,
  ) {}

  public isDevEnvironment(): boolean {
    const rawEnvironmentName = this.dataAccessorService.getSpringProfileOrNull();

    if (rawEnvironmentName === null) {
      return false;
    }

    const uppercaseEnvironmentName = rawEnvironmentName.toUpperCase();

    if (uppercaseEnvironmentName.includes('PROD') || uppercaseEnvironmentName.includes('PREPROD')) {
      return false;
    }

    return DEV_ENVIRONMENTS_NAMES.some((devEnvName) =>
      uppercaseEnvironmentName.includes(devEnvName),
    );
  }

  public getAppConfiguration(): Observable<AppConfiguration | null> {
    return this.httpClient
      .get<AppConfiguration>(this.httpEndpointsService.buildAppConfigurationUrl(), {
        context: new HttpContext().set(TO_AUTHORIZE, true),
      })
      .pipe(
        tap((configuration) => this._configuration$.next(configuration)),
        catchError((error: HttpErrorResponse) => {
          this._configuration$.next(null);
          this.errorService.throwError(
            this.constructor.name,
            `Error on getting App Configuration: ${error}`,
          );
        }),
      );
  }

  public saveAppConfiguration(appConfiguration: AppConfiguration): void {
    this.dataRecordingService.saveAppConfiguration(appConfiguration.uiConfig);
    this.dataRecordingService.saveAppEnvironment(appConfiguration.springProfile);
  }

  public setUp(): void {
    let configurationValue = null;
    if (
      this.dataAccessorService.getSpringProfileOrNull() &&
      this.dataAccessorService.getConfigFileOrNull()
    ) {
      configurationValue = {
        springProfile: this.dataAccessorService.getSpringProfile(),
        uiConfig: this.dataAccessorService.getConfigFile(),
      };
    }
    this._configuration$.next(configurationValue);
  }
}
