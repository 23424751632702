<div class="bu-configuration">
  <ng-container *ngIf="_buConfigBySections !== null">
    <div
      class="section"
      *ngFor="let section of _buConfigBySections"
    >
      <h4
        class="section__title"
        nxHeadline="subsection-xsmall"
      >
        {{ section.title }}
      </h4>

      <div class="section__data">{{ section.data | json }}</div>
    </div>
  </ng-container>

  <div *ngIf="_buConfigBySections === null">
    <h3>BU Config is empty.</h3>
  </div>
</div>
