import { Injectable } from '@angular/core';

import { LoginResponseData } from '../../../shared/types/login-response-data.type';
import { UserInformation } from '../../../shared/types/user-information.type';

@Injectable({
  providedIn: 'root',
})
export class UserInfoMapperService {
  public fromLoginDataToUserInformation(loginData: LoginResponseData): UserInformation {
    return {
      clusterId: loginData.clusterId,
      user: loginData.userName,
      email: loginData.email,
      userName: loginData.userName,
      lastName: loginData.lastName,
      agencyName: loginData.agencyName,
      zipcode: loginData.zipcode,
      city: loginData.city,
      addressline1: loginData.addressline1,
      addressline2: loginData.addressline2,
      questions: loginData.questions,
    };
  }
}
