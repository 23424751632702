import { InjectionToken } from '@angular/core';

import { environment } from '../../environments/environment';

import { EnvironmentConfig } from '../shared/types/environment-config.type';

export const ENVIRONMENT = new InjectionToken<EnvironmentConfig>('ENVIRONMENT', {
  providedIn: 'root',
  factory: () => environment,
});
