import { InjectionToken, ValueProvider } from '@angular/core';
import { WINDOW as NEW_WINDOW } from '@agentmax/global/window';

/**
 * @deprecated Use {@link NEW_WINDOW} instead.
 */
export const WINDOW = new InjectionToken<Window>('WINDOW');

/**
 * @deprecated Use {@link NEW_WINDOW} instead.
 */
export const WINDOW_PROVIDER: ValueProvider = {
  provide: WINDOW,
  useValue: window,
};

/**
 * @deprecated Use {@link NEW_WINDOW} instead.
 */
export const MOCK_WINDOW_PROVIDER: ValueProvider = {
  provide: WINDOW,
  useValue: window,
};
