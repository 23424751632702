<div class="login-page-layout">
  <header
    class="header"
    nx-header
  >
    <nx-header-row class="header-row">
      <nx-header-actions>
        <app-logo></app-logo>
      </nx-header-actions>
    </nx-header-row>

    <nx-header-row class="header-row">
      <nx-header-actions>
        <app-select-language></app-select-language>
      </nx-header-actions>
    </nx-header-row>
  </header>

  <main class="main">
    <div class="container-fluid">
      <div class="boxes">
        <div class="box-form">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </main>
</div>
