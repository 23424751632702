import { ClassProvider, InjectionToken, ValueProvider } from '@angular/core';

import { MockSessionStorage } from '../../testing/mocks/session-storage.mock';

/**
 * @deprecated
 */
export const SESSION_STORAGE = new InjectionToken<Storage>('SESSION_STORAGE');

/**
 * @deprecated
 */
export const SESSION_STORAGE_PROVIDER: ValueProvider = {
  provide: SESSION_STORAGE,
  useValue: sessionStorage,
};

/**
 * @deprecated
 */
export const MOCK_SESSION_STORAGE_PROVIDER: ClassProvider = {
  provide: SESSION_STORAGE,
  useClass: MockSessionStorage,
};
