import { Injectable } from '@angular/core';
import { ErrorService } from '@agentmax/shared/services/error/error.service';

/**
 * @deprecated We can use NDBX util functions or shared/assertion functions instead
 */
@Injectable({
  providedIn: 'root',
})
export class TypeAssertionService {
  constructor(private readonly errorService: ErrorService) {}

  public doesObjectHaveKey<T extends Record<string, unknown>>(
    obj: T,
    key: unknown,
  ): key is keyof T {
    // We have to make this trick with types.
    // Otherwise typescript will complain about types incompatibility.
    if (typeof key !== 'string') {
      this.errorService.throwError(this.constructor.name, 'Only strings are allowed!');
    }

    return Object.keys(obj).includes(key);
  }
}
