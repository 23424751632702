import { EnvironmentConfig } from '@agentmax/shared/types/environment-config.type';
import { UiEnvironmentName } from '@agentmax/shared/enums/ui-environment-name.enum';

export const environment: EnvironmentConfig = {
  name: UiEnvironmentName.INT2,
  isProduction: false,
  standalone: true,
  BASE_URL: 'https://oneportal2.int.aws.emagin.eu/bookingCore2/',
  baseUrlMarketingMaterial: 'https://materialmanagement-test.allianz-assistance.it/Account/Login?',
  language: {
    all: {
      en: 'UK- English',
      at: 'AT- Deutsch',
      de: 'DE- Deutsch',
      ro: 'RO- Română',
      hu: 'HU- Magya',
      es: 'ES- Español',
      it: 'IT- Italy',
      fr: 'FR- France',
      cz: 'CZ - čeština',
    },
    at: {
      en: 'UK- English',
      at: 'AT- Deutsch',
    },
    bg: {
      en: 'UK- English',
      bg: 'BG - Bulgaria',
    },
    en: {
      en: 'UK- English',
    },
    gb: {
      en: 'UK- English',
    },
    de: {
      en: 'UK- English',
      de: 'DE- Deutsch',
    },
    hu: {
      hu: 'HU- Magya',
    },
    ro: {
      en: 'UK- English',
      ro: 'RO- Română',
    },
    es: {
      en: 'UK- English',
      es: 'ES- Español',
    },
    it: {
      en: 'UK- English',
      it: 'IT- Italiano',
    },
    fr: {
      en: 'UK- English',
      fr: 'FR- France',
    },
    ch: {
      'ch-en': 'CH- English',
      'ch-it': 'CH- Italiano',
      'ch-de': 'CH- Deutsch',
      'ch-fr': 'CH- Français',
    },
    uk: {
      en: 'UK- English',
    },
    cz: {
      en: 'UK- English',
      cz: 'CZ - čeština',
    },
  },
};
