import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, combineLatest, map, startWith } from 'rxjs';

import { DataAccessorService } from '../../../shared/services/data-accessor/data-accessor.service';
import { AppFeatureTogglesService } from '../../services/app-feature-toggles/app-feature-toggles.service';
import { DataRecordingService } from '../../../shared/services/data-recording/data-recording.service';

import { FeatureToggleList } from '../../../shared/types/feature-toggle.type';
import { FeatureToggleStateChangeEvent } from '../../../shared/types/feature-toggle-state-change-event.type';

@Component({
  selector: 'app-feature-toggles',
  templateUrl: './feature-toggles.component.html',
  styleUrls: ['./feature-toggles.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeatureTogglesComponent implements OnInit {
  private readonly _featureToggleList$ = new BehaviorSubject<FeatureToggleList>([]);

  protected readonly _searchField = new FormControl('');
  protected readonly _filteredFeatureToggleList$ = combineLatest([
    this._featureToggleList$.asObservable(),
    this._searchField.valueChanges.pipe(startWith('')),
  ]).pipe(
    map(([featureToggleList, searchValue]) => {
      if (searchValue === null || searchValue === '') {
        return featureToggleList;
      }

      const formattedSearchValue = searchValue.trim().toLocaleLowerCase();
      return featureToggleList.filter(({ name }) =>
        name.toLowerCase().includes(formattedSearchValue),
      );
    }),
  );

  constructor(
    private readonly _dataAccessorService: DataAccessorService,
    private readonly _appFeatureTogglesService: AppFeatureTogglesService,
    private readonly _dataRecordingService: DataRecordingService,
  ) {}

  public ngOnInit(): void {
    this.initFeatureToggleList();
  }

  protected handleResetButtonClick(): void {
    this._appFeatureTogglesService.resetAppFeatureToggles();
    this.initFeatureToggleList();
  }

  protected handleFeatureToggleStateChange({ key, value }: FeatureToggleStateChangeEvent): void {
    this._dataRecordingService.saveFeatureToggles({
      ...this._dataAccessorService.getFeatureToggles(),
      [key]: value,
    });
    this.initFeatureToggleList();
  }

  private initFeatureToggleList(): void {
    const originalFeatureToggles = this._dataAccessorService.getOriginalFeatureToggles();
    this._featureToggleList$.next(
      Object.entries(this._dataAccessorService.getFeatureToggles()).map(([key, value]) => {
        return {
          value,
          key,
          name: key.replace('.enabled', ''),
          isLocal: originalFeatureToggles[key] === undefined,
        };
      }),
    );
  }
}
