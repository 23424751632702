import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, catchError, Observable, of, tap } from 'rxjs';

import { HttpEndpointsService } from '../../../shared/services/http-endpoints/http-endpoints.service';
import { DataRecordingService } from '../../../shared/services/data-recording/data-recording.service';
import { DataAccessorService } from '../../../shared/services/data-accessor/data-accessor.service';
import { TypeAssertionService } from '../../../shared/services/type-assertion/type-assertion.service';
import { ErrorService } from '@agentmax/shared/services/error/error.service';

import { AppFeatureTogglesServiceInterface } from '@agentmax/core/services/app-feature-toggles/app-feature-toggles-service.interface';
import { AppFeatureToggles } from '@agentmax/shared/types/app-feature-toggles.type';
import { FeatureToggleKey } from '@agentmax/shared/enums/feature-toggle-key.enum';
import { TO_AUTHORIZE } from '@agentmax/core/http/contexts/to-authorize';

@Injectable({
  providedIn: 'root',
})
export class AppFeatureTogglesService implements AppFeatureTogglesServiceInterface {
  private readonly _featureToggles$ = new BehaviorSubject<AppFeatureToggles | null>(null);

  public readonly featureToggles$ = this._featureToggles$.asObservable();

  constructor(
    private readonly httpClient: HttpClient,
    private readonly httpEndpointsService: HttpEndpointsService,
    private readonly dataRecordingService: DataRecordingService,
    private readonly dataAccessorService: DataAccessorService,
    private readonly typeAssertionService: TypeAssertionService,
    private readonly errorService: ErrorService,
  ) {}

  public getAppFeatureToggles(): Observable<AppFeatureToggles | null> {
    return this.httpClient
      .get<AppFeatureToggles>(this.httpEndpointsService.buildAppFeatureTogglesUrl(), {
        context: new HttpContext().set(TO_AUTHORIZE, true),
      })
      .pipe(
        tap((featureToggles) => this._featureToggles$.next(featureToggles)),
        catchError((error: HttpErrorResponse) => {
          this._featureToggles$.next(null);
          console.error(error);
          return of(null);
        }),
      );
  }

  public getFeatureToggle(featureToggleKey: FeatureToggleKey): boolean {
    const featureToggles = this.dataAccessorService.getFeatureToggles();
    if (this.typeAssertionService.doesObjectHaveKey(featureToggles, featureToggleKey)) {
      return featureToggles[featureToggleKey];
    }

    this.errorService.throwError(
      this.constructor.name,
      `Feature Toggle does not exist ${featureToggleKey}`,
    );
  }

  public saveAppFeatureToggles(appFeatureToggles: AppFeatureToggles): void {
    this.dataRecordingService.saveFeatureToggles(
      this.extendWithLocalFeatureToggles(appFeatureToggles),
    );
    this.dataRecordingService.saveOriginalFeatureToggles(appFeatureToggles);
  }

  public resetAppFeatureToggles(): void {
    const originalFeatureToggles = this.dataAccessorService.getOriginalFeatureToggles();
    this.dataRecordingService.saveFeatureToggles(
      this.extendWithLocalFeatureToggles(originalFeatureToggles),
    );
  }

  private extendWithLocalFeatureToggles(appFeatureToggles: AppFeatureToggles): AppFeatureToggles {
    const localFeatureTogglesKeys = Object.values(FeatureToggleKey);
    const localFeatureToggles: Record<string, boolean> = {};

    for (const key of localFeatureTogglesKeys) {
      if (!this.isLocalFeatureToggle(appFeatureToggles, key)) {
        continue;
      }

      localFeatureToggles[key] = false;
    }

    return { ...appFeatureToggles, ...localFeatureToggles };
  }

  private isLocalFeatureToggle(
    appFeatureToggles: AppFeatureToggles,
    featureToggleKey: string,
  ): boolean {
    return appFeatureToggles[featureToggleKey] === undefined;
  }
}
