import { Injectable } from '@angular/core';

import { LoggerService } from '../logger/logger.service';

import { ErrorServiceInterface } from './error-service.interface';

/**
 * @deprecated This service should not be used any more. Just throw errors in the normal way instead.
 */
@Injectable({
  providedIn: 'root',
})
export class ErrorService implements ErrorServiceInterface {
  constructor(private readonly loggerService: LoggerService) {}

  public throwError(location: string, message: string): never {
    const error = this.loggerService.buildMessage(location, message);
    throw new Error(error);
  }
}
