import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { NonNullableFormBuilder, Validators } from '@angular/forms';
import { catchError, takeUntil, tap } from 'rxjs';

import { ResetPasswordMapperService } from '../../../core/mappers/reset-password/reset-password-mapper.service';
import { ResetPasswordHttpService } from '../../../core/http/reset-password/reset-password-http.service';

import { DestroyEmitter } from '../../../shared/classes/destroy-emitter.class';
import { CustomValidators } from '../../../shared/validators/custom-validators.class';
import { HttpErrorResponse } from '@angular/common/http';
import { isObject } from '@agentmax/shared/type-assertions/is-object/is-object.type-assertion';
import { isString } from '@agentmax/shared/type-assertions/is-string/is-string.type-assertion';
import { ErrorService } from '@agentmax/shared/services/error/error.service';
import { BaseResponseData } from '@agentmax/shared/types/base-response-data.type';

@Component({
  selector: 'app-reset-password-force-form',
  templateUrl: './reset-password-force-form.component.html',
  styleUrls: ['./reset-password-force-form.component.scss'],
})
export class ResetPasswordForceFormComponent extends DestroyEmitter {
  @Input()
  public set userEmail(value: string) {
    this._userEmail = value;
  }
  @Output()
  public readonly resolve = new EventEmitter<BaseResponseData>();
  protected _userEmail = '';
  protected _onePortalMessageCode: string | null = null;
  protected readonly _resetPasswordForceForm = this._formBuilder.group(
    {
      oldPassword: ['', [Validators.required]],
      newPassword: ['', [Validators.required, CustomValidators.passwordFormat]],
      confirmPassword: ['', [Validators.required]],
    },
    {
      validators: [
        CustomValidators.controlsMatch('newPassword', 'confirmPassword'),
        CustomValidators.controlsDoNotMatch('oldPassword', 'newPassword'),
      ],
    },
  );
  constructor(
    private readonly _formBuilder: NonNullableFormBuilder,
    private readonly _resetPasswordMapperService: ResetPasswordMapperService,
    private readonly _resetPasswordHttpService: ResetPasswordHttpService,
    private readonly _cdRef: ChangeDetectorRef,
    private readonly errorService: ErrorService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.subToResetPasswordForceFormValueChanges();
  }

  protected handleResetPasswordForceFormSubmit(): void {
    if (this._resetPasswordForceForm.invalid) {
      return;
    }

    const resetPasswordForceDto = this._resetPasswordMapperService.fromFormValueToDto(
      this._resetPasswordForceForm.getRawValue(),
      this._userEmail,
    );
    this._resetPasswordHttpService
      .resetPasswordForce(resetPasswordForceDto)
      .pipe(
        tap((data) => this.resolve.emit(data)),
        catchError((httpErrorResponse: HttpErrorResponse) => {
          const { error } = httpErrorResponse;

          this._onePortalMessageCode = error['onePortalMessageCode'];
          this._cdRef.markForCheck();
          if (
            isObject(error) &&
            isString(error['onePortalMessageCode']) &&
            error['onePortalMessageCode'] !== ''
          ) {
            this.errorService.throwError(this.constructor.name, error['onePortalMessageCode']);
          }

          this.errorService.throwError(this.constructor.name, 'Unknown error');
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }
  private subToResetPasswordForceFormValueChanges(): void {
    this._resetPasswordForceForm.valueChanges
      .pipe(
        tap(() => {
          if (this._onePortalMessageCode !== null) {
            this._onePortalMessageCode = null;
          }
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }
}
