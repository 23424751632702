import { InjectionToken } from '@angular/core';

/**
 * @description Do not use this format with moment dates!
 * Formats reference https://angular.io/api/common/DatePipe#custom-format-options
 */
export const DISPLAY_DATE_FORMAT = new InjectionToken<string>('DISPLAY_DATE_FORMAT', {
  providedIn: 'root',
  factory: () => 'dd/MM/yyyy',
});
