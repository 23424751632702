export enum SessionStorageKey {
  BRAND_FOR_NEWS_AND_PRODUCTS = 'ngStorage-brandForNews',
  PARTNER_CODE = 'ngStorage-partnerCode',
  ACCESS_TOKEN = 'ngStorage-accessToken',
  REFRESH_TOKEN = 'ngStorage-refreshToken',
  EMAIL_ID = 'ngStorage-emailId',
  CURRENT_USER = 'ngStorage-currentUser',
  NEW_CURRENT_USER = 'currentUser',
  CURRENT_AGENCY = 'currentAgency',
  CURRENT_ACCOUNTS_DETAILS = 'currentAccountsDetails',
  CURRENT_BUSINESS_UNIT = 'currentBusinessUnit',
  USER_PERMISSIONS = 'userPermissions',
  USER_COUNTRY_CODE = 'ngStorage-userCountryCode',
  BUSINESS_UNITS = 'ngStorage-businessUnits',
  BOOKING_APP = 'ngStorage-BookingApp',
  UI_CONFIG = 'ngStorage-UIConfig',
  ENVIRONMENT_CONFIG = 'ngStorage-environmentData',
  ENVIRONMENT = 'environment',
  SELECTED_AGENCY = 'ngStorage-agency',
  LANGUAGE_OPTIONS_NAME = 'languageOptionsName',
  SELECTED_LANGUAGE_CODE = 'selectedLanguageCode',
  /**
   * @deprecated Use {@link SELECTED_LANGUAGE_CODE} instead.
   */
  SELECTED_LANGUAGE = 'selectedLanguage',
  CURRENCY = 'ngStorage-currency',
  PRODUCT_SAVE_OFFER = 'ngStorage-productForSaveOffer',
  QUOTATION_SAVE_OFFER = 'ngStorage-quotationForSaveOffer',
  SELECTED_OFFER_DETAILS = 'selectedOfferDetails',
  FEATURE_TOGGLES = 'featureToggles',
  ORIGINAL_FEATURE_TOGGLES = 'originalFeatureToggles',
  ACCOUNT_DETAILS = 'accountDetails',
  MY_ACCOUNT_INFO = 'myAccountInfo',
  CLUSTER_ID = 'clusterId',
  USER_INFORMATION = 'userInformation',
  BU_BRAND = 'businessUnitBrand',
  BU_AGENCY_LOGO = 'businessUnitAgencyLogo',
  WHITE_LABEL = 'whiteLabel',
  QUOTE_OFFER_COMMENT = 'comment',
  PASSWORD_EXPIRE_DAYS_LEFT = 'passwordExpireDaysLeft',
  PASSWORD_EXPIRED = 'passwordExpires',
  CUSTOMER_NUMBER = 'customerNumber',
  USER_TYPE = 'userType',
}
