import { ChangeDetectionStrategy, Component } from '@angular/core';
import { tap, catchError } from 'rxjs';

import { DataAccessorService } from '../../../shared/services/data-accessor/data-accessor.service';
import { InfoHttpService } from '../../http/info/info-http.service';
import { EnvironmentService } from '../../services/environment/environment.service';
import { EnvironmentInfoDialogService } from '../../services/environment-info-dialog/environment-info-dialog.service';

@Component({
  selector: 'app-environment-info',
  templateUrl: './environment-info.component.html',
  styleUrls: ['./environment-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnvironmentInfoComponent {
  protected _currentEnvironmentName =
    this._dataAccessorService.getSpringProfileOrNull() || 'INVALID';

  constructor(
    private readonly _dataAccessorService: DataAccessorService,
    private readonly _infoHttpService: InfoHttpService,
    private readonly _environmentService: EnvironmentService,
    private readonly _environmentInfoDialogService: EnvironmentInfoDialogService,
  ) {}

  protected handleCurrentEnvironmentNameClick(): void {
    this._infoHttpService
      .info()
      .pipe(
        tap((infoResponseData) => {
          this._environmentInfoDialogService.openDialog({
            infoRequestSuccess: true,
            localProject: {
              name: 'onePortalUI',
              properties: {
                // TODO: Return to this once we start to deploy Angular app
                buildOn: '<Unknown>',
                baseUrl: this._environmentService.getBookingCoreApiUrl(),
              },
            },
            externalProjects: infoResponseData.projects,
          });
        }),
        catchError((error: unknown) => {
          this._environmentInfoDialogService.openDialog({
            infoRequestSuccess: false,
            error,
          });
          throw error;
        }),
      )
      .subscribe();
  }
}
