import {
  createComponent,
  Injectable,
  Type,
  ApplicationRef,
  EnvironmentInjector,
  ComponentRef,
  inject,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { IDomService } from './dom-service.interface';

@Injectable({
  providedIn: 'root',
})
export class DomService implements IDomService {
  private readonly _document = inject(DOCUMENT);
  private readonly _appRef = inject(ApplicationRef);
  private readonly _environmentInjector = inject(EnvironmentInjector);

  public appendComponentToBody<T>(component: Type<T>): ComponentRef<T> {
    const componentRef = createComponent(component, {
      environmentInjector: this._environmentInjector,
    });
    this._document.body.appendChild(componentRef.location.nativeElement);
    this._appRef.attachView(componentRef.hostView);
    return componentRef;
  }
}
