import { Injectable } from '@angular/core';
import { HttpWrapperService } from '../../../shared/services/http-wrapper/http-wrapper.service';
import { HttpEndpointsService } from '../../../shared/services/http-endpoints/http-endpoints.service';
import { UpdateOfferRequest } from 'src/app/shared/types/update-offer-request.type';
import { SuccessUpdateOfferData } from 'src/app/shared/types/update-offer-response.type';
import { Observable } from 'rxjs';
import { SuccessUpdateOfferStatusData } from 'src/app/shared/types/success-update-offer-status-data';

@Injectable({
  providedIn: 'root',
})
export class UpdateOfferService {
  constructor(
    private readonly httpWrapperService: HttpWrapperService,
    private readonly httpEndpointsService: HttpEndpointsService,
  ) {}

  public updateStatus(
    newStatus: string,
    quoteId: string,
  ): Observable<SuccessUpdateOfferStatusData> {
    const updateStatusRequest = this.generateUpdateStatusRequest(newStatus, quoteId);
    return this.httpWrapperService.put<SuccessUpdateOfferStatusData>(
      this.httpEndpointsService.buildUpdateOfferUrl(quoteId),
      updateStatusRequest,
    );
  }

  public updateComment(
    newComment: string | null,
    quoteId: string,
  ): Observable<SuccessUpdateOfferData> {
    const updateCommentRequest = this.generateUpdateCommentRequest(newComment, quoteId);
    return this.httpWrapperService.put<SuccessUpdateOfferData>(
      this.httpEndpointsService.buildUpdateOfferUrl(quoteId),
      updateCommentRequest,
    );
  }

  private generateUpdateStatusRequest(newStatus: string, quoteId: string): UpdateOfferRequest {
    return {
      snrDetails: {
        progressDetails: {
          quoteId: quoteId,
          source: 'AgentMax',
          type: 'Offer',
          status: newStatus,
        },
      },
    };
  }

  private generateUpdateCommentRequest(
    newComment: string | null,
    quoteId: string,
  ): UpdateOfferRequest {
    return {
      snrDetails: {
        progressDetails: {
          quoteId: quoteId,
          source: 'AgentMax',
          type: 'Offer',
          comment: newComment,
        },
      },
    };
  }
}
