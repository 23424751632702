import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SuccessLogoutMessageService {
  private _messageIsShown = false;

  public get isShown(): boolean {
    return this._messageIsShown;
  }

  public show(): void {
    this._messageIsShown = true;
  }

  public hide(): void {
    this._messageIsShown = false;
  }
}
