import { Component, ChangeDetectionStrategy, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NxInputModule } from '@aposin/ng-aquila/input';

@Component({
  selector: 'app-text-field-control',
  templateUrl: './text-field-control.component.html',
  styleUrls: ['./text-field-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextFieldControlComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NxInputModule],
})
export class TextFieldControlComponent implements ControlValueAccessor {
  @Input()
  public set label(value: string) {
    this._label = value;
  }

  @Input()
  public set placeholder(value: string) {
    this._placeholder = value;
  }

  protected _label = '';
  protected _placeholder = '';

  public writeValue(value: unknown): void {}

  public setDisabledState(isDisabled: boolean): void {}

  public registerOnChange(fn: (value: string) => {}): void {
    this._onChange = fn;
  }

  public registerOnTouched(fn: () => {}): void {
    this._onTouch = fn;
  }

  protected handleInput(value: string): void {
    this._onChange(value);
    this._onTouch();
  }

  private _onChange = (value: string) => {};
  private _onTouch = () => {};
}
