export const ALLIANZ_PARTNERS_HOSTS = ['allianzpartners-agentmax.ch'];

export const MONDIAL_ASSISTANCE_HOSTS = [
  'mondialassistance-agentmax.at',
  'mondialassistance-agentmax.fr',
  'mondialassistance-agentmax.hu',
  'mondialassistance-agentmax.ro',
];

export const GLOBAL_ASSISTANCE_HOSTS = ['allianztravel-agentmax.it', 'allianztravel-globy.it'];

export const ALLIANZ_TRAVEL_HOSTS = [
  'agentmax.cz',
  'allianzassistance-agentmax.ch',
  'allianzassistance-agentmax.co.uk',
  'allianztravel-agentmax.at',
  'allianztravel-agentmax.de',
  'allianztravel-agentmax.es',
  'allianztravel-agentmax.fr',
  'allianztravel-agentmax.hu',
  'allianztravel-agentmax.ro',
  'allianztravel-agentmaxbg.com',
];
