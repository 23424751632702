// Can be extended in the future in case of need
type IMockLocation = Pick<Location, 'hostname' | 'host'>;

export class MockLocation implements IMockLocation {
  readonly hostname = 'www.mock.com';
  readonly host = 'www.mock.com';
}

export class MockLocationPartners implements IMockLocation {
  readonly hostname = 'www.allianzpartners-agentmax.ch';
  readonly host = 'www.allianzpartners-agentmax.ch';
}

export class MockLocationMondial implements IMockLocation {
  readonly hostname = 'www.mondialassistance-agentmax.at';
  readonly host = 'www.mondialassistance-agentmax.at';
}

export class MockLocationGlobalAssistance implements IMockLocation {
  readonly hostname = 'www.allianztravel-agentmax.it';
  readonly host = 'www.allianztravel-agentmax.it';
}

export class MockLocationTravel implements IMockLocation {
  readonly hostname = 'www.allianztravel-agentmax.at';
  readonly host = 'www.allianztravel-agentmax.at';
}

export class MockLocationAgentmax implements IMockLocation {
  readonly hostname = 'https://oneportal.int.aws.emagin.eu/#/';
  readonly host = 'https://oneportal.int.aws.emagin.eu/#/';
}
