import { Component, Inject } from '@angular/core';
import { NX_MODAL_DATA } from '@aposin/ng-aquila/modal';
import { UpdateOfferService } from 'src/app/quote-admin/services/update-offer/update-offer.service';
import { DataAccessorService } from '@agentmax/shared/services/data-accessor/data-accessor.service';
import { QuoteOfferDetailsResponse } from 'src/app/shared/types/success-quote-offer-details-data.type';
import { UpdateOfferCommentService } from 'src/app/quote-admin/services/update-offer-comment/update-offer-comment.service';
import { DataRecordingService } from '@agentmax/shared/services/data-recording/data-recording.service';
import { catchError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

type UpdateOfferCommentDialogType = {
  comment: string;
};

@Component({
  selector: 'app-update-comment',
  templateUrl: './update-offer-comment-dialog.component.html',
  styleUrls: ['./update-offer-comment-dialog.component.scss'],
})
export class UpdateOfferCommentDialogComponent {
  successOnUpdate = false;
  errorOnUpdate = false;
  commentToUpdate: string;
  protected readonly quoteOfferDetails: QuoteOfferDetailsResponse =
    this.dataAccessorService.getQuoteOfferDetails();
  constructor(
    private readonly updateOfferService: UpdateOfferService,
    private readonly dataAccessorService: DataAccessorService,
    private readonly dataRecordingService: DataRecordingService,
    private readonly updateOfferCommentService: UpdateOfferCommentService,
    @Inject(NX_MODAL_DATA) public data: UpdateOfferCommentDialogType,
  ) {
    this.commentToUpdate = data.comment;
  }
  public updateOffer(value: string | null): void {
    this.successOnUpdate = false;
    this.errorOnUpdate = false;
    this.updateOfferService
      .updateComment(value, this.quoteOfferDetails.snrContract.quoteId)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.successOnUpdate = false;
          this.errorOnUpdate = true;
          throw error;
        }),
      )
      .subscribe((data) => {
        if (data.operationStatus === 'success') {
          this.updateOfferCommentService.setComment(value);
          this.dataRecordingService.saveQuoteOfferComment(value);
          this.successOnUpdate = true;
          this.errorOnUpdate = false;
        } else {
          this.successOnUpdate = false;
          this.errorOnUpdate = true;
        }
      });
  }
}
