<nx-header-navigation>
  <nx-header-navigation-item *ngFor="let link of _linkList">
    <a
      [routerLink]="link.url"
      [routerLinkActiveOptions]="{ exact: true }"
      routerLinkActive="is-active"
      nxHeaderLink
      >{{ link.text | translate }}</a
    >
  </nx-header-navigation-item>
</nx-header-navigation>
