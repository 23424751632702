import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ContactUsService } from '../../services/contact-us/contact-us.service';
import { NonNullableFormBuilder, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

type FeedbackStatus = 'success' | 'error';

@Component({
  selector: 'app-contact-us-modal',
  templateUrl: './contact-us-dialog.component.html',
  styleUrls: ['./contact-us-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactUsDialogComponent {
  public readonly contactForm = this.formBuilder.group({
    message: ['', [Validators.required]],
  });
  private readonly _feedbackStatus$ = new BehaviorSubject<FeedbackStatus | null>(null);

  public readonly feedbackStatus$ = this._feedbackStatus$.asObservable();

  constructor(
    private readonly contactUsService: ContactUsService,
    private readonly formBuilder: NonNullableFormBuilder,
  ) {}

  public handleContactFormSubmit(): void {
    if (this.contactForm.invalid) {
      return;
    }

    this.contactUsService.sendFeedback(this.contactForm.controls.message.value).subscribe({
      next: (data) => {
        this._feedbackStatus$.next('success');
      },
      error: (e) => {
        this._feedbackStatus$.next('error');
      },
    });
  }
}
