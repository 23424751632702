import { Injectable } from '@angular/core';
import { SortDirection } from '@aposin/ng-aquila/table';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  public compare(
    a: number | string | Date,
    b: number | string | Date,
    direction: SortDirection,
  ): number {
    return (a < b ? -1 : 1) * (direction === 'asc' ? 1 : -1);
  }
}
