import { Pipe, PipeTransform } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

@Pipe({
  name: 'hasError',
  standalone: true,
})
export class HasErrorPipe implements PipeTransform {
  public transform(value: ValidationErrors | null, errorName: string): boolean {
    return value !== null && value[errorName] === true;
  }
}
