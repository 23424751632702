<nx-header-brand *ngIf="logoSrc">
  <figure nxFigure>
    <img
      class="header-logo"
      [src]="logoSrc"
      onerror="this.style.display='none'"
      alt="{{ _side }} header logo"
    />
  </figure>
</nx-header-brand>
