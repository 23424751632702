import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { HttpEndpointsService } from '../../../shared/services/http-endpoints/http-endpoints.service';

import { GenerateTokenResponseData } from '../../../shared/types/generate-token-response-data.type';

type GenerateTokenParameters = Readonly<{
  refreshToken: string;
  userEmail: string;
}>;

@Injectable({
  providedIn: 'root',
})
export class TokensHttpService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly httpEndpointsService: HttpEndpointsService,
  ) {}

  public generateToken(params: GenerateTokenParameters): Observable<GenerateTokenResponseData> {
    const generateTokenUrl = this.httpEndpointsService.buildGenerateTokenUrl();
    return this.httpClient.post<GenerateTokenResponseData>(generateTokenUrl, params);
  }
}
