import { ChangeDetectionStrategy, Component, InjectionToken, inject } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable, map } from 'rxjs';

import { ClusterIdSessionStorageService } from '@agentmax/shared/session-storage/cluster-id-session-storage.service';
import { BrandForNewsAndProductsSessionStorageService } from '@agentmax/shared/session-storage/brand-for-news-and-products-session-storage.service';
import { IFrameService } from '@agentmax/shared/services/iframe/iframe.service';

import { LanguagesState } from '@agentmax/store/languages';
import { NewLanguageCode } from '@agentmax/shared/enums/language-code.enum';
import { AppUrl } from '@agentmax/shared/enums/app-url.enum';

export const ONE_WEB_HOME = new InjectionToken('ONE_WEB_HOME', {
  providedIn: 'root',
  factory: () => '/home' as const,
});

export const NEWS_PAGE_URL = new InjectionToken('NEWS_PAGE_URL', {
  providedIn: 'root',
  factory: () => `/${AppUrl.NEWS}` as const,
});

@Component({
  selector: 'app-home-news',
  templateUrl: './home-news.component.html',
  styleUrls: ['./home-news.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeNewsComponent {
  private readonly _iframeService = inject(IFrameService);
  private readonly _oneWebHome = inject(ONE_WEB_HOME);
  private readonly _clusterId = inject(ClusterIdSessionStorageService);
  private readonly _brandForNewsAndProducts = inject(BrandForNewsAndProductsSessionStorageService);
  protected readonly _newsPageUrl = inject(NEWS_PAGE_URL);

  @Select(LanguagesState.selectedCode)
  private readonly _selectedLanguageCode$!: Observable<NewLanguageCode>;

  protected readonly _iFrameURL$ = this._selectedLanguageCode$.pipe(
    map((selectedLanguageCode) =>
      this._iframeService.generateOneWebURL(
        this._oneWebHome,
        selectedLanguageCode,
        this._clusterId.getOrReturnNull(),
        this._brandForNewsAndProducts.getOrReturnNull(),
      ),
    ),
  );
}
