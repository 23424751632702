import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-quotation-stepper-layout',
  templateUrl: './quotation-stepper-layout.component.html',
  styleUrls: ['./quotation-stepper-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuotationStepperLayoutComponent {
  // TODO: Move to a corresponding service
  // The steps themselves and all their management logic
  protected readonly _steps = [
    'Trip Details',
    'Products',
    'Traveler Information',
    'Payment',
    'Confirmation',
  ];
}
