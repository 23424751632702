import { CurrentUserType } from '@agentmax/shared/types/current-user-type.type';

export namespace CurrentUser {
  export class Save {
    public static readonly type = '[CurrentUser] Save';

    constructor(public readonly currentUser: CurrentUserType) {}
  }

  export class Clear {
    public static readonly type = '[CurrentUser] Clear';
  }

  export class Restore {
    public static readonly type = '[CurrentUser] Restore';

    constructor(public readonly currentUser: CurrentUserType) {}
  }
}
