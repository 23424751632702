import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpWrapperService } from '../../../shared/services/http-wrapper/http-wrapper.service';
import { HttpEndpointsService } from '../../../shared/services/http-endpoints/http-endpoints.service';

import { InfoResponseData } from '../../../shared/types/info-response-data.type';

@Injectable({
  providedIn: 'root',
})
export class InfoHttpService {
  constructor(
    private readonly _httpWrapperService: HttpWrapperService,
    private readonly _httpEndpointsService: HttpEndpointsService,
  ) {}

  public info(): Observable<InfoResponseData> {
    return this._httpWrapperService.get<InfoResponseData>(
      this._httpEndpointsService.buildInfoUrl(),
    );
  }
}
