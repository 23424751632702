import { Injectable } from '@angular/core';
import { UserIdleService } from 'angular-user-idle';
import { tap, filter } from 'rxjs';

import { SessionExpiresSoonDialogService } from '../session-expires-soon-dialog/session-expires-soon-dialog.service';
import { LogoutService } from '../logout/logout.service';

@Injectable({
  providedIn: 'root',
})
export class IdleEventsWatcherService {
  constructor(
    private readonly _userIdleService: UserIdleService,
    private readonly _sessionExpiresSoonDialogService: SessionExpiresSoonDialogService,
    private readonly _logoutService: LogoutService,
  ) {}

  public start(): void {
    this._userIdleService
      .onTimerStart()
      .pipe(
        // Despite the fact the typing says that only numbers can appear
        // here in some cases null values are also come and we need to bypass them
        filter((timerCount: number | null): timerCount is number => timerCount !== null),
        tap(() => {
          if (this._sessionExpiresSoonDialogService.isOpened) {
            return;
          }

          this._sessionExpiresSoonDialogService.openDialog();
        }),
      )
      .subscribe();

    this._userIdleService
      .onTimeout()
      .pipe(
        tap(() => {
          this._userIdleService.stopWatching();
          this._sessionExpiresSoonDialogService.closeDialog();
          this._logoutService.logoutAndCloseApp({ showSuccessMessage: false }).subscribe();
        }),
      )
      .subscribe();
  }
}
