import { ChangeDetectionStrategy, Component } from '@angular/core';

import { LoginPageViewService } from '../../services/login-page-view.service';
import { LoginStrategyFactory } from '../../factories/login-strategy.factory';
import { SuccessLogoutMessageService } from '../../services/success-logout-message.service';

import { LoginPageView } from '../../enums/login-page-view.enum';
import { LoginResponseData } from '../../../shared/types/login-response-data.type';
import { ForgotPasswordResolveData } from '../../types/forgot-password-resolve-data.type';
import { BaseResponseData } from '@agentmax/shared/types/base-response-data.type';
import { ErrorSwitchAccountMessageService } from '../../services/error-switch-account-message.service';

@Component({
  selector: 'app-login-page-view-container',
  templateUrl: './login-page-view-container.component.html',
  styleUrls: ['./login-page-view-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginPageViewContainerComponent {
  protected readonly currentLoginPageView$ = this._loginPageViewService.currentView$;
  protected readonly LoginPageView = LoginPageView;
  protected _forgotPasswordResolveData: ForgotPasswordResolveData | null = null;
  protected _loginFormResolveData: LoginResponseData | null = null;
  public createPasswordSuccessMsg = 'passchangepage.0.PASSCHANGESUCCESS';
  public resetPasswordSuccessMsg = 'passchangepage.0.PASSCHANGESUCCESS';

  constructor(
    protected readonly _loginPageViewService: LoginPageViewService,
    protected readonly _loginStrategyFactory: LoginStrategyFactory,
    protected readonly _successLogoutMessageService: SuccessLogoutMessageService,
    protected readonly _errorSwitchAccountMessageeService: ErrorSwitchAccountMessageService,
  ) {}

  protected handleLoginFormResolve(data: LoginResponseData): void {
    this._loginFormResolveData = data;
    this._loginStrategyFactory.build(data).execute(data);
  }

  protected handleForgotPasswordFormResolve(data: ForgotPasswordResolveData): void {
    this._forgotPasswordResolveData = data;
    this._loginPageViewService.switchToResetPasswordView();
  }

  protected handleResetPasswordResolve(_data: BaseResponseData): void {
    this._loginPageViewService.switchToPasswordSuccessfullyResetView();
  }

  protected handleCreatePasswordResolve(_data: BaseResponseData): void {
    this._loginPageViewService.switchToPasswordSuccessfullyCreatedView();
  }
}
