<div class="create-password-form">
  <div class="item_message">
    <nx-message context="success">
      <span>{{messageText | translate}}</span>
    </nx-message>
  </div>
  <div class="item_button">
    <button
      nxButton="primary"
      type="button"
      (click)="continueClick.emit()"
    >
      <span>{{ "passchangepage.0.CONTINUELOGIN" | translate }}</span>
    </button>
  </div>
</div>
