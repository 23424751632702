import { Injectable, inject } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, finalize } from 'rxjs';

import { LoadingOverlayService } from '@agentmax/core/services/loading-overlay/loading-overlay.service';

import { SHOW_LOADING_OVERLAY } from '@agentmax/core/http/contexts/show-loading-overlay';

@Injectable({
  providedIn: 'root',
})
export class LoadingOverlayInterceptor implements HttpInterceptor {
  private readonly _loadingOverlayService = inject(LoadingOverlayService);

  private _requestsCount = 0;

  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!req.context.get(SHOW_LOADING_OVERLAY)) {
      return next.handle(req);
    }

    this._loadingOverlayService.show();
    this.increaseRequestsCount();

    return next.handle(req).pipe(
      finalize(() => {
        this.decreaseRequestsCount();

        if (this.checkIsNoRequestsLeft()) {
          this._loadingOverlayService.hide();
        }
      }),
    );
  }

  private increaseRequestsCount(): void {
    this._requestsCount += 1;
  }

  private decreaseRequestsCount(): void {
    this._requestsCount -= 1;
  }

  private checkIsNoRequestsLeft(): boolean {
    return this._requestsCount === 0;
  }
}
