import { Pipe, PipeTransform } from '@angular/core';

import { FeatureToggleList } from '../../types/feature-toggle.type';
import { ErrorService } from '../../services/error/error.service';

type FeatureToggleReleaseVersion = Readonly<{
  major: string;
  minor: string;
}>;

@Pipe({
  name: 'sortFeatureToggleList',
  standalone: true,
})
export class SortFeatureToggleListPipe implements PipeTransform {
  constructor(private readonly errorService: ErrorService) {}

  public transform(value: FeatureToggleList): FeatureToggleList {
    return value.slice().sort((leftFT, rightFT) => {
      const leftFTName = leftFT.name;
      const rightFTName = rightFT.name;
      const { major: leftMajor, minor: leftMinor } =
        this.extractReleaseFromFeatureToggleName(leftFTName);
      const { major: rightMajor, minor: rightMinor } =
        this.extractReleaseFromFeatureToggleName(rightFTName);
      const leftMajorAsNumber = parseInt(leftMajor);
      const rightMajorAsNumber = parseInt(rightMajor);

      if (Number.isNaN(leftMajorAsNumber)) {
        this.errorService.throwError(
          this.constructor.name,
          `Feature toggle '${leftFTName}' release version is wrong!`,
        );
      }

      if (Number.isNaN(rightMajorAsNumber)) {
        this.errorService.throwError(
          this.constructor.name,
          `Feature toggle '${rightFTName}' release version is wrong!`,
        );
      }

      if (leftMajorAsNumber > rightMajorAsNumber) {
        return 1;
      }

      if (leftMajorAsNumber < rightMajorAsNumber) {
        return -1;
      }

      const leftMinorAsNumber = parseInt(leftMinor);
      const rightMinorAsNumber = parseInt(rightMinor);
      const isLeftMinorANumber = !Number.isNaN(leftMinorAsNumber);
      const isRightMinorANumber = !Number.isNaN(rightMinorAsNumber);

      if (isLeftMinorANumber && isRightMinorANumber) {
        return leftMinorAsNumber > rightMinorAsNumber ? 1 : -1;
      }

      if (isLeftMinorANumber && !isRightMinorANumber) {
        return 1;
      }

      if (!isLeftMinorANumber && isRightMinorANumber) {
        return -1;
      }

      return leftMinor.localeCompare(rightMinor);
    });
  }

  // An example of feature toggle name is: 'R23.B.migration_help_resources'
  private extractReleaseFromFeatureToggleName(name: string): FeatureToggleReleaseVersion {
    const [major, minor] = name.replace('R', '').split('.');
    return { major, minor };
  }
}
