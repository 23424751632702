export enum CountryCode {
  AUSTRIA = 'AT',
  CZECH = 'CZ',
  ITALY = 'IT',
  SPAIN = 'ES',
  UK = 'GB',
  SWITZERLAND = 'CH',
  GERMANY = 'DE',
  HUNGARY = 'HU',
  BULGARIA = 'BG',
  ROMANIA = 'RO',
  FRANCE = 'FR',
}

export const isCountryCode = (value: string): value is CountryCode => {
  return Object.values<string>(CountryCode).includes(value);
};
