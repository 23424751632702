import { Injectable } from '@angular/core';
import { Observable, switchMap } from 'rxjs';

import { HttpWrapperServiceInterface } from './http-wrapper-service.interface';
import { BaseHttpService } from '@agentmax/shared/base/base-http.service';

@Injectable({
  providedIn: 'root',
})
// TODO: Not covered with unit tests
export class HttpWrapperService extends BaseHttpService implements HttpWrapperServiceInterface {
  public get<T>(endpointUrl: string): Observable<T> {
    return this.pullTokens().pipe(
      switchMap(() =>
        this.httpClient.get<T>(endpointUrl, {
          context: this.buildAuthorizeContext(),
        }),
      ),
    );
  }

  public post<T, O = unknown>(endpointUrl: string, requestBody: O): Observable<T> {
    return this.pullTokens().pipe(
      switchMap(() =>
        this.httpClient.post<T>(endpointUrl, requestBody, {
          context: this.buildAuthorizeContext(),
        }),
      ),
    );
  }

  public put<T, O = unknown>(endpointUrl: string, requestBody: O): Observable<T> {
    return this.pullTokens().pipe(
      switchMap(() =>
        this.httpClient.put<T>(endpointUrl, requestBody, {
          context: this.buildAuthorizeContext(),
        }),
      ),
    );
  }
}
