import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { BrowserTabIconService } from '@agentmax/core/services/browser-tab-icon/browser-tab-icon.service';

@Component({
  selector: 'app-login-page-layout',
  templateUrl: './login-page-layout.component.html',
  styleUrls: ['./login-page-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginPageLayoutComponent implements OnInit {
  constructor(private readonly browserTabIconService: BrowserTabIconService) {}

  ngOnInit(): void {
    this.browserTabIconService.setTabIconBeforeLogin();
  }
}
