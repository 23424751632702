import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from '@angular/core';
import { NonNullableFormBuilder, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, takeUntil, tap, throwError } from 'rxjs';

import { UriService } from '../../../core/services/uri/uri.service';
import { VerifyUserEmailHttpService } from '../../../core/http/verify-user-email/verify-user-email-http.service';

import { DestroyEmitter } from '../../../shared/classes/destroy-emitter.class';
import { ForgotPasswordResolveData } from '../../types/forgot-password-resolve-data.type';

@Component({
  selector: 'app-forgot-password-form',
  templateUrl: './forgot-password-form.component.html',
  styleUrls: ['./forgot-password-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForgotPasswordFormComponent extends DestroyEmitter implements OnInit {
  @Output()
  public readonly resolve = new EventEmitter<ForgotPasswordResolveData>();

  protected _backendErrorMessage = '';
  protected readonly _forgotPasswordForm = this._formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
  });

  constructor(
    private readonly _formBuilder: NonNullableFormBuilder,
    private readonly _uriService: UriService,
    private readonly _verifyUserEmailHttpService: VerifyUserEmailHttpService,
    private readonly _cdRef: ChangeDetectorRef,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.subToForgotPasswordFormValueChanges();
  }

  protected handleForgotPasswordFormSubmit(): void {
    if (this._forgotPasswordForm.invalid) {
      return;
    }

    const { email } = this._forgotPasswordForm.getRawValue();
    const emailId = this._uriService.encodeComponent(email);
    this._verifyUserEmailHttpService
      .verifyUserEmail(emailId)
      .pipe(
        tap((data) => {
          this.resolve.emit({
            ...data,
            userEmail: email,
          });
        }),
        catchError((httpErrorResponse: HttpErrorResponse) => {
          // Not sure if it is possible that `httpErrorResponse.error` equals to `null`
          // but this logic was migrated from the old AngularJS app
          this._backendErrorMessage =
            httpErrorResponse.error !== null ? 'loginpage.0.EMAILNVAL' : 'loginpage.0.VE_001';
          this._cdRef.markForCheck();
          return throwError(() => httpErrorResponse);
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private subToForgotPasswordFormValueChanges(): void {
    this._forgotPasswordForm.valueChanges
      .pipe(
        tap(() => (this._backendErrorMessage = '')),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }
}
