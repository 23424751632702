import { Injector, NgModule, Type, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { createCustomElement } from '@angular/elements';
import { CookieService as NgxCookieService } from 'ngx-cookie-service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS, HttpBackend } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UserIdleModule } from 'angular-user-idle';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { GlobalPageLayoutComponent } from './core/layouts/global-page-layout/global-page-layout.component';
import { InnerPageLayoutComponent } from './core/layouts/inner-page-layout/inner-page-layout.component';
import { TopBannerLayoutComponent } from './core/layouts/top-banner-layout/top-banner-layout.component';
import { QuotationStepperLayoutComponent } from './core/layouts/quotation-stepper-layout/quotation-stepper-layout.component';
import { NewsPageComponent } from './news/pages/news-page/news-page.component';
import { HelpPageComponent } from './help/pages/help-page/help-page.component';
import { SaveOfferComponent } from './angularjs/components/save-offer/save-offer.component';
import { ViewOfferComponent } from './quote-admin/components/view-offer/view-offer.component';
import { PolicyAdminPageComponent } from './policy-admin/pages/policy-admin-page/policy-admin-page.component';
import { QuoteAdminPageComponent } from './quote-admin/components/quote-admin-page/quote-admin-page.component';
import { FooterComponent } from './core/components/footer/footer.component';
import { ChangeLanguageHelperComponent } from './angularjs/components/change-language-helper/change-language-helper.component';
import { HomePageComponent } from './home/pages/home-page/home-page.component';
import { NavHeaderComponent } from './core/components/nav-header/nav-header.component';
import { WelcomeMessageComponent } from './core/components/welcome-message/welcome-message.component';
import { SessionExpiresSoonDialogComponent } from './core/dialogs/session-expires-soon-dialog/session-expires-soon-dialog.component';

import { LOCATION_PROVIDER } from './core/deprecated-global-dependencies/location.global-dependency';
import { SESSION_STORAGE_PROVIDER } from './core/deprecated-global-dependencies/session-storage.global-dependency';
import { WINDOW_PROVIDER } from './core/deprecated-global-dependencies/window.global-dependency';
import { TrustedResourceUrlPipe } from './shared/pipes/trusted-resource-url/trusted-resource-url.pipe';
import { SortFeatureToggleListPipe } from './shared/pipes/sort-feature-toggle-list/sort-feature-toggle-list.pipe';
import { DataAccessorService } from './shared/services/data-accessor/data-accessor.service';
import { AuthorizationParamsInterceptor } from './core/interceptors/authorization-params/authorization-params.interceptor';
import { environment } from '../environments/environment';

import { NdbxIconModule } from '@allianz/ngx-ndbx/icon';
import { NxIconModule } from '@aposin/ng-aquila/icon';
import { NxButtonModule } from '@aposin/ng-aquila/button';
import { NxCheckboxModule } from '@aposin/ng-aquila/checkbox';
import { NxFormfieldModule } from '@aposin/ng-aquila/formfield';
import { NxInputModule } from '@aposin/ng-aquila/input';
import { NxGridModule } from '@aposin/ng-aquila/grid';
import { NxHeadlineModule } from '@aposin/ng-aquila/headline';
import { NxDataDisplayModule } from '@aposin/ng-aquila/data-display';
import { NxAccordionModule } from '@aposin/ng-aquila/accordion';
import { NxCopytextModule } from '@aposin/ng-aquila/copytext';
import { NxDatefieldModule } from '@aposin/ng-aquila/datefield';
import { NxMomentDateModule } from '@aposin/ng-aquila/moment-date-adapter';
import { NxErrorModule } from '@aposin/ng-aquila/base';
import { NxLabelModule } from '@aposin/ng-aquila/base';
import { NxListModule } from '@aposin/ng-aquila/list';
import { NxTableModule } from '@aposin/ng-aquila/table';
import { NxDynamicTableModule } from '@aposin/ng-aquila/dynamic-table';
import { NxPaginationModule } from '@aposin/ng-aquila/pagination';
import { NxBadgeModule } from '@aposin/ng-aquila/badge';
import { NxLinkModule } from '@aposin/ng-aquila/link';
import { NxFooterModule } from '@aposin/ng-aquila/footer';
import { NxSpinnerModule } from '@aposin/ng-aquila/spinner';
import { NxImageModule } from '@aposin/ng-aquila/image';
import { NxHeaderModule } from '@aposin/ng-aquila/header';
import { NxModalModule } from '@aposin/ng-aquila/modal';
import { NxDropdownModule } from '@aposin/ng-aquila/dropdown';
import { NxRadioModule } from '@aposin/ng-aquila/radio-button';
import { NxTabsModule } from '@aposin/ng-aquila/tabs';
import { NxProgressStepperModule } from '@aposin/ng-aquila/progress-stepper';
import { UpdateOfferStatusDialogComponent } from './core/dialogs/update-offer-status-dialog/update-offer-status-dialog.component';
import { LoginModule } from './login/login.module';
import { LogoutComponent } from './core/components/logout/logout.component';
import { LogoutIconComponent } from './core/components/logout-icon/logout-icon.component';
import { InnerLogoComponent } from './core/components/inner-logo/inner-logo.component';
import { UpdateOfferCommentDialogComponent } from './angularjs/dialogs/update-offer-comment-dialog/update-offer-comment-dialog.component';
import { NxExpertModule } from '@aposin/ng-aquila/config';
import { ContactUsComponent } from './shared/components/contact-us/contact-us.component';
import { EnvironmentInfoComponent } from './core/components/environment-info/environment-info.component';
import { BuConfigurationComponent } from './core/components/bu-configuration/bu-configuration.component';
import { FeatureTogglesComponent } from './core/components/feature-toggles/feature-toggles.component';
import { FeatureTogglesViewComponent } from './core/components/feature-toggles-view/feature-toggles-view.component';
import { EnvironmentInfoDialogComponent } from './core/dialogs/environment-info-dialog/environment-info-dialog.component';
import { ApplicationKeyValueTableComponent } from './core/components/application-key-value-table/application-key-value-table.component';
import { ContactUsDialogComponent } from './core/dialogs/contact-us-dialog/contact-us-dialog.component';
import { NxMessageModule } from '@aposin/ng-aquila/message';
import { NxPopoverModule } from '@aposin/ng-aquila/popover';
import { HomeNewsComponent } from './home/components/home-news/home-news.component';
import { PasswordExpiresSoonDialogComponent } from './core/dialogs/password-expires-soon-dialog/password-expires-soon-dialog.component';
import { NAV_LINKS_FILTERS_PROVIDERS } from './core/services/nav-links/nav-links-filters-providers';
import { ProdErrorHandler } from './core/error-handler/prod-error-handler';
import { ENVIRONMENT } from './global/environment';
import { EnvironmentConfig } from './shared/types/environment-config.type';
import { LoadingOverlayInterceptor } from './core/interceptors/loading-overlay/loading-overlay.interceptor';
import { WildCardRouteModule } from './wild-card-route.module';
import { NgxsModule } from '@ngxs/store';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { STORE } from './store';

@NgModule({
  declarations: [
    AppComponent,
    GlobalPageLayoutComponent,
    InnerPageLayoutComponent,
    TopBannerLayoutComponent,
    QuotationStepperLayoutComponent,
    HelpPageComponent,
    NewsPageComponent,
    TrustedResourceUrlPipe,
    SaveOfferComponent,
    ViewOfferComponent,
    QuoteAdminPageComponent,
    FooterComponent,
    ChangeLanguageHelperComponent,
    HomePageComponent,
    NavHeaderComponent,
    UpdateOfferStatusDialogComponent,
    SessionExpiresSoonDialogComponent,
    LogoutComponent,
    UpdateOfferCommentDialogComponent,
    InnerLogoComponent,
    ContactUsComponent,
    EnvironmentInfoComponent,
    BuConfigurationComponent,
    FeatureTogglesComponent,
    FeatureTogglesViewComponent,
    EnvironmentInfoDialogComponent,
    ApplicationKeyValueTableComponent,
    ContactUsDialogComponent,
    WelcomeMessageComponent,
    HomeNewsComponent,
    PasswordExpiresSoonDialogComponent,
  ],
  providers: [
    SESSION_STORAGE_PROVIDER,
    LOCATION_PROVIDER,
    WINDOW_PROVIDER,
    NgxCookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizationParamsInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingOverlayInterceptor,
      multi: true,
    },
    ...NAV_LINKS_FILTERS_PROVIDERS,
    {
      provide: ErrorHandler,
      useFactory: (env: EnvironmentConfig) => {
        if (env.isProduction) {
          return new ProdErrorHandler();
        }

        return new ErrorHandler();
      },
      deps: [ENVIRONMENT],
    },
  ],
  bootstrap: environment.standalone ? [AppComponent] : [],
  imports: [
    BrowserModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend],
      },
    }),
    FormsModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    NxModalModule,
    UserIdleModule.forRoot({
      idle: 1500, // in seconds
      timeout: 300, // in seconds
    }),
    NdbxIconModule,
    NxGridModule,
    NxButtonModule,
    NxCheckboxModule,
    NxFormfieldModule,
    NxInputModule,
    NxHeadlineModule,
    NxAccordionModule,
    NxHeadlineModule,
    NxCopytextModule,
    NxDatefieldModule,
    NxMomentDateModule,
    NxErrorModule,
    NxLabelModule,
    NxListModule,
    NxTableModule,
    NxDynamicTableModule,
    NxPaginationModule,
    NxBadgeModule,
    NxLinkModule,
    NxDataDisplayModule,
    NxFooterModule,
    NxSpinnerModule,
    NxImageModule,
    NxHeaderModule,
    NxDropdownModule,
    NxTabsModule,
    NxProgressStepperModule,
    NxIconModule,
    LogoutIconComponent,
    PolicyAdminPageComponent,
    NxExpertModule,
    NxMessageModule,
    NxPopoverModule,
    NxRadioModule,
    SortFeatureToggleListPipe,

    NgxsModule.forRoot(STORE, {
      // Check for selectorOptions details https://www.ngxs.io/concepts/select#selector-options
      selectorOptions: {
        suppressErrors: false,
        injectContainerState: false,
      },
    }),
    NgxsLoggerPluginModule.forRoot({
      disabled: environment.isProduction,
    }),

    // Modules with routing
    AppRoutingModule,
    LoginModule,
    WildCardRouteModule, // This module must always be the last!
  ],
})
export class AppModule {
  constructor(
    private injector: Injector,
    private translateService: TranslateService,
    private dataAccessorService: DataAccessorService,
  ) {
    if (!environment.standalone) {
      this.translateService.setDefaultLang('en');
      const newLang = this.dataAccessorService.getCurrentLanguageCode();
      this.translateService.use(newLang);
    }

    const elements: Array<[Type<unknown>, string]> = [
      [ChangeLanguageHelperComponent, 'ng-app-root'],
      [HelpPageComponent, 'ng-help-page'],
      [NewsPageComponent, 'ng-products-page'],
      [SaveOfferComponent, 'ng-save-offer-popup'],
      [ViewOfferComponent, 'ng-view-offer-page'],
      [QuoteAdminPageComponent, 'ng-quote-admin-page'],
      [UpdateOfferStatusDialogComponent, 'ng-update-offer-status'],
    ];

    for (const [component, name] of elements) {
      const htmlElement = createCustomElement(component, { injector });
      customElements.define(name, htmlElement);
    }
  }
  ngDoBootstrap() {}
}

export function HttpLoaderFactory(httpBackend: HttpBackend): TranslateHttpLoader {
  const http = new HttpClient(httpBackend);
  const pathToTranslations = environment.standalone
    ? './assets/translations/'
    : './scripts/translations/';
  return new TranslateHttpLoader(http, pathToTranslations, '.json');
}
