import { Injectable, inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, forkJoin, tap, take } from 'rxjs';

import { AppConfigurationService } from '../app-configuration/app-configuration.service';
import { AppFeatureTogglesService } from '../app-feature-toggles/app-feature-toggles.service';

import { DataRecordingService } from '../../../shared/services/data-recording/data-recording.service';
import { LoginResponseData } from '../../../shared/types/login-response-data.type';
import { UserInfoMapperService } from '../../mappers/user-info/user-info-mapper.service';
import { BrowserTabIconService } from '../browser-tab-icon/browser-tab-icon.service';
import { Languages } from '@agentmax/store/languages';
import { BuLanguageServiceFactory } from '../bu-language/bu-language.service.factory';

export interface IAppInitializationService {
  initialize(data: LoginResponseData): Observable<unknown>;
}

@Injectable({
  providedIn: 'root',
})
export class AppInitializationService implements IAppInitializationService {
  private readonly _store = inject(Store);
  private readonly _buLanguageServiceFactory = inject(BuLanguageServiceFactory);

  constructor(
    private readonly appFeatureTogglesService: AppFeatureTogglesService,
    private readonly appConfigurationService: AppConfigurationService,
    private readonly dataRecordingService: DataRecordingService,
    private readonly userInfoMapperService: UserInfoMapperService,
    private readonly browserTabIconService: BrowserTabIconService
  ) {}

  public initialize(data: LoginResponseData): Observable<unknown> {
    return forkJoin([
      this.appFeatureTogglesService.getAppFeatureToggles(),
      this.appConfigurationService.getAppConfiguration(),
    ]).pipe(
      tap(([appFeatureToggles, appConfiguration]) => {
        if (appFeatureToggles !== null) {
          this.appFeatureTogglesService.saveAppFeatureToggles(appFeatureToggles);
        }
        if (appConfiguration !== null) {
          this.appConfigurationService.saveAppConfiguration(appConfiguration);
        }

        this.saveUserData(data);
        const defaultAgencyInfo = data.accountsDetails.filter((details) => details.defaultAgency);
        const rawCountryCode = defaultAgencyInfo[0].businessUnits.countryCode;
        const buLanguageService = this._buLanguageServiceFactory.build(rawCountryCode);
        this._store.dispatch(
          new Languages.Update(
            buLanguageService.getDefaultLanguageCode(data.defaultLanguageCode, rawCountryCode),
            buLanguageService.getLanguageOptionsName(rawCountryCode),
          ),
        );
        this.browserTabIconService.setTabIconAfterLogin(defaultAgencyInfo[0].agency.brand);
      }),
      take(1),
    );
  }

  private saveUserData(loginData: LoginResponseData): void {
    if (loginData.accountsDetails.length === 0) {
      return;
    }
    const defaultAgencyInfo = loginData.accountsDetails.filter((details) => details.defaultAgency);
    if (defaultAgencyInfo.length > 0) {
      const currentUserTokens = {
        TokenId: loginData.accessToken,
        UserId: loginData.userId,
        AgencyId: loginData.agencyName,
        user_permissions: defaultAgencyInfo[0].roleId,
      };
      this.dataRecordingService.saveUserTokens(currentUserTokens);
      this.dataRecordingService.saveUserCountryCode(defaultAgencyInfo[0].businessUnits.countryCode);
      const currentUserInfo = this.userInfoMapperService.fromLoginDataToUserInformation(loginData);
      this.dataRecordingService.saveUserInformation(currentUserInfo);
      this.dataRecordingService.saveAccountDetails(loginData.accountsDetails);
      this.dataRecordingService.saveClusterId(loginData.clusterId);
      this.dataRecordingService.saveBusinessUnits(defaultAgencyInfo[0].businessUnits);
      this.dataRecordingService.saveCustomerNumber(defaultAgencyInfo[0].agency.customerNumber);
      if (defaultAgencyInfo[0].agency.brand) {
        this.dataRecordingService.saveBUBrand(defaultAgencyInfo[0].agency.brand);
      }
      this.dataRecordingService.saveWhiteLabelFlag(loginData.whiteLabel);
      this.dataRecordingService.saveUserType(loginData.userType);
      if (defaultAgencyInfo[0].agency.logo) {
        this.dataRecordingService.saveBUAgencyLogo(defaultAgencyInfo[0].agency.logo);
      }
      const myAccountInfo = {
        email: loginData.email,
        userName: loginData.userName,
        lastName: loginData.lastName,
        agencyName: loginData.agencyName,
        agencyId: loginData.agencyId,
        agentId: loginData.agentId,
        defaultLanguageCode: loginData.defaultLanguageCode,
        accountsDetails: loginData.accountsDetails,
      };
      this.dataRecordingService.saveMyAccountInfo(myAccountInfo);
    }
  }
}
