import { Injectable, Injector } from '@angular/core';

import { LoginResponseData } from '../../shared/types/login-response-data.type';
import { LoginStrategy } from '../classes/login.strategy';
import { NewUserStrategy } from '../strategies/new-user.strategy';
import { PasswordAlmostExpiredStrategy } from '../strategies/password-almost-expired.strategy';
import { PasswordExpiredStrategy } from '../strategies/password-expired.strategy';
import { SuccessStrategy } from '../strategies/success.strategy';

@Injectable({
  providedIn: 'root',
})
export class LoginStrategyFactory {
  constructor(private readonly _injector: Injector) {}

  public build(data: LoginResponseData): LoginStrategy {
    if (data.newUser) {
      return this._injector.get(NewUserStrategy);
    }

    if (data.passwordExpired === '1') {
      return this._injector.get(PasswordAlmostExpiredStrategy);
    }

    if (data.passwordExpired === '2') {
      return this._injector.get(PasswordExpiredStrategy);
    }

    return this._injector.get(SuccessStrategy);
  }
}
