<div class="products-page">
  <div id="main-columns">
    <div class="container">
      <div class="row row-10">
        <iframe
          *ngIf="iFrameURLStream | async as iFrameURL"
          [src]="iFrameURL | trustedResourceUrl"
          width="100%"
          height="600"
          title="Products Iframe"
        >
        </iframe>
      </div>
    </div>
  </div>
</div>
