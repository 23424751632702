import { Injectable } from '@angular/core';

import { EnvironmentService } from '../../../core/services/environment/environment.service';

import { HttpEndpointsServiceInterface } from './http-endpoints-service.interface';

@Injectable({
  providedIn: 'root',
})
// TODO: Not covered with unit tests
export class HttpEndpointsService implements HttpEndpointsServiceInterface {
  constructor(private readonly environmentService: EnvironmentService) {}

  public buildGenerateTokenUrl(): string {
    return `${this.getBookingCoreApiUrl()}generateToken`;
  }

  public buildLoginUrl(): string {
    return `${this.getBookingCoreApiUrl()}login`;
  }

  public buildLogoutUrl(): string {
    return `${this.getBookingCoreApiUrl()}logout`;
  }

  public buildAppFeatureTogglesUrl(): string {
    return `${this.getBookingCoreApiUrl()}featureToggles`;
  }

  public buildAppConfigurationUrl(): string {
    return `${this.getBookingCoreApiUrl()}configuration`;
  }

  public buildSaveOfferUrl(): string {
    return `${this.getBookingCoreApiUrl()}offerdetail/save`;
  }
  public buildSearchQuoteUrl(): string {
    return `${this.getBookingCoreApiUrl()}offerdetail/search`;
  }

  public buildOfferDetailsUrl(quoteId: string): string {
    return `${this.getBookingCoreApiUrl()}offerdetail/${quoteId}`;
  }

  public buildVerifyUserEmailUrl(emailId: string): string {
    return `${this.getBookingCoreApiUrl()}verifyUserEmail?emailId=${emailId}`;
  }

  public buildUpdateOfferUrl(quoteId: string): string {
    return `${this.getBookingCoreApiUrl()}offerdetail/update/${quoteId}`;
  }

  public buildResetPasswordUrl(): string {
    return `${this.getBookingCoreApiUrl()}resetPassword`;
  }

  public buildChangeSecurityUrl(): string {
    return `${this.getBookingCoreApiUrl()}changeSecurity`;
  }

  public buildResetPasswordForceUrl(): string {
    return this.buildChangeSecurityUrl();
  }

  public buildSendFeedbackUrl(): string {
    return `${this.getBookingCoreApiUrl()}sendFeedback`;
  }

  public buildInfoUrl(): string {
    return `${this.getBookingCoreApiUrl()}info`;
  }

  public buildProfilePreferenceUrl(): string {
    return `${this.getBookingCoreApiUrl()}modifyChanges`;
  }

  public buildSearchPolicyUrl(): string {
    return `${this.getBookingCoreApiUrl()}searchPolicy_cisl2`;
  }

  public buildEditAccountDetailsUrl(): string {
    return `${this.getBookingCoreApiUrl()}accountDetails`;
  }

  public buildChangeRoleUrl(): string {
    return `${this.getBookingCoreApiUrl()}changeRole`;
  }

  public buildModifyUserStatusApiUrl(): string {
    return `${this.getBookingCoreApiUrl()}modifyUserStatus`;
  }

  public buildRemoveUserApiUrl(): string {
    return `${this.getBookingCoreApiUrl()}removeUser`;
  }

  public buildResendActivationEmailApiUrl(): string {
    return `${this.getBookingCoreApiUrl()}resendMail`;
  }

  public buildAddPartnerUrl(): string {
    return `${this.getBookingCoreApiUrl()}addPartner`;
  }

  private getBookingCoreApiUrl(): string {
    return this.environmentService.getBookingCoreApiUrl();
  }
}
