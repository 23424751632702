import { Injectable, Injector, inject } from '@angular/core';

import { UKLanguageService } from './uk-language.service';
import { CHLanguageService } from './ch-language.service';
import { BuLanguageService } from './bu-language.service';

import { CountryCode, isCountryCode } from '@agentmax/shared/enums/country-code.enum';

@Injectable({
  providedIn: 'root',
})
export class BuLanguageServiceFactory {
  private readonly _injector = inject(Injector);

  public build(rawCountryCode: string): BuLanguageService {
    if (!isCountryCode(rawCountryCode)) {
      throw new Error(`Unknown country code: '${rawCountryCode}'!`);
    }

    switch (rawCountryCode) {
      case CountryCode.UK:
        return this._injector.get(UKLanguageService);
      case CountryCode.SWITZERLAND:
        return this._injector.get(CHLanguageService);
      default:
        return this._injector.get(BuLanguageService);
    }
  }
}
