<div class="home-news">
  <header class="home-news-header text-center">
    {{ "tripdetailpage.0.NEWS" | translate }}
  </header>

  @if (_iFrameURL$ | async; as iFrameURL) {
    <iframe
      [src]="iFrameURL | trustedResourceUrl"
      width="100%"
      height="500px"
      title="Home Iframe"
    ></iframe>
  }

  <div class="text-center">
    <button
      [routerLink]="_newsPageUrl"
      nxButton="primary"
      type="button"
      title="News Iframe"
    >
      {{ "tripdetailpage.0.ALLNEWS" | translate }}
    </button>
  </div>
</div>
