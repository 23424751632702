import { Injectable, inject, ComponentRef } from '@angular/core';

import { LoadingOverlayComponent } from '@agentmax/core/components/loading-overlay/loading-overlay.component';

import { DomService } from '@agentmax/shared/services/dom/dom.service';

@Injectable({
  providedIn: 'root',
})
export class LoadingOverlayService {
  private readonly _domService = inject(DomService);
  private _componentRef: ComponentRef<LoadingOverlayComponent> | null = null;

  public get isShown(): boolean {
    return this._componentRef !== null;
  }

  public show(): void {
    if (this.isShown) {
      return;
    }

    this._componentRef = this._domService.appendComponentToBody(LoadingOverlayComponent);
  }

  public hide(): void {
    if (!this.isShown) {
      return;
    }

    this._componentRef!.destroy();
    this._componentRef = null;
  }
}
