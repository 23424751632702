import { CurrentAccountDetailsType } from '@agentmax/shared/types/current-account-details.type';

export namespace CurrentAccountsDetails {
  export class Save {
    public static readonly type = '[CurrentAccountsDetails] Save';

    constructor(
      public readonly currentAccountsDetails: ReadonlyArray<CurrentAccountDetailsType>
    ) {}
  }

  export class Clear {
    public static readonly type = '[CurrentAccountsDetails] Clear';
  }

  export class Restore {
    public static readonly type = '[CurrentAccountsDetails] Restore';

    constructor(
      public readonly currentAccountsDetails: ReadonlyArray<CurrentAccountDetailsType>
    ) {}
  }
}
