import { ChangeDetectionStrategy, Component, ElementRef, ViewChild } from '@angular/core';
import { DataAccessorService } from '@agentmax/shared/services/data-accessor/data-accessor.service';
import { UserService } from '@agentmax/shared/services/user/user.service';
import { CountryCode } from 'src/app/shared/enums/country-code.enum';

@Component({
  selector: 'app-welcome-message',
  templateUrl: './welcome-message.component.html',
  styleUrls: ['./welcome-message.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WelcomeMessageComponent {
  protected readonly userInfo = this.userService.getCurrentUsersInfo();
  protected readonly accountDetails = this.dataAccessorService.getAccountDetails();
  protected readonly showWelcomeText =
    this.dataAccessorService.getUserCountryCode() !== CountryCode.CZECH;

  @ViewChild('clickTriggerIcon')
  private readonly _clickTriggerIcon!: ElementRef<HTMLElement>;

  constructor(
    private readonly userService: UserService,
    private readonly dataAccessorService: DataAccessorService,
  ) {}
}
