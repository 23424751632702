import { Injectable } from '@angular/core';

import { ShaService } from '../../../shared/services/sha/sha.service';

import { ResetPasswordFormValueType } from '../../../login/types/reset-password-form-value.type';
import { ResetPasswordDto } from '../../../login/dtos/reset-password.dto';
import { ResetPasswordForceFormValueType } from '../../../login/types/reset-password-force-form-value.type';
import { ResetPasswordForceDto } from '../../../login/dtos/reset-password-force.dto';

@Injectable({
  providedIn: 'root',
})
export class ResetPasswordMapperService {
  constructor(private readonly _shaService: ShaService) {}

  public fromFormValueAndUserEmailToDto(
    resetPasswordFormValue: ResetPasswordFormValueType,
    userEmail: string,
  ): ResetPasswordDto {
    const { newPassword, securityQuestion, answer } = resetPasswordFormValue;
    return {
      password: this._shaService.toSHA256(newPassword),
      questionAnswer: {
        question: securityQuestion,
        answer: this._shaService.toSHA256(answer),
      },
      userId: userEmail,
    };
  }
  public fromFormValueToDto(
    resetPasswordForceFormValueType: ResetPasswordForceFormValueType,
    userEmail: string,
  ): ResetPasswordForceDto {
    const { oldPassword, newPassword } = resetPasswordForceFormValueType;
    return {
      changePassword: {
        isForceChange: true,
        oldPassword: this._shaService.toSHA256(oldPassword),
        newPassword: this._shaService.toSHA256(newPassword),
      },
      questions: [],
      userId: userEmail,
    };
  }
}
