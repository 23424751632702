import { Injectable, inject } from '@angular/core';
import {
  Action,
  NgxsOnInit,
  State,
  StateContext,
  StateToken,
} from '@ngxs/store';

import { CurrentAccountsDetailsSessionStorageService } from '@agentmax/shared/session-storage/current-accounts-details-session-storage.service';

import { CurrentAccountDetailsType } from '@agentmax/shared/types/current-account-details.type';
import { CurrentAccountsDetails } from './current-accounts-details.actions';

export type CurrentAccountsDetailsStateModel =
  ReadonlyArray<CurrentAccountDetailsType>;

export const CURRENT_ACCOUNTS_DETAILS_STATE =
  new StateToken<CurrentAccountsDetailsStateModel>(
    'CURRENT_ACCOUNTS_DETAILS_STATE'
  );

@State({
  name: CURRENT_ACCOUNTS_DETAILS_STATE,
  defaults: [],
})
@Injectable({
  providedIn: 'root',
})
export class CurrentAccountsDetailsState implements NgxsOnInit {
  private readonly _currentAccountsDetails = inject(
    CurrentAccountsDetailsSessionStorageService
  );

  public ngxsOnInit(
    context: StateContext<CurrentAccountsDetailsStateModel>
  ): void {
    const currentAccountsDetails =
      this._currentAccountsDetails.getOrReturnNull();

    if (currentAccountsDetails === null) {
      return;
    }

    context.dispatch(
      new CurrentAccountsDetails.Restore(currentAccountsDetails)
    );
  }

  @Action(CurrentAccountsDetails.Save)
  public save(
    context: StateContext<CurrentAccountsDetailsStateModel>,
    { currentAccountsDetails }: CurrentAccountsDetails.Save
  ): void {
    this._currentAccountsDetails.set(currentAccountsDetails);
    context.setState(currentAccountsDetails);
  }

  @Action(CurrentAccountsDetails.Clear)
  public clear(context: StateContext<CurrentAccountsDetailsStateModel>): void {
    this._currentAccountsDetails.remove();
    context.setState([]);
  }

  @Action(CurrentAccountsDetails.Restore)
  public restore(
    context: StateContext<CurrentAccountsDetailsStateModel>,
    { currentAccountsDetails }: CurrentAccountsDetails.Restore
  ): void {
    context.setState(currentAccountsDetails);
  }
}
