<div class="login-page-view-container">
  <ng-container [ngSwitch]="currentLoginPageView$ | async">
    <app-login-page-card
      *ngSwitchCase="LoginPageView.LOGIN"
      [hideCloseButton]="true"
    >
      <app-login-form
        [successLogoutMessageIsShown]="_successLogoutMessageService.isShown"
        [errorSwitchAccountMessageIsShown]="_errorSwitchAccountMessageeService.isShown"
        (resolve)="handleLoginFormResolve($event)"
        (forgottenPasswordClick)="
          _loginPageViewService.switchToForgotPasswordView()
        "
      ></app-login-form>
    </app-login-page-card>

    <app-login-page-card
      *ngSwitchCase="LoginPageView.FORGOT_PASSWORD"
      (closeButtonClick)="_loginPageViewService.switchToLoginView()"
    >
      <app-forgot-password-form
        (resolve)="handleForgotPasswordFormResolve($event)"
      ></app-forgot-password-form>
    </app-login-page-card>

    <app-login-page-card
      *ngSwitchCase="LoginPageView.RESET_PASSWORD"
      (closeButtonClick)="_loginPageViewService.switchToForgotPasswordView()"
    >
      <app-reset-password-form
        *ngIf="_forgotPasswordResolveData"
        [securityQuestions]="_forgotPasswordResolveData.questions"
        [userEmail]="_forgotPasswordResolveData.userEmail"
        (resolve)="handleResetPasswordResolve($event)"
      ></app-reset-password-form>
    </app-login-page-card>

    <app-login-page-card
      *ngSwitchCase="LoginPageView.PASSWORD_SUCCESSFULLY_RESET"
      (closeButtonClick)="_loginPageViewService.switchToLoginView()"
    >
      <app-create-password-success-view
        [messageText]="resetPasswordSuccessMsg"
        (continueClick)="_loginPageViewService.switchToLoginView()"
      >
      </app-create-password-success-view>
    </app-login-page-card>

    <app-login-page-card
      *ngSwitchCase="LoginPageView.CREATE_PASSWORD"
      (closeButtonClick)="_loginPageViewService.switchToLoginView()"
    >
      <app-create-password-form
        *ngIf="_loginFormResolveData"
        [userEmail]="_loginFormResolveData.email"
        (resolve)="handleCreatePasswordResolve($event)"
      ></app-create-password-form>
    </app-login-page-card>

    <app-login-page-card
      *ngSwitchCase="LoginPageView.PASSWORD_SUCCESSFULLY_CREATED"
      (closeButtonClick)="_loginPageViewService.switchToLoginView()"
    >
      <app-create-password-success-view
        [messageText]="createPasswordSuccessMsg"
        (continueClick)="_loginPageViewService.switchToLoginView()"
      >
      </app-create-password-success-view>
    </app-login-page-card>

    <app-login-page-card
      *ngSwitchCase="LoginPageView.FORCE_PASSWORD_RESET"
      (closeButtonClick)="_loginPageViewService.switchToLoginView()"
    >
      <app-reset-password-force-form
        *ngIf="_loginFormResolveData"
        [userEmail]="_loginFormResolveData.email"
        (resolve)="handleResetPasswordResolve($event)"
      >
      </app-reset-password-force-form>
    </app-login-page-card>
  </ng-container>
</div>
