import { Injectable, inject } from '@angular/core';

import { SessionStorageService } from '@agentmax/shared/services/session-storage/session-storage.service';

import { IBaseSessionStorageService } from './base-session-storage.service.interface';
import { SessionStorageKey } from '@agentmax/shared/enums/session-storage-key.enum';

@Injectable()
export abstract class BaseSessionStorageService<T = unknown>
  implements IBaseSessionStorageService<T>
{
  private readonly _sessionStorageService = inject(SessionStorageService);

  protected abstract get key(): SessionStorageKey;

  public get(): T {
    return this._sessionStorageService.getParsedValue<T>(this.key);
  }

  public getOrReturnNull(): T | null {
    return this._sessionStorageService.getParsedValueOrNull<T>(this.key);
  }

  public set(value: T): void {
    this._sessionStorageService.setRawValue(this.key, value);
  }

  public remove(): void {
    this._sessionStorageService.removeValue(this.key);
  }
}
