import { ClassProvider } from '@angular/core';

import { NAV_LINKS_FILTERS } from './nav-links-filters.injection-token';
import { HeadLinkPermissionFilter } from './head-link-permission.filter';
import { HeadReportLinkPermissionFilter } from './head-report-link-permission.filter';
import { QuoteAdminConfigurationFilter } from './quote-admin-configuration.filter';

export const NAV_LINKS_FILTERS_PROVIDERS: ReadonlyArray<ClassProvider> = [
  {
    provide: NAV_LINKS_FILTERS,
    useClass: HeadLinkPermissionFilter,
    multi: true,
  },
  {
    provide: NAV_LINKS_FILTERS,
    useClass: HeadReportLinkPermissionFilter,
    multi: true,
  },
  {
    provide: NAV_LINKS_FILTERS,
    useClass: QuoteAdminConfigurationFilter,
    multi: true,
  },
];
