//TODO: If some type is needed outside of this Quotation context (SaveOffer feature) we will need to move it into a separate file

export type Quotation = Readonly<{
  productType: ProductType;
  voyage: Voyage;
  tripBookingDate: string;
  retrieveNonPurchasableOffer: boolean;
  policyOffering: PolicyOffering;
  contractFilter: ContractFilter[];
}>;

type ContractFilter = Readonly<{
  customFieldType: string;
  customFieldCode: string;
}>;

type PolicyOffering = Readonly<{
  reductions: Reduction[];
  agent: Agent;
  salesChannel: string;
  partnerShipCode: string;
  subscriptionCountry: string;
  purchaseDate: string;
  currency: string;
  language: string;
  agencyId: string;
}>;

type Agent = Readonly<{
  agentNumber: string;
}>;

type Reduction = Readonly<{
  code: string;
}>;

type ProductType = Readonly<{
  Comprehensive: boolean;
  Cancellation: boolean;
  Health: boolean;
  Other: boolean;
  student: boolean;
  visa: boolean;
}>;

type Voyage = Readonly<{
  departure: Departure;
  voyageType: string;
  departureDate: string;
  destination: Destination[];
  travelersCategories: TravelersCategory[];
  policyDate: string;
  arrivalDate: string;
  tripPrice: string;
  tripPriceGroupError: boolean;
  travelReason: string;
  voyageCategory: string;
  residenceCountry: string;
}>;

type Departure = Readonly<{
  country: string;
}>;

type Destination = Readonly<{
  country: string;
  area: string;
  description: string;
}>;

type TravelersCategory = TravelersWithDOBCategory | TravelersWithAgeCategory;

type TravelersWithDOBCategory = Readonly<{
  dateOfBirth: string;
  numberOfTravellers: number;
  pricePerCategory: number;
}>;

type TravelersWithAgeCategory = Readonly<{
  age: string;
  numberOfTravellers: number;
  pricePerCategory: number;
}>;

export const isDOBTravelerCategory = (
  value: TravelersCategory,
): value is TravelersWithDOBCategory => {
  return value.hasOwnProperty('dateOfBirth');
};
