<app-change-language-helper></app-change-language-helper>
<form [formGroup]="searchOfferForm">
  <nx-accordion>
    <nx-expansion-panel>
      <nx-expansion-panel-header class="quote-admin-collapsible">
        <nx-expansion-panel-title
          class="quote-admin-collapsible-title"
          >{{ 'quoteAdminPage.0.SEARCHFORQUOTES' | translate }}</nx-expansion-panel-title
        >
      </nx-expansion-panel-header>
      <div nxLayout="grid">
        <div nxRow>
          <div nxCol="4">
            <nx-formfield
              appearance="outline"
              [label]="'quoteAdminPage.0.QUOTEID' | translate"
              floatLabel="always"
            >
              <input
                nxInput
                type="text"
                placeholder="{{ 'quoteAdminPage.0.QUOTEID' | translate }}"
                class="quote-admin-label"
                formControlName="quoteId"
              />
            </nx-formfield>
          </div>
          <div nxCol="4">
            <nx-formfield
              appearance="outline"
              [label]="'quoteAdminPage.0.FIRSTNAME' | translate"
              floatLabel="always"
            >
              <input
                nxInput
                type="text"
                placeholder="{{ 'quoteAdminPage.0.FIRSTNAME' | translate }}"
                class="quote-admin-label"
                formControlName="customerName"
              />
            </nx-formfield>
          </div>
          <div nxCol="4">
            <nx-formfield
              appearance="outline"
              [label]="'quoteAdminPage.0.LASTNAME' | translate"
              floatLabel="always"
            >
              <input
                nxInput
                type="text"
                placeholder="{{ 'quoteAdminPage.0.LASTNAME' | translate }}"
                class="quote-admin-label"
                formControlName="customerSurname"
              />
            </nx-formfield>
          </div>
        </div>
        <div nxRow>
          <div nxCol="4">
            <nx-formfield
              [label]="'quoteAdminPage.0.TRIPSTARTDATE' | translate"
              appearance="outline"
              floatLabel="always"
            >
              <input
                nxDatefield
                nxInput
                [datepicker]="myDatepickerTripStartDate"
                placeholder="DD/MM/YYYY"
                class="quote-admin-label"
                formControlName="tripStartDate"
              />
              <nx-datepicker-toggle
                [for]="myDatepickerTripStartDate"
                nxFormfieldSuffix
              ></nx-datepicker-toggle>
              <nx-datepicker #myDatepickerTripStartDate></nx-datepicker>
            </nx-formfield>
          </div>
          <div nxCol="4">
            <nx-formfield
              [label]="'quoteAdminPage.0.TRIPENDDATE' | translate"
              appearance="outline"
              floatLabel="always"
            >
              <input
                nxDatefield
                nxInput
                [datepicker]="myDatepickerTripEndDate"
                placeholder="DD/MM/YYYY"
                class="quote-admin-label"
                formControlName="tripEndDate"
              />
              <nx-datepicker-toggle
                [for]="myDatepickerTripEndDate"
                nxFormfieldSuffix
              ></nx-datepicker-toggle>
              <nx-datepicker #myDatepickerTripEndDate></nx-datepicker>
            </nx-formfield>
          </div>
          <div nxCol="4">
            <nx-formfield
              appearance="outline"
              [label]="'quoteAdminPage.0.EMAIL' | translate"
              floatLabel="always"
            >
              <input
                nxInput
                type="text"
                placeholder="{{ 'quoteAdminPage.0.EMAIL' | translate }}"
                class="quote-admin-label"
                formControlName="contactEmail"
              />
            </nx-formfield>
          </div>
        </div>
        <div nxRow>
          <div nxCol="8">
            <nx-formfield
              [label]="'quoteAdminPage.0.QUOTATIONDATE' | translate"
              appearance="outline"
              floatLabel="always"
            >
              <input
                nxDatefield
                nxInput
                [datepicker]="myDatepickerQuotationDate"
                placeholder="DD/MM/YYYY"
                class="quote-admin-label"
                formControlName="createdDate"
              />
              <nx-datepicker-toggle
                [for]="myDatepickerQuotationDate"
                nxFormfieldSuffix
              ></nx-datepicker-toggle>
              <nx-datepicker #myDatepickerQuotationDate></nx-datepicker>
            </nx-formfield>
          </div>
          <div nxCol="4">
            <nx-formfield
              appearance="outline"
              [label]="'quoteAdminPage.0.BUSINESSPARTNERID' | translate"
              floatLabel="always"
            >
              <input
                nxInput
                type="text"
                placeholder="{{ 'quoteAdminPage.0.BUSINESSPARTNERID' | translate }}"
                class="quote-admin-label"
                formControlName="businessPartnerID"
              />
            </nx-formfield>
          </div>
        </div>
        <div nxRow>
          <div
            nxCol="2"
            style="margin-left: 82% !important;"
          >
            <button
              nxButton="primary"
              type="button"
              (click)="searchQuote()"
            >
              Search
            </button>
          </div>
        </div>
      </div>
    </nx-expansion-panel>
  </nx-accordion>
</form>

<nx-message
  context="error"
  *ngIf="quoteValidationMessages.length > 0"
  class="nx-margin-bottom-xs"
>
  <nx-label
    size="small"
    *ngFor="let message of quoteValidationMessages"
    >{{message | translate}}</nx-label
  >
</nx-message>

<div
  class="scroll-container"
  *ngIf="quoteDetailsList.length > 0"
>
  <table
    nxTable
    condensed
    nxSort
    (sortChange)="sortTable($event)"
    aria-describedby="Quote Offer Search Results"
  >
    <thead>
      <tr nxTableRow>
        <th
          nxHeaderCell
          scope="col"
        >
          {{ 'quoteAdminPage.0.QUOTEID' | translate }}
        </th>
        <th
          nxHeaderCell
          nxSortHeaderCell="status"
          scope="col"
        >
          {{ 'quoteAdminPage.0.STATUS' | translate }}
        </th>
        <th
          nxHeaderCell
          scope="col"
        >
          {{ 'quoteAdminPage.0.LASTNAME' | translate }}
        </th>
        <th
          nxHeaderCell
          scope="col"
        >
          {{ 'quoteAdminPage.0.FIRSTNAME' | translate }}
        </th>
        <th
          nxHeaderCell
          nxSortHeaderCell="createdDate"
          scope="col"
        >
          {{ 'quoteAdminPage.0.SAVEDON' | translate }}
        </th>
        <th
          nxHeaderCell
          scope="col"
        >
          {{ 'quoteAdminPage.0.STARTORENDDATE' | translate }}
        </th>
        <th
          nxHeaderCell
          scope="col"
        >
          {{ 'quoteAdminPage.0.PREMIUM' | translate }}
        </th>
        <th
          nxHeaderCell
          scope="col"
        >
          {{ 'quoteAdminPage.0.PRODUCT' | translate }}
        </th>
        <th
          nxHeaderCell
          scope="col"
        >
          {{ 'quoteAdminPage.0.TYPE' | translate }}
        </th>
        <th
          nxHeaderCell
          scope="col"
        >
          {{ 'quoteAdminPage.0.SAVEDBY' | translate }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        nxTableRow
        *ngFor="let item of currentlyShownPageElements; index as i; trackBy: trackByFn"
      >
        <td nxTableCell>
          <nx-link
            ><a
              (click)="viewOffer(item.quoteId)"
              style="color: #337ab7 !important;"
              >{{item.quoteId}}</a
            ></nx-link
          >
        </td>
        <td nxTableCell>
          <nx-badge [type]="item.statusType">{{item.status}}</nx-badge>
        </td>
        <td nxTableCell>{{item.customerSurname}}</td>
        <td nxTableCell>{{item.customerName}}</td>
        <td nxTableCell>{{item.createdDate | date: DISPLAY_DATE_FORMAT }}</td>
        <td nxTableCell>
          {{ item.tripStartDate | date: DISPLAY_DATE_FORMAT }}
        </td>
        <td nxTableCell>{{item.premium}}</td>
        <td nxTableCell>{{item.product}}</td>
        <td nxTableCell>{{item.type}}</td>
        <td nxTableCell>{{item.savedBy}}</td>
      </tr>
    </tbody>
  </table>
</div>

<nx-pagination
  class="pagination"
  [count]="quoteDetailsList.length"
  [page]="page"
  type="advanced"
  [perPage]="elementsPerPage"
  (goPrev)="prevPage()"
  (goNext)="nextPage()"
  (goPage)="goToPage($event)"
>
</nx-pagination>
