import { InjectionToken, ValueProvider, ClassProvider } from '@angular/core';
import { LOCATION as NEW_LOCATION } from '@agentmax/global/location';

import {
  MockLocation,
  MockLocationAgentmax,
  MockLocationGlobalAssistance,
  MockLocationMondial,
  MockLocationPartners,
  MockLocationTravel,
} from '../../testing/data/location.mock';

/**
 * @deprecated Use {@link NEW_LOCATION} instead.
 */
export const LOCATION = new InjectionToken<Location>('LOCATION');

/**
 * @deprecated We can directly use {@link NEW_LOCATION} now instead.
 */
export const LOCATION_PROVIDER: ValueProvider = {
  provide: LOCATION,
  useValue: location,
};

/**
 * @deprecated We can use {@link NEW_LOCATION} and use any mock class we want instead.
 */
export const MOCK_LOCATION_PROVIDER: ClassProvider = {
  provide: LOCATION,
  useClass: MockLocation,
};

/**
 * @deprecated We can use {@link NEW_LOCATION} and use any mock class we want instead.
 */
export const MOCK_PARTNERS_LOCATION_PROVIDER: ClassProvider = {
  provide: LOCATION,
  useClass: MockLocationPartners,
};

/**
 * @deprecated We can use {@link NEW_LOCATION} and use any mock class we want instead.
 */
export const MOCK_MONDIAL_LOCATION_PROVIDER: ClassProvider = {
  provide: LOCATION,
  useClass: MockLocationMondial,
};

/**
 * @deprecated We can use {@link NEW_LOCATION} and use any mock class we want instead.
 */
export const MOCK_GLOBAL_LOCATION_PROVIDER: ClassProvider = {
  provide: LOCATION,
  useClass: MockLocationGlobalAssistance,
};

/**
 * @deprecated We can use {@link NEW_LOCATION} and use any mock class we want instead.
 */
export const MOCK_TRAVEL_LOCATION_PROVIDER: ClassProvider = {
  provide: LOCATION,
  useClass: MockLocationTravel,
};

/**
 * @deprecated We can use {@link NEW_LOCATION} and use any mock class we want instead.
 */
export const MOCK_AGENTMAX_LOCATION_PROVIDER: ClassProvider = {
  provide: LOCATION,
  useClass: MockLocationAgentmax,
};
