import { Injectable } from '@angular/core';

import { BaseSessionStorageService } from './base-session-storage.service';
import { CurrentAccountDetailsType } from '@agentmax/shared/types/current-account-details.type';
import { SessionStorageKey } from '@agentmax/shared/enums/session-storage-key.enum';

@Injectable({
  providedIn: 'root',
})
export class CurrentAccountsDetailsSessionStorageService extends BaseSessionStorageService<
  ReadonlyArray<CurrentAccountDetailsType>
> {
  public readonly key = SessionStorageKey.CURRENT_ACCOUNTS_DETAILS;
}
