/**
 * @deprecated Use {@link NewLanguageCode} instead.
 */
export enum LanguageCode {
  ENGLISH = 'en',
  CZECH = 'cz',
  GERMAN = 'de',
  ITALIAN = 'it',
  FRENCH = 'fr',
  SWISS_GERMAN = 'ch-de',
  SWISS_ITALIAN = 'ch-it',
  SWISS_FRENCH = 'ch-fr',
  SWISS_ENGLISH = 'ch-en',
  SWISS = 'ch',
}

/**
 * @description This enum represents all available language codes in AgentMax.
 * The values here must be aligned with the translations files we have.
 */
export enum NewLanguageCode {
  ENGLISH = 'en',
  AUSTRIAN = 'at',
  BULGARIAN = 'bg',
  ROMANIAN = 'ro',
  HUNGARIAN = 'hu',
  SPANISH = 'es',
  CZECH = 'cz',
  GERMAN = 'de',
  ITALIAN = 'it',
  FRENCH = 'fr',
  SWEDISH_ENGLISH = 'ch-en',
  SWEDISH_ITALIAN = 'ch-it',
  SWEDISH_GERMAN = 'ch-de',
  SWEDISH_FRENCH = 'ch-fr',
}

export const isLanguageCode = (value: string): value is NewLanguageCode => {
  return Object.values<string>(NewLanguageCode).includes(value);
};
