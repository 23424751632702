<div class="reset-password-form">
  <form
    class="form"
    [formGroup]="_resetPasswordForm"
    (ngSubmit)="handleResetPasswordFormSubmit()"
  >
    <nx-formfield
      class="form__item"
      [label]="'passchangepage.0.NPASS' | translate"
      appearance="outline"
    >
      <span nxFormfieldPrefix>
        <nx-icon name="product-lock"></nx-icon>
      </span>

      <input
        [placeholder]="'passchangepage.0.NPASS' | translate"
        nxInput
        type="password"
        formControlName="newPassword"
      />

      <!-- This trick is needed to inject rendered html into the tooltip -->
      <div
        #renderedTooltipMessage
        class="rendered-tooltip-message"
        [innerHTML]="'passchangepage.0.REQDPASS' | translate"
      ></div>

      <app-info-icon
        class="password-requirements-info"
        [nxTooltip]="renderedTooltipMessage.textContent || ''"
        nxTooltipPosition="top"
        nxFormfieldSuffix
      ></app-info-icon>

      <nx-error
        *ngIf="
          _resetPasswordForm.controls.newPassword.errors
            | hasError : 'passwordFormat'
        "
        appearance="text"
        nxFormfieldError
      >
        <span>{{ "passchangepage.0.PASSMATCHWITHCRITERIA" | translate }}</span>
      </nx-error>
    </nx-formfield>

    <nx-formfield
      class="form__item"
      [label]="'passchangepage.0.CONFPASS' | translate"
      appearance="outline"
    >
      <span nxFormfieldPrefix>
        <nx-icon name="product-lock"></nx-icon>
      </span>

      <input
        [placeholder]="'passchangepage.0.CONFPASS' | translate"
        nxInput
        type="password"
        formControlName="confirmPassword"
      />

      <nx-error
        *ngIf="
          _resetPasswordForm.controls.confirmPassword.errors
            | hasError : 'controlsMatch'
        "
        appearance="text"
        nxFormfieldError
      >
        <span>{{ "passchangepage.0.PASSMISMATCH" | translate }}</span>
      </nx-error>
    </nx-formfield>

    <nx-formfield
      class="form__item"
      [label]="'passchangepage.0.SECURITYQUESTION' | translate"
      appearance="outline"
    >
      <nx-dropdown
        [placeholder]="'passchangepage.0.SECURITYQUESTION' | translate"
        formControlName="securityQuestion"
      >
        <nx-dropdown-item
          *ngFor="let question of _securityQuestions"
          [value]="question.question"
        >
          {{
            "passchangepage.0." + question.question | translate
          }}</nx-dropdown-item
        >
      </nx-dropdown>
    </nx-formfield>

    <nx-formfield
      class="form__item"
      [label]="'passchangepage.0.ANSWER' | translate"
      appearance="outline"
    >
      <input
        [placeholder]="'passchangepage.0.ANSWER' | translate"
        nxInput
        type="text"
        formControlName="answer"
      />
    </nx-formfield>

    <button
      class="form__item login-button"
      [disabled]="_resetPasswordForm.invalid || _onePortalMessageCode !== null"
      nxButton="primary"
      type="submit"
    >
      <span>{{ "passchangepage.0.CHANGEPASS" | translate }}</span>
    </button>
  </form>

  <nx-error
    class="backend-error-container"
    *ngIf="_onePortalMessageCode"
    appearance="message"
  >
    <span>{{ "passchangepage.0." + _onePortalMessageCode | translate }}</span>
  </nx-error>
</div>
