import { Component, ChangeDetectionStrategy } from '@angular/core';
import { NxModalRef } from '@aposin/ng-aquila/modal';
import { UserIdleService } from 'angular-user-idle';

import { LogoutService } from '../../services/logout/logout.service';

@Component({
  selector: 'app-session-expires-soon-dialog',
  templateUrl: './session-expires-soon-dialog.component.html',
  styleUrls: ['./session-expires-soon-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SessionExpiresSoonDialogComponent {
  constructor(
    private readonly _dialogRef: NxModalRef<unknown>,
    private readonly _userIdleService: UserIdleService,
    private readonly _logoutService: LogoutService,
  ) {}

  public handleLogoutButtonClick(): void {
    this._dialogRef.close();
    this._logoutService.logoutAndCloseApp().subscribe();
  }

  public handleContinueButtonClick(): void {
    this._userIdleService.stopTimer();
    this._dialogRef.close();
  }
}
