import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from '@angular/core';
import { NonNullableFormBuilder, Validators } from '@angular/forms';
import { takeUntil, tap } from 'rxjs';

import { LoginMapperService } from '../../../core/mappers/login/login-mapper.service';
import { LoginHttpService } from '../../../core/http/login/login-http.service';
import { ErrorService } from '../../../shared/services/error/error.service';

import { DestroyEmitter } from '@agentmax/shared/classes/destroy-emitter.class';
import { LoginResponseData } from '@agentmax/shared/types/login-response-data.type';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginFormComponent extends DestroyEmitter implements OnInit {
  @Input()
  public set successLogoutMessageIsShown(value: boolean) {
    this._successLogoutMessageIsShown = value;
  }

  @Input()
  public set errorSwitchAccountMessageIsShown(value: boolean) {
    this._errorSwitchAccountMessageIsShown = value;
  }

  @Output()
  public readonly forgottenPasswordClick = new EventEmitter<void>();

  @Output()
  public readonly resolve = new EventEmitter<LoginResponseData>();

  public readonly _loginForm = this._formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required]],
  });

  public set onePortalMessageCode(value: string | null) {
    this._onePortalMessageCode = value;
  }

  public get onePortalMessageCode(): string | null {
    return this._onePortalMessageCode;
  }

  protected _successLogoutMessageIsShown = false;
  protected _errorSwitchAccountMessageIsShown = false;

  private _onePortalMessageCode: string | null = null;

  constructor(
    private readonly _formBuilder: NonNullableFormBuilder,
    private readonly _loginMapperService: LoginMapperService,
    private readonly _loginHttpService: LoginHttpService,
    private readonly _errorService: ErrorService,
    private readonly _cdRef: ChangeDetectorRef,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.subToLoginFormValueChanges();
  }

  public handleLoginFormSubmit(): void {
    if (this._loginForm.invalid) {
      this._errorService.throwError(this.constructor.name, 'The login form must be valid!');
    }

    const loginFormValue = this._loginForm.getRawValue();
    const loginDto = this._loginMapperService.fromFormValueToDto(loginFormValue);
    this._loginHttpService
      .login(loginDto)
      .pipe(
        tap((data) => {
          const { newUser, error, error_description } = data;

          if (!newUser && error !== null) {
            this._onePortalMessageCode = data.onePortalMessage_Code;
            this._cdRef.markForCheck();
            throw new Error(`[${error}] ${error_description || 'No description'}`);
          }

          this.resolve.emit(data);
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private subToLoginFormValueChanges(): void {
    this._loginForm.valueChanges
      .pipe(
        tap(() => {
          if (this._onePortalMessageCode !== null) {
            this._onePortalMessageCode = null;
          }
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }
}
