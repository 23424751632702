import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';

import { DataAccessorService } from '@agentmax/shared/services/data-accessor/data-accessor.service';
import { ACCESS_TOKEN_HTTP_PARAM_KEY } from '@agentmax/shared/dependencies/access-token-http-param-key';

import { TO_AUTHORIZE } from '@agentmax/core/http/contexts/to-authorize';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationParamsInterceptor implements HttpInterceptor {
  private readonly _dataAccessorService = inject(DataAccessorService);
  private readonly _accessTokenHttpParamKey = inject(ACCESS_TOKEN_HTTP_PARAM_KEY);

  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!req.context.get(TO_AUTHORIZE)) {
      return next.handle(req);
    }

    return next.handle(
      req.clone({
        setParams: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          [this._accessTokenHttpParamKey]: this._dataAccessorService.getAccessToken(),
        },
      }),
    );
  }
}
