import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-login-page-card',
  templateUrl: './login-page-card.component.html',
  styleUrls: ['./login-page-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginPageCardComponent {
  @Input()
  public set hideCloseButton(value: boolean) {
    this._hideCloseButton = value;
  }

  @Output()
  public readonly closeButtonClick = new EventEmitter<void>();

  protected _hideCloseButton = false;
}
