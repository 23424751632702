import { Injectable, inject } from '@angular/core';
import * as moment from 'moment';

import { MOMENT } from './moment.config';

@Injectable({
  providedIn: 'root',
})
export class MomentService {
  private readonly _moment = inject(MOMENT);

  public createMoment(input?: moment.MomentInput): moment.Moment {
    return this._moment(input);
  }

  public checkIsMoment(value: unknown): value is moment.Moment {
    return this._moment.isMoment(value);
  }
}
