import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-logout-icon',
  templateUrl: './logout-icon.component.html',
  styleUrls: ['./logout-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class LogoutIconComponent {}
