import { ChangeDetectionStrategy, Component } from '@angular/core';

import { LogoutService } from '../../services/logout/logout.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoutComponent {
  constructor(private readonly logoutService: LogoutService) {}

  protected handleLogoutButtonClick(): void {
    this.logoutService.logoutAndCloseApp().subscribe();
  }
}
