import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  ViewChild,
  inject,
  ElementRef,
  AfterViewInit,
  ChangeDetectorRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  NxDatefieldDirective,
  NxDatefieldModule,
  NxDatepickerComponent,
} from '@aposin/ng-aquila/datefield';
import { NxMomentDateModule } from '@aposin/ng-aquila/moment-date-adapter';
import { NxInputModule } from '@aposin/ng-aquila/input';
import { NxMaskDirective, NxMaskModule } from '@aposin/ng-aquila/mask';
import { Moment } from 'moment';

import { MomentService } from '@agentmax/shared/services/moment/moment.service';

import { DATE_FORMAT, DATE_MASK } from './date-control.config';

@Component({
  selector: 'app-date-control',
  templateUrl: './date-control.component.html',
  styleUrls: ['./date-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateControlComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NxDatefieldModule, NxMomentDateModule, NxInputModule, NxMaskModule],
})
export class DateControlComponent implements ControlValueAccessor, AfterViewInit {
  private readonly _momentService = inject(MomentService);
  private readonly _cdRef = inject(ChangeDetectorRef);
  protected readonly _dateFormat = inject(DATE_FORMAT);
  protected readonly _dateMask = inject(DATE_MASK);

  @Input()
  public set label(value: string) {
    this._label = value;
  }

  @Input()
  public set placeholder(value: string) {
    this._placeholder = value;
  }

  @Input()
  public set minDate(value: Moment | null) {
    this._minDate = value;
  }

  @Input()
  public set maxDate(value: Moment | null) {
    this._maxDate = value;
  }

  @ViewChild('inputRef', { static: true })
  private readonly _inputRef!: ElementRef<HTMLInputElement>;

  @ViewChild(NxDatefieldDirective, { static: true })
  private readonly _nxDatefield!: NxDatefieldDirective<Moment | null>;

  @ViewChild(NxMaskDirective, { static: true })
  private readonly _nxMask!: NxMaskDirective;

  @ViewChild(NxDatepickerComponent, { static: true })
  private readonly _nxDatepicker!: NxDatepickerComponent<Moment | null>;

  protected _label = '';
  protected _placeholder = '';
  protected _minDate: Moment | null = null;
  protected _maxDate: Moment | null = null;
  private _isInitialSet = true;
  private _initialValue: Moment | null = null;

  public writeValue(value: unknown): void {
    if (value !== null && !this._momentService.checkIsMoment(value)) {
      throw new Error('A Moment or null value is expected!');
    }

    if (this._isInitialSet) {
      this._isInitialSet = false;
      this._initialValue = value;
      return;
    }

    if (value === null) {
      this.writeNull();
    } else {
      this.writeDate(value);
    }

    this._cdRef.markForCheck();
  }

  public setDisabledState(isDisabled: boolean): void {}

  public ngAfterViewInit(): void {
    if (this._initialValue === null) {
      return;
    }

    this.writeDate(this._initialValue);
  }

  public getInputValue(): string {
    return this._inputRef.nativeElement.value;
  }

  public getDatepickerDate(): Moment | null {
    return this._nxDatepicker.selected;
  }

  public registerOnChange(fn: (value: Moment | null) => void): void {
    this._onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this._onTouch = fn;
  }

  protected handleDateChange(value: Moment | null): void {
    this._onChange(value);
    this._onTouch();
  }

  private writeNull(): void {
    this._nxDatefield.writeValue(null);
    this._nxMask.writeValue('');
  }

  private writeDate(date: Moment): void {
    this._nxDatefield.writeValue(date);
    this._nxMask.writeValue(date.format(this._dateFormat));
  }

  private _onChange = (value: Moment | null) => {};
  private _onTouch = () => {};
}
