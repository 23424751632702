/**
 * @description This enum represents all possible environment languages options.
 * Each environment should have configured options for all values from here.
 */
export enum LanguageOptionsName {
  ALL = 'all',
  AT = 'at',
  BG = 'bg',
  CH = 'ch',
  CZ = 'cz',
  DE = 'de',
  EN = 'en',
  ES = 'es',
  FR = 'fr',
  GB = 'gb',
  HU = 'hu',
  IT = 'it',
  RO = 'ro',
  UK = 'uk',
}

export const isLanguageOptionsName = (value: string): value is LanguageOptionsName => {
  return Object.values<string>(LanguageOptionsName).includes(value);
};
