import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';

import { LogoutService } from '../../../core/services/logout/logout.service';
import { UserService } from '../../../shared/services/user/user.service';
import { SuccessLogoutMessageService } from '../../services/success-logout-message.service';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginPageComponent implements OnInit, OnDestroy {
  constructor(
    private readonly _logoutService: LogoutService,
    private readonly _userService: UserService,
    private readonly _successfulLogoutMessageService: SuccessLogoutMessageService,
  ) {}

  public ngOnInit(): void {
    if (this._userService.isLoggedIn()) {
      this._logoutService.logoutAndCloseApp({ skipNavigationToLogin: true }).subscribe();
    }
  }

  public ngOnDestroy(): void {
    this._successfulLogoutMessageService.hide();
  }
}
