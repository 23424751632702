import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-create-password-success-view',
  templateUrl: './create-password-success.component.html',
  styleUrls: ['./create-password-success.component.scss'],
})
export class CreatePasswordSuccessComponent {
  @Output()
  public readonly continueClick = new EventEmitter<void>();
  @Input()
  public messageText = '';
}
