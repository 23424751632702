import { Injectable } from '@angular/core';

import { NewLanguageCode, isLanguageCode } from '@agentmax/shared/enums/language-code.enum';
import {
  LanguageOptionsName,
  isLanguageOptionsName,
} from '@agentmax/shared/enums/language-options-name.enum';

@Injectable({
  providedIn: 'root',
})
export class BuLanguageService {
  public getDefaultLanguageCode(
    rawDefaultLanguageCode: string,
    rawCountryCode: string,
  ): NewLanguageCode {
    if (isLanguageCode(rawDefaultLanguageCode)) {
      return rawDefaultLanguageCode;
    }

    const countryBasedLanguageCode = rawCountryCode.toLowerCase();

    if (isLanguageCode(countryBasedLanguageCode)) {
      return countryBasedLanguageCode;
    }

    return NewLanguageCode.ENGLISH;
  }

  public getLanguageOptionsName(rawCountryCode: string): LanguageOptionsName {
    const countryBasedLanguageOptionsName = rawCountryCode.toLowerCase();

    if (isLanguageOptionsName(countryBasedLanguageOptionsName)) {
      return countryBasedLanguageOptionsName;
    }

    throw new Error(`Unknown language options name: '${countryBasedLanguageOptionsName}'!`);
  }
}
