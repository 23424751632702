import { Injectable } from "@angular/core";
import { SessionStorageKey } from "../enums/session-storage-key.enum";
import { CurrentUserType } from "../types/current-user-type.type";
import { BaseSessionStorageService } from "./base-session-storage.service";

@Injectable({
    providedIn:'root'
})
export class CurrentUserSessionStorageService extends BaseSessionStorageService<CurrentUserType> {
    public readonly key = SessionStorageKey.NEW_CURRENT_USER;
}