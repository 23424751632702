import { Injectable, InjectionToken, inject } from '@angular/core';

import { BuLanguageService } from './bu-language.service';

import { NewLanguageCode } from '@agentmax/shared/enums/language-code.enum';

export const SWEDISH_LANGUAGE_CODES = new InjectionToken<ReadonlyArray<string>>(
  'SWEDISH_LANGUAGE_CODES',
  {
    providedIn: 'root',
    factory: () => [
      NewLanguageCode.SWEDISH_ENGLISH,
      NewLanguageCode.SWEDISH_FRENCH,
      NewLanguageCode.SWEDISH_GERMAN,
      NewLanguageCode.SWEDISH_ITALIAN,
    ],
  },
);

@Injectable({
  providedIn: 'root',
})
export class CHLanguageService extends BuLanguageService {
  private readonly _swedishLanguageCodes = inject(SWEDISH_LANGUAGE_CODES);

  public override getDefaultLanguageCode(
    rawDefaultLanguageCode: string,
    _rawCountryCode = '',
  ): NewLanguageCode {
    if (this._swedishLanguageCodes.includes(rawDefaultLanguageCode)) {
      return rawDefaultLanguageCode as NewLanguageCode;
    }

    return NewLanguageCode.SWEDISH_ENGLISH;
  }
}
