<div class="login-page-card">
  <button
    class="close-button"
    *ngIf="!_hideCloseButton"
    type="button"
    (click)="closeButtonClick.emit()"
  >
    <nx-icon
      name="product-cross"
      size="m"
    ></nx-icon>
  </button>

  <div class="card-content">
    <ng-content></ng-content>
  </div>
</div>
