import { Inject, Injectable } from '@angular/core';

import { DataAccessorService } from '../../../shared/services/data-accessor/data-accessor.service';

import { NAV_LINKS_FILTERS } from '../nav-links/nav-links-filters.injection-token';
import { NavLinksFilterList } from '../nav-links/nav-links-filter.interface';
import { NavLinkListType, NavLinkType } from '../../../shared/types/nav-links.type';
import { AppUrl } from '../../../shared/enums/app-url.enum';
import { NavLinkId } from '../../../shared/enums/nav-link-id.enum';

const STATIC_NAV_LINKS: NavLinkListType = [
  {
    id: NavLinkId.SEARCH_POLICY,
    url: `/${AppUrl.SEARCH_POLICY}`,
    text: 'navbar.0.POLADMIN',
  },
  {
    id: NavLinkId.SEARCH_OFFER,
    url: `/${AppUrl.SEARCH_OFFER}`,
    text: 'navbar.0.QUOTEADMIN',
  },
  {
    id: NavLinkId.REPORTS,
    url: `/${AppUrl.REPORTS}`,
    text: 'navbar.0.REPORTS',
  },
  {
    id: NavLinkId.NEWS,
    url: `/${AppUrl.NEWS}`,
    text: 'navbar.0.NEWSRES',
  },
  {
    id: NavLinkId.HELP,
    url: `/${AppUrl.HELP}`,
    text: 'navbar.0.HELP',
  },
  {
    id: NavLinkId.SETTINGS,
    url: `/${AppUrl.SETTING}`,
    text: 'navbar.0.SETTINGS',
  },
];

@Injectable({
  providedIn: 'root',
})
export class NavHeaderService {
  constructor(
    @Inject(NAV_LINKS_FILTERS)
    private readonly _navLinksFilters: NavLinksFilterList,
    private readonly _dataAccessorService: DataAccessorService,
  ) {}

  public generateNavLinks(): NavLinkListType {
    return this._navLinksFilters.reduce(
      (resultNavLinks, nextFilter) => nextFilter.filter(resultNavLinks),
      [this.generateHomeNavLink(), ...STATIC_NAV_LINKS] as NavLinkListType,
    );
  }

  public generateHomeUrl(): string {
    return `/${AppUrl.HOME}/${this._dataAccessorService.getUserCountryCode()}`;
  }

  private generateHomeNavLink(): NavLinkType {
    return {
      id: NavLinkId.HOME,
      url: this.generateHomeUrl(),
      text: 'navbar.0.HOME',
    };
  }
}
