import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';

import { AppUrl } from '../../../shared/enums/app-url.enum';
import { UserService } from '../../../shared/services/user/user.service';
import { NavHeaderService } from '../nav-header/nav-header.service';

@Injectable({
  providedIn: 'root',
})
// TODO: We can either use this service as the only dependant on router
// and make all routing related things through it (e.g. `navigateToLogin` method)
// OR
// we can make a lightweight dependency which will be of type Pick<Router, 'navigate'>
// so that we do not depend on the whole `Router`, but only on it single `navigate` method.
export class NavigationService {
  private readonly _userService = inject(UserService);
  private readonly _router = inject(Router);
  private readonly _navHeaderService = inject(NavHeaderService);

  public async navigateTo(path: string): Promise<boolean> {
    return this._router.navigate([path]);
  }

  public async navigateToLogin(): Promise<boolean> {
    return this.navigateTo(`/${AppUrl.LOGIN}`);
  }

  public async navigateToApp(): Promise<boolean> {
    if (this._userService.userHasPermissionForComponent('cIdHome')) {
      return this.navigateTo(this._navHeaderService.generateHomeUrl());
    } else {
      return this.navigateTo(`/${AppUrl.SEARCH_POLICY}`);
    }
  }
}
