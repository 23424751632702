import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { PasswordExpiresSoonService } from '../../services/password-expires-soon/password-expires-soon.service';
import { NX_MODAL_DATA } from '@aposin/ng-aquila/modal';

@Component({
  selector: 'app-password-expire-days-left-dialog',
  templateUrl: './password-expires-soon-dialog.component.html',
  styleUrls: ['./password-expires-soon-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordExpiresSoonDialogComponent implements OnInit {
  protected passwordExpireDaysLeftText = '';

  constructor(
    private readonly passwordExpiresSoonService: PasswordExpiresSoonService,
    @Inject(NX_MODAL_DATA) private readonly passwordDaysLeft: string,
  ) {}

  ngOnInit(): void {
    this.passwordExpireDaysLeftText = this.passwordExpiresSoonService.getPasswordExpireDaysLeftText(
      this.passwordDaysLeft,
    );
  }
}
