import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  Input,
} from '@angular/core';
import { NonNullableFormBuilder, Validators } from '@angular/forms';
import { catchError, takeUntil, tap } from 'rxjs';

import { ResetPasswordMapperService } from '../../../core/mappers/reset-password/reset-password-mapper.service';
import { ResetPasswordHttpService } from '../../../core/http/reset-password/reset-password-http.service';

import { DestroyEmitter } from '../../../shared/classes/destroy-emitter.class';
import { CustomValidators } from '../../../shared/validators/custom-validators.class';
import { Question } from '../../../shared/types/question.type';
import { BaseResponseData } from '@agentmax/shared/types/base-response-data.type';

@Component({
  selector: 'app-reset-password-form',
  templateUrl: './reset-password-form.component.html',
  styleUrls: ['./reset-password-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResetPasswordFormComponent extends DestroyEmitter implements OnInit {
  @Input()
  public set securityQuestions(value: ReadonlyArray<Question>) {
    this._securityQuestions = value;
  }

  @Input()
  public set userEmail(value: string) {
    this._userEmail = value;
  }

  @Output()
  public readonly resolve = new EventEmitter<BaseResponseData>();

  protected _securityQuestions: ReadonlyArray<Question> = [];
  protected _userEmail = '';
  protected _onePortalMessageCode: string | null = null;
  protected readonly _resetPasswordForm = this._formBuilder.group(
    {
      newPassword: ['', [Validators.required, CustomValidators.passwordFormat]],
      confirmPassword: ['', [Validators.required]],
      securityQuestion: ['', [Validators.required]],
      answer: ['', [Validators.required]],
    },
    {
      validators: [CustomValidators.controlsMatch('newPassword', 'confirmPassword')],
    },
  );

  constructor(
    private readonly _formBuilder: NonNullableFormBuilder,
    private readonly _resetPasswordMapperService: ResetPasswordMapperService,
    private readonly _resetPasswordHttpService: ResetPasswordHttpService,
    private readonly _cdRef: ChangeDetectorRef,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.throwIfNoSecurityQuestions();
    this.throwIfNoUserEmail();
    this.subToResetPasswordFormValueChanges();
  }

  protected handleResetPasswordFormSubmit(): void {
    if (this._resetPasswordForm.invalid) {
      return;
    }

    const resetPasswordDto = this._resetPasswordMapperService.fromFormValueAndUserEmailToDto(
      this._resetPasswordForm.getRawValue(),
      this._userEmail,
    );
    this._resetPasswordHttpService
      .resetPassword(resetPasswordDto)
      .pipe(
        tap((data) => this.resolve.emit(data)),
        catchError((error: Error) => {
          this._onePortalMessageCode = error.message;
          this._cdRef.markForCheck();
          throw error;
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private throwIfNoSecurityQuestions(): never | void {
    if (this._securityQuestions.length === 0) {
      throw new Error('Security questions are required!');
    }
  }

  private throwIfNoUserEmail(): never | void {
    if (this._userEmail === '') {
      throw new Error('User email is required!');
    }
  }

  private subToResetPasswordFormValueChanges(): void {
    this._resetPasswordForm.valueChanges
      .pipe(
        tap(() => {
          if (this._onePortalMessageCode !== null) {
            this._onePortalMessageCode = null;
          }
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }
}
