<div class="environment-info-dialog">
  <header class="header">
    <nx-icon
      class="header__icon"
      name="info-circle"
      size="m"
    ></nx-icon>
    <h2 class="header__title">Version info</h2>
  </header>

  <main *ngIf="_dialogData.infoRequestSuccess">
    <nx-tab-group [(selectedIndex)]="_selectedTabIndex">
      <nx-tab [label]="_dialogData.localProject.name">
        <app-application-key-value-table [tableItems]="_localProjectTableItems">
        </app-application-key-value-table>
      </nx-tab>

      <nx-tab
        *ngFor="let externalProjectTableItems of _externalProjectsTableItems"
        [label]="externalProjectTableItems[0].application"
      >
        <app-application-key-value-table [tableItems]="externalProjectTableItems">
        </app-application-key-value-table>
      </nx-tab>

      <nx-tab label="Feature Toggles">
        <app-feature-toggles></app-feature-toggles>
      </nx-tab>

      <nx-tab label="BU Configuration">
        <app-bu-configuration></app-bu-configuration>
      </nx-tab>
    </nx-tab-group>
  </main>

  <main *ngIf="!_dialogData.infoRequestSuccess">
    <div class="error-view">
      <nx-icon
        class="error-icon"
        name="exclamation-circle"
        size="s"
      ></nx-icon>
      <p class="error-text">{{ "versioninfo.INFO_FAILURE_MSG" | translate }}</p>
    </div>
  </main>
</div>
