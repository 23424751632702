import { TO_AUTHORIZE } from "@agentmax/core/http/contexts/to-authorize";
import { TokensHttpService } from "@agentmax/core/http/tokens/tokens-http.service";
import { HttpClient, HttpContext } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { Observable, tap } from "rxjs";
import { DataAccessorService } from "../services/data-accessor/data-accessor.service";
import { DataRecordingService } from "../services/data-recording/data-recording.service";
import { GenerateTokenResponseData } from "../types/generate-token-response-data.type";

@Injectable()
export abstract class BaseHttpService {
    private readonly dataAccessorService = inject(DataAccessorService);
    private readonly dataRecordingService = inject(DataRecordingService);
    private readonly tokensHttpService = inject(TokensHttpService);
    protected readonly httpClient = inject(HttpClient);

    protected pullTokens(): Observable<GenerateTokenResponseData> {
        const refreshToken = this.dataAccessorService.getRefreshToken();
        const userEmail = this.dataAccessorService.getUserEmail();

        return this.tokensHttpService
            .generateToken({
                refreshToken,
                userEmail,
            })
            .pipe(
                tap(({ accessToken, refreshToken }) => {
                    this.dataRecordingService.saveAccessToken(accessToken);
                    this.dataRecordingService.saveRefreshToken(refreshToken);
                })
            );
    }

    protected buildAuthorizeContext(): HttpContext {
        return new HttpContext().set(TO_AUTHORIZE, true);
    }

}