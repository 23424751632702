import { Injectable } from '@angular/core';
import { VoyageType } from 'src/app/shared/enums/voyage-type.enum';

@Injectable({
  providedIn: 'root',
})
export class VoyageTypeService {
  public getVoyageTypeTranslation(travelType: string): string[] {
    const voyageTypeList = [];

    if (travelType === VoyageType.ANNUAL_ONE_WAY) {
      voyageTypeList.push('tripdetailpage.0.ANNUAL');
      voyageTypeList.push('tripdetailpage.0.SINGLETRIP');
    } else if (travelType === VoyageType.ONE_WAY) {
      voyageTypeList.push('tripdetailpage.0.SINGLETRIP');
    } else if (travelType === VoyageType.ANNUAL) {
      voyageTypeList.push('tripdetailpage.0.ANNUAL');
    }

    return voyageTypeList;
  }
}
