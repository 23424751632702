import { Injectable } from '@angular/core';

import { ENVIRONMENT } from '../../../global/environment';
import { SessionStorageService } from '../../../shared/services/session-storage/session-storage.service';
import { SessionStorageKey } from '../../../shared/enums/session-storage-key.enum';
import { EnvironmentConfig } from '../../../shared/types/environment-config.type';
import { environment } from '../../../../environments/environment';

import { EnvironmentServiceInterface } from './environment-service.interface';

/**
 * @deprecated Use {@link ENVIRONMENT} global dependency instead
 */
@Injectable({
  providedIn: 'root',
})
export class EnvironmentService implements EnvironmentServiceInterface {
  private readonly environmentConfig = environment.standalone
    ? environment
    : this.sessionStorageService.getParsedValue<EnvironmentConfig>(
        SessionStorageKey.ENVIRONMENT_CONFIG,
      );

  constructor(private readonly sessionStorageService: SessionStorageService) {}

  public getBookingCoreApiUrl(): string {
    return this.environmentConfig.BASE_URL;
  }

  public getBaseUrlMarketingMaterial(): string {
    return this.environmentConfig.baseUrlMarketingMaterial;
  }
}
