import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { GlobalPageLayoutComponent } from '../core/layouts/global-page-layout/global-page-layout.component';
import { LoginPageLayoutComponent } from './layouts/login-page-layout/login-page-layout.component';
import { LoginPageComponent } from './components/login-page/login-page.component';
import { AppUrl } from '../shared/enums/app-url.enum';

const loginRoutes: Routes = [
  {
    path: '',
    component: GlobalPageLayoutComponent,
    children: [
      {
        path: '',
        component: LoginPageLayoutComponent,
        children: [
          {
            path: AppUrl.LOGIN,
            component: LoginPageComponent,
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(loginRoutes)],
  exports: [RouterModule],
})
export class LoginRoutingModule {}
