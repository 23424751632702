import { Injectable } from '@angular/core';

import { SessionStorageService } from '../session-storage/session-storage.service';
import { SessionStorageKey } from '../../enums/session-storage-key.enum';
import { QuoteOfferDetailsResponse } from 'src/app/shared/types/success-quote-offer-details-data.type';
import { AppFeatureToggles } from 'src/app/shared/types/app-feature-toggles.type';
import { BUConfig } from 'src/app/shared/types/ui-config.type';
import { CurrentUser } from 'src/app/shared/types/current-user.type';
import { BusinessUnits } from 'src/app/shared/types/business-units.type';
import { UserInformation } from 'src/app/shared/types/user-information.type';
import { AccountDetails } from 'src/app/shared/types/account-details.type';
import { MyAccountInfo } from 'src/app/shared/types/login-response-data.type';

@Injectable({
  providedIn: 'root',
})
export class DataRecordingService {
  constructor(private readonly sessionStorageService: SessionStorageService) {}

  public saveAccessToken(accessToken: string): void {
    this.sessionStorageService.setRawValue(SessionStorageKey.ACCESS_TOKEN, accessToken);
  }

  public saveRefreshToken(refreshToken: string): void {
    this.sessionStorageService.setRawValue(SessionStorageKey.REFRESH_TOKEN, refreshToken);
  }

  public saveQuoteOfferDetails(offerDetails: QuoteOfferDetailsResponse): void {
    this.sessionStorageService.setRawValue(SessionStorageKey.SELECTED_OFFER_DETAILS, offerDetails);
  }

  public saveUserCountryCode(countryCode: string): void {
    this.sessionStorageService.setRawValue(SessionStorageKey.USER_COUNTRY_CODE, countryCode);
  }

  public saveUserInformation(userInfo: UserInformation): void {
    this.sessionStorageService.setRawValue(SessionStorageKey.USER_INFORMATION, userInfo);
  }

  public saveUserTokens(currentUserTokens: CurrentUser): void {
    this.sessionStorageService.setRawValue(SessionStorageKey.CURRENT_USER, currentUserTokens);
  }

  public saveFeatureToggles(appFT: AppFeatureToggles): void {
    this.sessionStorageService.setRawValue(SessionStorageKey.FEATURE_TOGGLES, appFT);
  }

  public saveOriginalFeatureToggles(appFT: AppFeatureToggles): void {
    this.sessionStorageService.setRawValue(SessionStorageKey.ORIGINAL_FEATURE_TOGGLES, appFT);
  }

  public saveAppConfiguration(appConfig: BUConfig): void {
    this.sessionStorageService.setRawValue(SessionStorageKey.UI_CONFIG, appConfig);
  }

  public saveAppEnvironment(appEnvironment: string): void {
    this.sessionStorageService.setRawValue(SessionStorageKey.ENVIRONMENT, appEnvironment);
  }

  public saveClusterId(clusterId: string | null): void {
    this.sessionStorageService.setRawValue(SessionStorageKey.CLUSTER_ID, clusterId);
  }

  public saveBusinessUnits(bu: BusinessUnits): void {
    this.sessionStorageService.setRawValue(SessionStorageKey.BUSINESS_UNITS, bu);
  }

  public saveCustomerNumber(customerNumber: string): void {
    this.sessionStorageService.setRawValue(SessionStorageKey.CUSTOMER_NUMBER, customerNumber);
  }

  public saveAccountDetails(accountDetails: AccountDetails): void {
    this.sessionStorageService.setRawValue(SessionStorageKey.ACCOUNT_DETAILS, accountDetails);
  }

  public saveMyAccountInfo(myAccountInfo: MyAccountInfo): void {
    this.sessionStorageService.setRawValue(SessionStorageKey.MY_ACCOUNT_INFO, myAccountInfo);
  }

  public saveBUBrand(brand: string): void {
    this.sessionStorageService.setRawValue(SessionStorageKey.BU_BRAND, brand);
  }

  public saveWhiteLabelFlag(isWhiteLabel: boolean): void {
    this.sessionStorageService.setRawValue(SessionStorageKey.WHITE_LABEL, isWhiteLabel);
  }

  public saveUserType(userType: string): void {
    this.sessionStorageService.setRawValue(SessionStorageKey.USER_TYPE, userType);
  }

  public saveSelectedLanguage(language: string): void {
    this.sessionStorageService.setRawValue(SessionStorageKey.SELECTED_LANGUAGE, language);
  }

  public saveBUAgencyLogo(logo: string): void {
    this.sessionStorageService.setRawValue(SessionStorageKey.BU_AGENCY_LOGO, logo);
  }

  public saveQuoteOfferComment(comment: string | null): void {
    this.sessionStorageService.setRawValue(SessionStorageKey.QUOTE_OFFER_COMMENT, comment);
  }
}
