import { Injectable } from '@angular/core';

import { BaseSessionStorageService } from './base-session-storage.service';
import { SessionStorageKey } from '@agentmax/shared/enums/session-storage-key.enum';

@Injectable({
  providedIn: 'root',
})
export class ClusterIdSessionStorageService extends BaseSessionStorageService<string> {
  protected readonly key = SessionStorageKey.CLUSTER_ID;
}
