import { Inject, Injectable } from '@angular/core';

import { SESSION_STORAGE } from '../../../core/deprecated-global-dependencies/session-storage.global-dependency';
import { SessionStorageKey } from '../../enums/session-storage-key.enum';
import { ErrorService } from '@agentmax/shared/services/error/error.service';

import { SessionStorageServiceInterface } from './session-storage-service.interface';

@Injectable({
  providedIn: 'root',
})
export class SessionStorageService implements SessionStorageServiceInterface {
  constructor(
    @Inject(SESSION_STORAGE)
    private readonly storage: Storage,
    private readonly errorService: ErrorService,
  ) {}

  public getRawValueOrNull(key: SessionStorageKey): string | null {
    return this.storage.getItem(key);
  }

  public getParsedValueOrNull<T = unknown>(key: SessionStorageKey): T | null {
    const rawValueOrNull = this.getRawValueOrNull(key);

    if (rawValueOrNull === null) {
      return null;
    }

    return JSON.parse(rawValueOrNull);
  }

  public getParsedValue<T = unknown>(key: SessionStorageKey): T {
    const parsedValueOrNull = this.getParsedValueOrNull(key);

    if (parsedValueOrNull === null) {
      this.errorService.throwError(
        this.constructor.name,
        `There is no value in session storage by key ${key}`,
      );
    }

    return parsedValueOrNull as T;
  }

  public setApplicableValue(key: SessionStorageKey, applicableValue: string): void {
    this.storage.setItem(key, applicableValue);
  }

  public setRawValue(key: SessionStorageKey, rawValue: unknown): void {
    const applicableValue = JSON.stringify(rawValue);
    this.setApplicableValue(key, applicableValue);
  }

  public removeValue(key: SessionStorageKey): void {
    this.storage.removeItem(key);
  }

  public clearStorage(): void {
    this.storage.clear();
  }
}
