import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UpdateOfferCommentService {
  private readonly _comment$ = new BehaviorSubject<string | null>(null);
  public readonly comment$ = this._comment$.asObservable();
  public setComment(comment: string | null): void {
    this._comment$.next(comment);
  }
}
