import { RegexPattern } from '@agentmax/core/deprecated-global-dependencies/regex-pattern';
import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { CountryCode } from '@agentmax/shared/enums/country-code.enum';

//TODO use dependency for this
const EMAIL_FORMAT_REGEXP =
  /^[a-zA-Z0-9_ÄÁÀÂĂǍA̋äáàâăǎa̋éèêëe̋ÈÉÊËE̋ìíîïi̋ÌÍÎÏI̋ÒÓŐÖÔòóőöôÜŰÛÚÜÙüûúűúüùÑñÇçḈḉ&ŞŢşţŞşŢţß\+\.']+([\.-]?[a-zA-Z0-9_ÄÁÀÂĂǍA̋äáàâăǎa̋éèêëe̋ÈÉÊËE̋ìíîïi̋ÌÍÎÏI̋ÒÓŐÖÔòóőöôÜŰÛÚÜÙüûúűúüùÑñÇçḈḉ&ŞŢşţŞşŢţß\+\.']+)*@[a-zA-Z0-9_ÄÁÀÂĂǍA̋äáàâăǎa̋éèêëe̋ÈÉÊËE̋ìíîïi̋ÌÍÎÏI̋ÒÓŐÖÔòóőöôÜŰÛÚÜÙüûúűúüùÑñÇçḈḉ&ŞŢşţŞşŢţß']+([\.-]?[a-zA-Z0-9_ÄÁÀÂĂǍA̋äáàâăǎa̋éèêëe̋ÈÉÊËE̋ìíîïi̋ÌÍÎÏI̋ÒÓŐÖÔòóőöôÜŰÛÚÜÙüûúűúüùÑñÇçḈḉ&ŞŢşţŞşŢţß']+)*(\.[a-zA-Z0-9_ÄÁÀÂĂǍA̋äáàâăǎa̋éèêëe̋ÈÉÊËE̋ìíîïi̋ÌÍÎÏI̋ÒÓŐÖÔòóőöôÜŰÛÚÜÙüûúűúüùÑñÇçḈḉ&ŞŢşţŞşŢţß']{2,8})+$/;
const PASSWORD_FORMAT_REGEXP = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/;

const CYRILIC_ALPHABET_PATTERN = RegexPattern.CYRILIC_ALPHABET_PATTERN;
const CZ_ALPHABRT_PATTERN = RegexPattern.CZ_ALPHABRT_PATTERN;
const BG = CountryCode.BULGARIA;
const CZ = CountryCode.CZECH;
@Injectable({
  providedIn: 'root',
})
export class CustomValidators {
  public static validateEmail(control: AbstractControl): ValidationErrors | null {
    if (!control.value) return null;
    if (control.value && !control.value.match(EMAIL_FORMAT_REGEXP)) {
      return { email: true };
    }
    return null;
  }

  public static controlsMatch(firstControlName: string, matchingControlName: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const firstControl = control.get(firstControlName);
      const matchingControl = control.get(matchingControlName);

      if (firstControl === null) {
        throw new Error(`Control with name: '${firstControlName}' is not found!`);
      }

      if (matchingControl === null) {
        throw new Error(`Control with name: '${matchingControlName}' is not found!`);
      }

      if (matchingControl.errors && !matchingControl.errors['controlsMatch']) {
        return null;
      }

      if (firstControl.value !== matchingControl.value) {
        matchingControl.setErrors({ controlsMatch: true });
      } else {
        matchingControl.setErrors(null);
      }

      return null;
    };
  }

  public static controlsDoNotMatch(
    firstControlName: string,
    matchingControlName: string,
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const firstControl = control.get(firstControlName);
      const matchingControl = control.get(matchingControlName);

      if (firstControl === null) {
        throw new Error(`Control with name: '${firstControlName}' is not found!`);
      }

      if (matchingControl === null) {
        throw new Error(`Control with name: '${matchingControlName}' is not found!`);
      }

      if (matchingControl.errors && !matchingControl.errors['controlsDoNotMatch']) {
        return null;
      }

      if (firstControl.value === matchingControl.value) {
        matchingControl.setErrors({ controlsDoNotMatch: true });
      } else {
        matchingControl.setErrors(null);
      }

      return null;
    };
  }

  public static passwordFormat(control: AbstractControl): ValidationErrors | null {
    // To not conflict with `required` validator
    if (control.value === '') {
      return null;
    }

    if (!PASSWORD_FORMAT_REGEXP.test(control.value)) {
      return { passwordFormat: true };
    }

    return null;
  }

  public static nonEmptyForm: ValidatorFn = (
    abstractControl: AbstractControl,
  ): ValidationErrors | null => {
    for (const formfieldValue of Object.values(abstractControl.value)) {
      if (formfieldValue) {
        return null;
      }
    }
    return { empty: true };
  };
  public static profileValidateField(countryCode: string, pattern: RegExp): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      // To not conflict with `required` validator
      const controlValue = control.value;
      if (controlValue === '') {
        return null;
      }
      if (countryCode === BG) {
        if (!pattern.test(controlValue) && !CYRILIC_ALPHABET_PATTERN.test(controlValue)) {
          return { profileValidateField: true };
        }
      } else if (countryCode === CZ) {
        if (!pattern.test(controlValue) && !CZ_ALPHABRT_PATTERN.test(controlValue)) {
          return { profileValidateField: true };
        }
      } else if (countryCode !== BG && countryCode !== CZ && !pattern.test(controlValue)) {
        return { profileValidateField: true };
      }
      return null;
    };
  }
}
