import { InjectionToken } from '@angular/core';

import { PolicyTypeValue } from '../../../shared/enums/policy-type-value.enum';
import { PolicyStatusValue } from '../../../shared/enums/policy-status-value.enum';

export const SEARCH_POLICY_TYPE_OPTIONS_VALUE = [
  {
    value: '',
    label: 'poladmin.0.POLICYTYPE',
  },
  {
    value: PolicyTypeValue.ANNUAL_TRIP,
    label: 'tripdetailpage.0.ANNUAL',
  },
  {
    value: PolicyTypeValue.SINGLE_TRIP,
    label: 'tripdetailpage.0.SINGLETRIP',
  },
];
export const SEARCH_POLICY_TYPE_OPTIONS = new InjectionToken<
  typeof SEARCH_POLICY_TYPE_OPTIONS_VALUE
>('SEARCH_POLICY_TYPE_OPTIONS');

export const SEARCH_POLICY_STATUS_OPTIONS_VALUE = [
  {
    value: '',
    label: 'poladmin.0.POLSTATUS',
  },
  {
    value: PolicyStatusValue.EFFECTIVE,
    label: 'poladmin.0.EFFECTIVE',
  },
  {
    value: PolicyStatusValue.CANCELLED,
    label: 'poladmin.0.CANCELLED',
  },
];
export const SEARCH_POLICY_STATUS_OPTIONS = new InjectionToken<
  typeof SEARCH_POLICY_STATUS_OPTIONS_VALUE
>('SEARCH_POLICY_STATUS_OPTIONS');
