<nx-formfield
  class="date-control"
  [label]="_label"
  data-test-id="date-control-form-field"
>
  <input
    #inputRef
    [datepicker]="datepickerRef"
    [placeholder]="_placeholder"
    [parseFormat]="_dateFormat"
    [displayFormat]="_dateFormat"
    [nxMask]="_dateMask"
    [min]="_minDate"
    [max]="_maxDate"
    nxInput
    nxDatefield
    data-test-id="date-control-input"
    (dateChange)="handleDateChange($event.value)"
  />

  <nx-datepicker-toggle
    [for]="datepickerRef"
    nxFormfieldSuffix
  ></nx-datepicker-toggle>

  <nx-datepicker #datepickerRef></nx-datepicker>
</nx-formfield>
