import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { LogoService } from '@agentmax/core/services/logo/logo.service';
import { ErrorService } from '@agentmax/shared/services/error/error.service';
import { LogoDataType } from 'src/app/shared/types/logos-map.type';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoComponent implements OnInit {
  logoData: LogoDataType = { src: '', alt: '' };

  constructor(
    private readonly logoService: LogoService,
    private readonly errorService: ErrorService,
  ) {}

  ngOnInit(): void {
    //TODO refactor these two variables to be a stream so they are updated
    const logoName = this.logoService.determineLogoNameByHostAndEnvName();
    this.logoData = this.logoService.getLogoData(logoName);
    if (this.logoData === undefined) {
      this.errorService.throwError(this.constructor.name, `There is no such logo: ${logoName}!`);
    }
  }
}
