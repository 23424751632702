import { inject, Injectable } from '@angular/core';
import { Action, NgxsOnInit, State, StateContext, StateToken } from '@ngxs/store';

import { CurrentAgencyType } from '@agentmax/shared/types/current-agency.type';
import { CurrentAgency } from './current-agency.actions';
import { CurrentAgencySessionStorageService } from '@agentmax/shared/session-storage/current-agency-session-storage.service';

export type CurrentAgencyStateModel = CurrentAgencyType | null;

export const CURRENT_AGENCY_STATE = new StateToken<CurrentAgencyStateModel>('CURRENT_AGENCY_STATE');

@State({
  name: CURRENT_AGENCY_STATE,
  defaults: null,
})
@Injectable({
  providedIn: 'root',
})
export class CurrentAgencyState implements NgxsOnInit {
  private readonly currentAgency = inject(CurrentAgencySessionStorageService);

  public ngxsOnInit(context: StateContext<CurrentAgencyStateModel>): void {
    const currentAgency = this.currentAgency.getOrReturnNull();

    if (currentAgency === null) {
      return;
    }

    context.dispatch(new CurrentAgency.Restore(currentAgency));
  }

  @Action(CurrentAgency.Save)
  public save(
    context: StateContext<CurrentAgencyStateModel>,
    { currentAgency }: CurrentAgency.Save,
  ): void {
    this.currentAgency.set(currentAgency);
    context.setState(currentAgency);
  }

  @Action(CurrentAgency.Clear)
  public clear(context: StateContext<CurrentAgencyStateModel>): void {
    this.currentAgency.remove();
    context.setState(null);
  }

  @Action(CurrentAgency.Restore)
  public restore(
    context: StateContext<CurrentAgencyStateModel>,
    { currentAgency }: CurrentAgency.Restore,
  ): void {
    context.setState(currentAgency);
  }
}
