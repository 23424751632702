<div class="float-right nx-margin-right-s">
  <span class="user-fullname">
    <span *ngIf="showWelcomeText">{{ "tripdetailpage.0.WELCOME" | translate }} &nbsp;</span>
    <span>{{ userInfo.userName }} &nbsp;</span>
    <span>{{ userInfo.lastName }}!</span>
  </span>
  <nx-icon
    name="chevron-down"
    class="popover-icon"
    [nxPopoverTriggerFor]="popoverRef"
    nxPopoverTrigger="click"
    #clickTriggerIcon
  ></nx-icon>
  <br />
  <span>{{ userInfo.agencyName}}</span>
</div>

<nx-popover #popoverRef>
  <span>{{ userInfo.agencyName }}</span> <br />
  <span>{{ userInfo.addressline1 }}</span> <br />
  <span>{{ userInfo.addressline2 }}</span> <br />
  <span>{{ userInfo.zipcode }} {{ userInfo.city }}</span>
</nx-popover>
