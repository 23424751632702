import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NxInputModule } from '@aposin/ng-aquila/input';
import { NxIconModule } from '@aposin/ng-aquila/icon';
import { NxButtonModule } from '@aposin/ng-aquila/button';
import { NxLinkModule } from '@aposin/ng-aquila/link';
import { NxDropdownModule } from '@aposin/ng-aquila/dropdown';
import { NxHeaderModule } from '@aposin/ng-aquila/header';
import { NxTooltipModule } from '@aposin/ng-aquila/tooltip';
import { NxMessageModule } from '@aposin/ng-aquila/message';
import { HasErrorPipe } from '../shared/pipes/has-error/has-error.pipe';
import { InfoIconComponent } from '../shared/components/info-icon/info-icon.component';
import { LoginRoutingModule } from './login-routing.module';

import { LoginPageLayoutComponent } from './layouts/login-page-layout/login-page-layout.component';
import { LogoComponent } from '../core/components/logo/logo.component';
import { SelectLanguageComponent } from '../shared/components/select-language/select-language.component';
import { LoginPageCardComponent } from './components/login-page-card/login-page-card.component';
import { LoginFormComponent } from './components/login-form/login-form.component';
import { LoginPageComponent } from './components/login-page/login-page.component';
import { ForgotPasswordFormComponent } from './components/forgot-password-form/forgot-password-form.component';
import { ResetPasswordFormComponent } from './components/reset-password-form/reset-password-form.component';
import { LoginPageViewContainerComponent } from './components/login-page-view-container/login-page-view-container.component';
import { CreatePasswordFormComponent } from './components/create-password-form/create-password-form.component';
import { CreatePasswordSuccessComponent } from './components/create-password-success/create-password-success.component';
import { ResetPasswordForceFormComponent } from './components/reset-password-force-form/reset-password-force-form.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    NxInputModule,
    NxIconModule,
    NxButtonModule,
    NxLinkModule,
    NxDropdownModule,
    NxHeaderModule,
    NxMessageModule,
    NxTooltipModule,
    HasErrorPipe,
    InfoIconComponent,
    LoginRoutingModule,
  ],
  declarations: [
    LoginPageLayoutComponent,
    LoginPageCardComponent,
    LoginFormComponent,
    ForgotPasswordFormComponent,
    ResetPasswordFormComponent,
    ResetPasswordForceFormComponent,
    LoginPageViewContainerComponent,
    SelectLanguageComponent,
    LogoComponent,
    LoginPageComponent,
    CreatePasswordFormComponent,
    CreatePasswordSuccessComponent,
  ],
  exports: [
    LoginPageCardComponent,
    LoginPageViewContainerComponent,
    SelectLanguageComponent,
    LogoComponent,
    LoginPageComponent,
  ],
})
export class LoginModule {}
