<div class="row row-10">
  <div class="col-md-12 ">
    <div class="col-md-6 link-list-url">
      <dl>
        <dd *ngIf="showMarketingMaterial">
          <span class="link-list-arrow">&#8594;</span>
          <span
            (click)="orderMarketingMaterial()"
            role="button"
            >{{ 'helpAndInformation.0.ORDER_MARKETING_MATERIAL' | translate }}</span
          >
        </dd>
      </dl>
    </div>
    <div class="col-md-6 "></div>
  </div>
  <div class="col-md-12 ">
    <iframe
      [src]="(_iFrameURL$ | async) || '' | trustedResourceUrl"
      width="100%"
      height="600"
      title="Help Iframe"
    ></iframe>
  </div>
</div>
