import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NxIconModule } from '@aposin/ng-aquila/icon';

type InfoIconName = 'info-circle-o' | 'info-circle';

@Component({
  selector: 'app-info-icon',
  templateUrl: './info-icon.component.html',
  styleUrls: ['./info-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NxIconModule],
})
export class InfoIconComponent {
  protected _currentIconName: InfoIconName = 'info-circle-o';

  protected handleMouseenter(): void {
    this._currentIconName = 'info-circle';
  }

  protected handleMouseleave(): void {
    this._currentIconName = 'info-circle-o';
  }
}
