import { CurrentAgencyType } from '@agentmax/shared/types/current-agency.type';

export namespace CurrentAgency {
  export class Save {
    public static readonly type = '[CurrentAgency] Save';

    constructor(public readonly currentAgency: CurrentAgencyType) {}
  }

  export class Clear {
    public static readonly type = '[CurrentAgency] Clear';
  }

  export class Restore {
    public static readonly type = '[CurrentAgency] Restore';

    constructor(public readonly currentAgency: CurrentAgencyType) {}
  }
}
