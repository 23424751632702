<div class="forgot-password-form">
  <form
    class="form"
    [formGroup]="_forgotPasswordForm"
    (ngSubmit)="handleForgotPasswordFormSubmit()"
  >
    <nx-formfield
      class="form__item"
      [label]="'loginpage.0.EMAIL' | translate"
      appearance="outline"
    >
      <span nxFormfieldPrefix>
        <nx-icon name="product-mail"></nx-icon>
      </span>

      <input
        nxInput
        [placeholder]="'loginpage.0.EMAIL' | translate"
        formControlName="email"
      />

      <nx-error
        *ngIf="
          _forgotPasswordForm.controls.email.errors | hasError : 'required'
        "
        appearance="text"
        nxFormfieldError
      >
        <span>{{ "loginpage.0.EMAILREQD" | translate }}</span>
      </nx-error>

      <nx-error
        *ngIf="_forgotPasswordForm.controls.email.errors | hasError : 'email'"
        appearance="text"
        nxFormfieldError
      >
        <span>{{ "loginpage.0.INVPASS" | translate }}</span>
      </nx-error>
    </nx-formfield>

    <button
      class="form__item login-button"
      [disabled]="_forgotPasswordForm.invalid || _backendErrorMessage !== ''"
      nxButton="primary"
      type="submit"
    >
      <span>{{ "passchangepage.0.CONFIRM" | translate }}</span>
    </button>
  </form>

  <nx-error
    class="backend-error-container"
    *ngIf="_backendErrorMessage"
    appearance="message"
  >
    <span>{{ _backendErrorMessage | translate }}</span>
  </nx-error>
</div>
