<div class="policy-admin-page">
  <nx-expansion-panel [expanded]="true">
    <nx-expansion-panel-header>
      <nx-expansion-panel-title
        >{{
        "poladmin.0.SEARCHPOL" | translate
        }}
        <nx-icon
          [nxTooltip]="'tooltip.0.POLADMIN' | translate"
          name="help-question-f"
          class="help-icon-size"
        ></nx-icon>
      </nx-expansion-panel-title>
    </nx-expansion-panel-header>

    <app-search-policy-form
      (resolve)="handleSearchResult($event)"
      (searchError)="handleSearchError($event)"
    ></app-search-policy-form>
  </nx-expansion-panel>

  <nx-error
    class="error-container"
    *ngIf="_errorMessage"
    appearance="message"
  >
    <span>{{ _errorMessage | translate }}</span>
  </nx-error>

  <app-search-policy-response-view
    *ngIf="searchResult$ | async as searchResult"
    [policyList]="searchResult"
  ></app-search-policy-response-view>
</div>
