import { Injectable } from '@angular/core';
import { NxSortHeaderIntl } from '@aposin/ng-aquila/table';

@Injectable()
export class AgentmaxIntlService extends NxSortHeaderIntl {
  sortAscendingAriaLabel = 'click to sort ascending';
  sortDescendingAriaLabel = 'click to sort descending';
  sortedAscendingAriaLabel = 'sorted ascending by';
  sortedDescendingAriaLabel = 'sorted descending by';
}
