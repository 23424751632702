import { Injectable } from '@angular/core';
import { LoginPageViewService } from '../services/login-page-view.service';

import { LoginStrategy } from '../classes/login.strategy';
import { LoginResponseData } from '../../shared/types/login-response-data.type';

@Injectable({
  providedIn: 'root',
})
export class NewUserStrategy extends LoginStrategy {
  constructor(private readonly _loginPageViewService: LoginPageViewService) {
    super();
  }

  public execute(_: LoginResponseData): void {
    this._loginPageViewService.switchToCreatePasswordView();
  }
}
