import { Injectable } from '@angular/core';

import { BaseSessionStorageService } from './base-session-storage.service';
import { SessionStorageKey } from '@agentmax/shared/enums/session-storage-key.enum';

@Injectable({
  providedIn: 'root',
})
export class BrandForNewsAndProductsSessionStorageService extends BaseSessionStorageService<string> {
  protected readonly key = SessionStorageKey.BRAND_FOR_NEWS_AND_PRODUCTS;
}
