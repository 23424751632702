import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { LogoService } from '@agentmax/core/services/logo/logo.service';
import { ErrorService } from '@agentmax/shared/services/error/error.service';

type Side = 'left' | 'right';

@Component({
  selector: 'app-inner-logo',
  templateUrl: './inner-logo.component.html',
  styleUrls: ['./inner-logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InnerLogoComponent implements OnInit {
  @Input()
  public set side(side: Side) {
    this._side = side;
  }

  protected logoSrc?: string;
  protected _side?: Side;

  constructor(
    private readonly logoService: LogoService,
    private readonly errorService: ErrorService,
  ) {}

  ngOnInit(): void {
    //TODO refactor this variable to be a stream so it is updated
    if (this._side === 'left') {
      this.logoSrc = this.logoService.determineInnerLeftLogoSrc();
    } else if (this._side === 'right') {
      this.logoSrc = this.logoService.determineInnerRightLogoSrc();
    } else {
      this.errorService.throwError(
        this.constructor.name,
        'You need to specify in which side is used this component!',
      );
    }
  }
}
