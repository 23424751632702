import { Injectable } from '@angular/core';
import { NxDialogService, NxModalRef } from '@aposin/ng-aquila/modal';
import { takeUntil, tap } from 'rxjs';

import { SessionExpiresSoonDialogComponent } from '../../dialogs/session-expires-soon-dialog/session-expires-soon-dialog.component';

import { DestroyEmitter } from '../../../shared/classes/destroy-emitter.class';
import { ErrorService } from '@agentmax/shared/services/error/error.service';

@Injectable({
  providedIn: 'root',
})
export class SessionExpiresSoonDialogService extends DestroyEmitter {
  public get isOpened(): boolean {
    return this._isOpened;
  }

  private _isOpened = false;
  private _dialogRef: NxModalRef<unknown> | null = null;

  constructor(
    private readonly _dialogService: NxDialogService,
    private readonly errorService: ErrorService,
  ) {
    super();
  }

  public openDialog(): void {
    if (this._isOpened) {
      this.errorService.throwError(this.constructor.name, 'Dialog is opened already!');
    }

    this._isOpened = true;
    this._dialogRef = this._dialogService.open(SessionExpiresSoonDialogComponent, {
      width: '500px',
      disableClose: true,
    });
    this._dialogRef
      .afterClosed()
      .pipe(
        tap(() => this.closeDialog()),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  public closeDialog(): void {
    if (!this._isOpened) {
      this.errorService.throwError(this.constructor.name, 'Dialog is closed already!');
    }

    this._isOpened = false;
    this._dialogRef!.close();
    this._dialogRef = null;
    this.destroy$.next();
  }
}
