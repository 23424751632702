import { Injectable, inject } from '@angular/core';

import { AppFeatureTogglesService } from '../app-feature-toggles/app-feature-toggles.service';
import { ConfigurationService } from '../configuration/configuration.service';

import { NavLinksFilter } from './nav-links-filter.interface';
import { NavLinkListType } from '../../../shared/types/nav-links.type';
import { FeatureToggleKey } from '../../../shared/enums/feature-toggle-key.enum';
import { NavLinkId } from '../../../shared/enums/nav-link-id.enum';

@Injectable()
export class QuoteAdminConfigurationFilter implements NavLinksFilter {
  private readonly _appFeatureTogglesService = inject(AppFeatureTogglesService);
  private readonly _configurationService = inject(ConfigurationService);

  public filter(navLinks: NavLinkListType): NavLinkListType {
    if (
      !this._appFeatureTogglesService.getFeatureToggle(FeatureToggleKey.SAVE_QUOTATION) ||
      !this._configurationService.shouldShowQuoteOfferFeature()
    ) {
      return navLinks.filter((link) => link.id !== NavLinkId.SEARCH_OFFER);
    }

    return navLinks;
  }
}
