const PROD_DOMAINS = ['allianztravel', 'agentmax', 'allianzpartners'];

//TODO this needs to be refactored into same service as 2 other functions that do similar thing
export function isProdEnvironment(host: string): boolean {
  const isProdDomain = PROD_DOMAINS.some((prodDomain) => host.includes(prodDomain));

  if (isProdDomain || host.includes('.pp.')) {
    return true;
  }

  return false;
}
