<div>
  <form
    class="form"
    [formGroup]="contactForm"
    (ngSubmit)="handleContactFormSubmit()"
    *ngIf="!(feedbackStatus$ | async); else requestResult"
  >
    <div nxModalContent>
      <h3
        nxHeadline="subsection-medium"
        class="modal-header blue-color nx-margin-bottom-m"
      >
        <nx-icon
          name="mail-circle"
          size="m"
        ></nx-icon>
        <span class="nx-margin-left-s">{{ 'contactus.CONTACTUS' | translate }}</span>
      </h3>
      <nx-formfield
        appearance="outline"
        floatLabel="always"
        class="nx-margin-bottom-m"
      >
        <nx-formfield-label>
          {{ 'contactus.MESSAGE' | translate }}
        </nx-formfield-label>
        <textarea
          formControlName="message"
          nxInput
          type="text"
          #inputToCount
          maxlength="500"
        ></textarea>
        <span nxFormfieldHint>
          <span> {{ 500 - (inputToCount.value.length )}} {{'contactus.CHAR' | translate }} </span>
        </span>
      </nx-formfield>
    </div>
    <div nxModalActions>
      <button
        class="nx-margin-bottom-0"
        nxButton="medium"
        type="submit"
        [disabled]="contactForm.invalid"
      >
        {{ 'passchangepage.0.SEND' | translate }}
      </button>
    </div>
  </form>
  <ng-template #requestResult>
    <div nxModalContent>
      <h3
        nxHeadline="subsection-medium"
        class="modal-header blue-color nx-margin-bottom-m"
      >
        <nx-icon
          name="mail-circle"
          size="m"
        ></nx-icon>
        <span class="nx-margin-left-s">{{ 'contactus.CONTACTUS' | translate }}</span>
      </h3>
      <div class="nx-margin-bottom-m">
        <nx-data-display *ngIf="(feedbackStatus$ | async ) === 'success'">
          {{ 'contactus.SUCCESS' | translate }}
        </nx-data-display>
        <nx-error
          appearance="text"
          *ngIf="(feedbackStatus$ | async ) === 'error'"
        >
          {{ 'contactus.ERROR' | translate }}
        </nx-error>
      </div>
    </div>
    <div nxModalActions>
      <button
        class="nx-margin-bottom-0"
        nxButton="medium"
        type="button"
        nxModalClose
      >
        {{ 'contactus.OK' | translate }}
      </button>
    </div>
  </ng-template>
</div>
