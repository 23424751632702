<div>
  <div nxModalContent>
    <div class="nx-margin-bottom-m">
      <nx-message context="info">
        {{ passwordExpireDaysLeftText }}
      </nx-message>
    </div>
  </div>
  <div nxModalActions>
    <button
      class="nx-margin-bottom-0"
      nxButton="medium"
      type="button"
      nxModalClose
    >
      {{ 'contactus.OK' | translate }}
    </button>
  </div>
</div>
