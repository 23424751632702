import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { UserIdleService } from 'angular-user-idle';

import { AppConfigurationService } from './core/services/app-configuration/app-configuration.service';
import { IdleEventsWatcherService } from './core/services/idle-events-watcher/idle-events-watcher.service';
import { UserService } from './shared/services/user/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  constructor(
    private readonly _appConfigurationService: AppConfigurationService,
    private readonly _idleEventsWatcherService: IdleEventsWatcherService,
    private readonly _userService: UserService,
    private readonly _userIdleService: UserIdleService,
  ) {}

  public ngOnInit(): void {
    this._idleEventsWatcherService.start();
    this._appConfigurationService.setUp();

    if (this._userService.isLoggedIn()) {
      this._userIdleService.startWatching();
    }
  }
}
