import { Injectable } from '@angular/core';
import { SessionStorageKey } from '../enums/session-storage-key.enum';
import { CurrentAgencyType } from '../types/current-agency.type';
import { BaseSessionStorageService } from './base-session-storage.service';

@Injectable({
  providedIn: 'root',
})
export class CurrentAgencySessionStorageService extends BaseSessionStorageService<CurrentAgencyType> {
  public readonly key = SessionStorageKey.CURRENT_AGENCY;
}
