import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { NX_MODAL_DATA } from '@aposin/ng-aquila/modal';

import { ApplicationKeyValueListType } from '../../../shared/types/application-key-value.type';
import {
  EnvironmentInfoDialogData,
  SuccessEnvironmentInfoDialogData,
  isSuccessEnvironmentInfoDialogData,
} from '../../../shared/types/environment-info-dialog-data.type';
import { LocalProjectInfo } from '../../../shared/types/project-info/local-project-info.type';
import { ExternalProjectInfo } from '../../../shared/types/project-info/external-project-info.type';
import { ErrorService } from '@agentmax/shared/services/error/error.service';

@Component({
  selector: 'app-environment-info-dialog',
  templateUrl: './environment-info-dialog.component.html',
  styleUrls: ['./environment-info-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnvironmentInfoDialogComponent implements OnInit {
  protected _selectedTabIndex = 0;
  protected _localProjectTableItems: ApplicationKeyValueListType = [];
  protected _externalProjectsTableItems: ReadonlyArray<ApplicationKeyValueListType> = [];

  constructor(
    @Inject(NX_MODAL_DATA)
    protected readonly _dialogData: EnvironmentInfoDialogData,
    private readonly errorService: ErrorService,
  ) {}

  public ngOnInit(): void {
    if (isSuccessEnvironmentInfoDialogData(this._dialogData)) {
      this.initTablesItems(this._dialogData);
    }
  }

  private initTablesItems(dialogData: SuccessEnvironmentInfoDialogData): void {
    const { localProject, externalProjects } = dialogData;
    this._localProjectTableItems = this.mapLocalProjectToApplicationKeyValueList(localProject);
    this._externalProjectsTableItems =
      this.mapExternalProjectsToApplicationKeyValueLists(externalProjects);
  }

  private mapLocalProjectToApplicationKeyValueList(
    localProject: LocalProjectInfo,
  ): ApplicationKeyValueListType {
    const { properties, name } = localProject;
    return Object.entries(properties).map(([propertyKey, propertyValue]) => {
      let key = '';

      if (propertyKey === 'buildOn') {
        key = 'versioninfo.build.on';
      } else if (propertyKey === 'baseUrl') {
        key = 'versioninfo.base.url';
      } else {
        this.errorService.throwError(
          this.constructor.name,
          `Property key: '${propertyKey}' is not found!`,
        );
      }

      return {
        application: name,
        key,
        value: propertyValue,
      };
    });
  }

  private mapExternalProjectsToApplicationKeyValueLists(
    externalProjects: ReadonlyArray<ExternalProjectInfo>,
  ): ReadonlyArray<ApplicationKeyValueListType> {
    return externalProjects.map((externalProject) => {
      const { properties, name } = externalProject;
      return Object.entries(properties)
        .filter(([propertyKey]) => {
          return (
            propertyKey === 'git.build.version' ||
            propertyKey === 'git.branch' ||
            propertyKey === 'git.commit.id.abbrev'
          );
        })
        .map(([propertyKey, propertyValue]) => {
          let key = '';

          if (propertyKey === 'git.build.version') {
            key = 'versioninfo.release';
          } else if (propertyKey === 'git.branch') {
            key = 'versioninfo.branch';
          } else if (propertyKey === 'git.commit.id.abbrev') {
            key = 'versioninfo.commit.id';
          } else {
            this.errorService.throwError(
              this.constructor.name,
              `Property key: '${propertyKey}' is not found!`,
            );
          }

          return {
            application: name,
            key,
            value: propertyValue,
          };
        });
    });
  }
}
