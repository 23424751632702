export const doesObjectHaveKey = <T extends Record<string, unknown>>(
  obj: T,
  key: unknown,
): key is keyof T => {
  // We have to make this trick with types.
  // Otherwise typescript will complain about types incompatibility.
  if (typeof key !== 'string') {
    throw Error('Only strings are allowed!');
  }

  return Object.keys(obj).includes(key);
};
