import { InjectionToken } from '@angular/core';

export const DATE_FORMAT = new InjectionToken('DATE_FORMAT', {
  providedIn: 'root',
  factory: () => 'DD/MM/YYYY',
});

export const DATE_MASK = new InjectionToken('DATE_MASK', {
  providedIn: 'root',
  factory: () => '00/00/0000',
});
