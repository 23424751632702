import { InjectionToken, inject } from '@angular/core';

import { LanguageOptionsNameSessionStorageService } from '@agentmax/shared/session-storage/language-options-name-session-storage.service';
import { PageUrlLanguagesInitializationService } from './page-url-languages-initialization.service';
import { SessionStorageLanguagesInitializationService } from './session-storage-languages-initialization.service';

import { ILanguagesInitializationService } from './languages-initialization.service.interface';

export const LanguagesInitializationService = new InjectionToken<ILanguagesInitializationService>(
  'LanguagesInitializationService',
  {
    providedIn: 'root',
    factory: () => {
      const languageOptionsName = inject(
        LanguageOptionsNameSessionStorageService,
      ).getOrReturnNull();

      if (languageOptionsName === null) {
        return inject(PageUrlLanguagesInitializationService);
      }

      return inject(SessionStorageLanguagesInitializationService);
    },
  },
);
