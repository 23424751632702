<div class="feature-toggles">
  <button
    nxButton="primary"
    type="button"
    (click)="handleResetButtonClick()"
  >
    <span>Reset</span>
  </button>

  <nx-formfield appearance="outline">
    <input
      class="search-input"
      [formControl]="_searchField"
      nxInput
      placeholder="Search..."
    />
  </nx-formfield>

  <app-feature-toggles-view
    *ngIf="_filteredFeatureToggleList$ | async as filteredFeatureToggleList"
    [featureToggleList]="filteredFeatureToggleList | sortFeatureToggleList"
    (featureToggleStateChange)="handleFeatureToggleStateChange($event)"
  ></app-feature-toggles-view>
</div>
