import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { GlobalPageLayoutComponent } from './core/layouts/global-page-layout/global-page-layout.component';
import { InnerPageLayoutComponent } from './core/layouts/inner-page-layout/inner-page-layout.component';
import { TopBannerLayoutComponent } from './core/layouts/top-banner-layout/top-banner-layout.component';

import { HomePageComponent } from './home/pages/home-page/home-page.component';
import { NewsPageComponent } from './news/pages/news-page/news-page.component';
import { HelpPageComponent } from './help/pages/help-page/help-page.component';

import { AppUrl } from './shared/enums/app-url.enum';
import { PolicyAdminPageComponent } from './policy-admin/pages/policy-admin-page/policy-admin-page.component';
import { QuoteAdminPageComponent } from './quote-admin/components/quote-admin-page/quote-admin-page.component';
import { BlankComponent } from './shared/components/blank-component/blank.component';
import { isUserLoggedInGuard } from './core/guards/is-user-logged-in.guard';

const appRoutes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/login' },
  {
    path: '',
    component: GlobalPageLayoutComponent,
    children: [
      {
        path: '',
        component: InnerPageLayoutComponent,
        canActivateChild: [isUserLoggedInGuard],
        children: [
          {
            path: '',
            component: TopBannerLayoutComponent,
            children: [
              { path: AppUrl.NEWS, component: NewsPageComponent },
              { path: AppUrl.HELP, component: HelpPageComponent },
              {
                path: AppUrl.SEARCH_POLICY,
                component: PolicyAdminPageComponent,
              },
              { path: AppUrl.SEARCH_OFFER, component: QuoteAdminPageComponent },
              {
                path: AppUrl.REPORTS,
                loadComponent: () =>
                  import('./sales-report/pages/sales-report-page/sales-report-page.component').then(
                    (c) => c.SalesReportPageComponent,
                  ),
              },
              {
                path: AppUrl.SETTING,
                loadChildren: () =>
                  import('./settings/settings.module').then((m) => m.SettingsModule),
              },
            ],
          },
          {
            path: AppUrl.HOME,
            component: HomePageComponent,
            children: [
              {
                path: 'IT',
                loadChildren: () => import('./BUs/IT/it.routes').then((r) => r.itRoutes),
              },
              {
                path: '**', //This is will be a country code
                component: BlankComponent, //This is a placeholder component
              },
            ],
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
