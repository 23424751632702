import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { NonNullableFormBuilder, Validators } from '@angular/forms';
import { NX_MODAL_DATA, NxModalRef } from '@aposin/ng-aquila/modal';
import { BehaviorSubject, catchError } from 'rxjs';
import { UpdateOfferService } from 'src/app/quote-admin/services/update-offer/update-offer.service';
import { OfferStatus } from 'src/app/shared/enums/offer-status.enum';

type UpdateStatus = 'success' | 'error';
type UpdateOfferStatusDialogType = {
  allowedStatus: OfferStatus[];
  quoteId: string;
};

@Component({
  selector: 'app-update-offer-status-dialog',
  templateUrl: './update-offer-status-dialog.component.html',
  styleUrls: ['./update-offer-status-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpdateOfferStatusDialogComponent implements OnInit {
  protected readonly _statusForm = this.formBuilder.group({
    status: ['', [Validators.required]],
  });
  public allowedStatus: OfferStatus[] = [];
  private readonly _updateRequestStatus$ = new BehaviorSubject<UpdateStatus | null>(null);
  public readonly updateRequestStatus$ = this._updateRequestStatus$.asObservable();

  constructor(
    private readonly formBuilder: NonNullableFormBuilder,
    private readonly updateOfferService: UpdateOfferService,
    @Inject(NX_MODAL_DATA) private readonly dialogInputData: UpdateOfferStatusDialogType,
    private readonly modalRef: NxModalRef<UpdateOfferStatusDialogComponent>,
  ) {}

  public ngOnInit(): void {
    this.allowedStatus = this.dialogInputData.allowedStatus;
    this.modalRef.beforeClosed().subscribe(() => {
      this.modalRef.close(this.allowedStatus);
    });
  }

  public handleStatusFormSubmit(): void {
    if (this._statusForm.invalid) {
      return;
    }

    const statusFormValue = this._statusForm.controls.status.value;
    this.updateOfferService
      .updateStatus(statusFormValue, this.dialogInputData.quoteId)
      .pipe(
        catchError((error: Error) => {
          this._updateRequestStatus$.next('error');
          throw error;
        }),
      )
      .subscribe((data) => {
        if (data.operationStatus === 'success') {
          this.allowedStatus = this.allowedStatus.filter(
            (statusItem) => statusItem !== statusFormValue,
          );
          this._updateRequestStatus$.next('success');
        } else {
          this._updateRequestStatus$.next('error');
        }
      });
  }
}
