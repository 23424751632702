<div nxRow>
  <h2>{{ 'viewofferdetails.0.ADDACOMMENT' | translate }}</h2>
</div>
<div nxRow>
  <div nxCol="9">
    <nx-formfield
      appearance="outline"
      floatLabel="always"
    >
      <nx-formfield-label>{{ 'viewofferdetails.0.COMMENT' | translate }}</nx-formfield-label>
      <textarea
        nxInput
        type="text"
        maxlength="255"
        class="text-area-pop-up"
        [(ngModel)]="commentToUpdate"
      ></textarea>
    </nx-formfield>
  </div>
  <div nxCol="3">
    <button
      nxButton="primary small-medium"
      type="button"
      style="margin-top: 20% !important;"
      (click)="updateOffer(commentToUpdate)"
    >
      {{ 'viewofferdetails.0.SAVE' | translate }}
    </button>
  </div>
</div>
<div
  nxRow
  *ngIf="errorOnUpdate"
>
  <div nxCol="12">
    <nx-message
      context="error"
      class="nx-margin-bottom-xs"
    >
      {{ 'viewofferdetails.0.UPDATEOFFERERRORMSG' | translate }}
    </nx-message>
  </div>
</div>
<div
  nxRow
  *ngIf="successOnUpdate"
>
  <div nxCol="12">
    <nx-message
      context="success"
      class="nx-margin-bottom-xs"
    >
      {{ 'viewofferdetails.0.UPDATEOFFERSUCCESSMSG' | translate }}
    </nx-message>
  </div>
</div>
