import { LocalProjectInfo } from './project-info/local-project-info.type';
import { ExternalProjectInfo } from './project-info/external-project-info.type';

export type EnvironmentInfoDialogData =
  | SuccessEnvironmentInfoDialogData
  | FailedEnvironmentInfoDialogData;

export type SuccessEnvironmentInfoDialogData = Readonly<{
  infoRequestSuccess: true;
  localProject: LocalProjectInfo;
  externalProjects: ReadonlyArray<ExternalProjectInfo>;
}>;

export type FailedEnvironmentInfoDialogData = Readonly<{
  infoRequestSuccess: false;
  error: unknown;
}>;

export const isSuccessEnvironmentInfoDialogData = (
  dialogData: EnvironmentInfoDialogData,
): dialogData is SuccessEnvironmentInfoDialogData => dialogData.infoRequestSuccess;
