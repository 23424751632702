<nx-formfield
  class="text-field-control"
  [label]="_label"
  appearance="outline"
>
  <input
    #inputRef
    nxInput
    [placeholder]="_placeholder"
    type="text"
    (input)="handleInput(inputRef.value)"
  />
</nx-formfield>
