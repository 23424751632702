<div class="inner-page-layout">
  <header
    class="header"
    nx-header
  >
    <nx-header-row>
      <app-select-language></app-select-language>

      <nx-header-actions class="header-actions">
        <div>
          <app-welcome-message></app-welcome-message>
          <app-environment-info *ngIf="_isDevEnvironment"></app-environment-info>
        </div>
        <app-logout></app-logout>
      </nx-header-actions>
    </nx-header-row>

    <nx-header-row class="header-row">
      <app-inner-logo side="left"></app-inner-logo>
      <app-nav-header></app-nav-header>
      <app-inner-logo side="right"></app-inner-logo>
      <!--TODO IT Loyalty-->
    </nx-header-row>
  </header>

  <main class="main">
    <router-outlet></router-outlet>
  </main>
  <app-contact-us></app-contact-us>
</div>
