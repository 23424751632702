import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UriService {
  public encodeComponent(uriComponent: string | number | boolean): string {
    return encodeURIComponent(uriComponent);
  }
}
