<div class="reset-password-force-form">
  <form
    class="form"
    [formGroup]="_resetPasswordForceForm"
    (ngSubmit)="handleResetPasswordForceFormSubmit()"
  >
    <nx-formfield
      class="form__item"
      appearance="outline"
    >
      <span nxFormfieldPrefix>
        <nx-icon name="product-lock"></nx-icon>
      </span>
      <input
        [placeholder]="'settings.0.OLDPASS' | translate"
        nxInput
        type="password"
        formControlName="oldPassword"
      />
    </nx-formfield>

    <nx-formfield>
      <span nxFormfieldPrefix>
        <nx-icon name="product-lock"></nx-icon>
      </span>
      <input
        [placeholder]="'settings.0.NEWPASS' | translate"
        nxInput
        type="password"
        formControlName="newPassword"
      />
      <!-- This trick is needed to inject rendered html into the tooltip -->
      <div
        #renderedTooltipMessage
        class="rendered-tooltip-message"
        [innerHTML]="'passchangepage.0.REQDPASS' | translate"
      ></div>
      <app-info-icon
        class="password-requirements-info"
        [nxTooltip]="renderedTooltipMessage.textContent || ''"
        nxTooltipPosition="top"
        nxFormfieldSuffix
      ></app-info-icon>
      <nx-error
        *ngIf="
      _resetPasswordForceForm.controls.newPassword.errors
          | hasError : 'passwordFormat'
        "
        appearance="text"
        nxFormfieldError
      >
        <span>{{ "passchangepage.0.PASSMATCHWITHCRITERIA" | translate }}</span>
      </nx-error>
      <nx-error
        *ngIf="
        _resetPasswordForceForm.controls.newPassword.errors
            | hasError : 'controlsDoNotMatch'
        "
        appearance="text"
        nxFormfieldError
      >
        <span>{{ "settings.0.CS_005" | translate }}</span>
      </nx-error>
    </nx-formfield>

    <nx-formfield
      class="form__item"
      appearance="outline"
    >
      <span nxFormfieldPrefix>
        <nx-icon name="product-lock"></nx-icon>
      </span>
      <input
        [placeholder]="'settings.0.CONNEWPASS' | translate"
        nxInput
        type="password"
        formControlName="confirmPassword"
      />
      <nx-error
        *ngIf="
        _resetPasswordForceForm.controls.confirmPassword.errors
            | hasError : 'controlsMatch'
          "
        appearance="text"
        nxFormfieldError
      >
        <span>{{ "settings.0.PASSMISMATCH" | translate }}</span>
      </nx-error>
    </nx-formfield>
    <button
      class="form__item login-button"
      [disabled]="_resetPasswordForceForm.invalid || _onePortalMessageCode !== null"
      nxButton="primary"
      type="submit"
    >
      <span>{{ "settings.0.CHANGEPASS" | translate }}</span>
    </button>
  </form>

  <nx-error
    class="backend-error-container"
    *ngIf="_onePortalMessageCode"
    appearance="message"
  >
    <span>{{ "settings.0." + _onePortalMessageCode | translate }}</span>
  </nx-error>
</div>
