<div class="environment-info">
  <span class="label">{{ "tripdetailpage.0.ENVIRONMENT" | translate }} </span>
  <span
    class="name"
    (click)="handleCurrentEnvironmentNameClick()"
    >{{
    _currentEnvironmentName | uppercase
    }}</span
  >
</div>
