import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { HostService } from '../../../shared/services/host/host.service';
import { DOCUMENT } from '@angular/common';
import { LOCATION } from '../../deprecated-global-dependencies/location.global-dependency';
import { ErrorService } from '@agentmax/shared/services/error/error.service';

const HREF_TO_MONDIAL_ASSISTANCE_ICON = 'mondial-assistance-icon.png';
const HREF_TO_ALLIANZ_TRAVEL_ICON = 'favicon.ico';
const HREF_TO_AGENTMAX_ICON = 'agentmax-icon.png';
const MONDIAL_CODE = 'MD';

const DEFAULT_ICON_ELEMENT_ID = 'app-icon';
const DEFAULT_SHORTCUT_ICON_ELEMENT_ID = 'app-shortcut-icon';

@Injectable({
  providedIn: 'root',
})
export class BrowserTabIconService {
  private readonly renderer2: Renderer2;
  private readonly iconElement = this.getIconElementById();
  private readonly shortcutIconElement = this.getShortcutIconElementById();

  constructor(
    private readonly hostService: HostService,
    private readonly rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(LOCATION) private readonly location: Location,
    private readonly errorService: ErrorService,
  ) {
    this.renderer2 = rendererFactory.createRenderer(null, null);
  }

  public setTabIconBeforeLogin(): void {
    const browserTabIconHref = this.determineIconHrefByHost();
    this.updateIconHrefTo(browserTabIconHref);
  }

  public setTabIconAfterLogin(agencyBrand: string | null): void {
    const browserTabIconHref = this.determineIconHrefByBrand(agencyBrand);
    this.updateIconHrefTo(browserTabIconHref);
  }

  private determineIconHrefByHost(): string {
    const host = this.location.host;
    if (this.hostService.isAllianzPartnersHost(host)) {
      return HREF_TO_ALLIANZ_TRAVEL_ICON;
    }

    if (this.hostService.isMondialAssistanceHost(host)) {
      return HREF_TO_MONDIAL_ASSISTANCE_ICON;
    }

    if (this.hostService.isGlobalAssistanceHost(host)) {
      return HREF_TO_ALLIANZ_TRAVEL_ICON;
    }

    if (this.hostService.isAllianzTravelHost(host)) {
      return HREF_TO_ALLIANZ_TRAVEL_ICON;
    }

    return HREF_TO_AGENTMAX_ICON;
  }

  private determineIconHrefByBrand(brand: string | null): string {
    if (brand === MONDIAL_CODE) {
      return HREF_TO_MONDIAL_ASSISTANCE_ICON;
    }

    return HREF_TO_ALLIANZ_TRAVEL_ICON;
  }

  private updateIconHrefTo(newIconHref: string): void {
    this.renderer2.setAttribute(this.iconElement, 'href', newIconHref);
    this.renderer2.setAttribute(this.shortcutIconElement, 'href', newIconHref);
  }

  private getIconElementById(id?: string): HTMLElement {
    const iconElementId = id || DEFAULT_ICON_ELEMENT_ID;
    const docIconElement = this.document.getElementById(iconElementId);

    if (docIconElement === null) {
      this.errorService.throwError(this.constructor.name, 'Icon element is not found!');
    }

    return docIconElement;
  }

  private getShortcutIconElementById(id?: string): HTMLElement {
    const shortcutIconElementId = id || DEFAULT_SHORTCUT_ICON_ELEMENT_ID;
    const docShortcutIconElement = this.document.getElementById(shortcutIconElementId);

    if (docShortcutIconElement === null) {
      this.errorService.throwError(this.constructor.name, 'Shortcut icon element is not found!');
    }

    return docShortcutIconElement;
  }
}
