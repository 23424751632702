import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NxSpinnerModule } from '@aposin/ng-aquila/spinner';

@Component({
  selector: 'app-loading-overlay',
  templateUrl: './loading-overlay.component.html',
  styleUrls: ['./loading-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NxSpinnerModule],
})
export class LoadingOverlayComponent {}
