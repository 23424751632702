<div class="session-expires-soon-dialog">
  <p class="session-expires-message">{{ "loginpage.0.SESSEXP" | translate }}</p>

  <div class="actions">
    <button
      class="actions__item"
      nxButton="primary"
      type="button"
      (click)="handleLogoutButtonClick()"
    >
      {{ "loginpage.0.CANCEL" | translate }}
    </button>

    <button
      class="actions__item"
      nxButton="primary"
      type="button"
      (click)="handleContinueButtonClick()"
    >
      {{ "loginpage.0.CONTINUE" | translate }}
    </button>
  </div>
</div>
