import { Injectable, inject } from '@angular/core';

import { SelectedLanguageCodeSessionStorageService } from '@agentmax/shared/session-storage/selected-language-code-session-storage.service';
import { LanguageOptionsNameSessionStorageService } from '@agentmax/shared/session-storage/language-options-name-session-storage.service';

import { ILanguagesInitializationService } from './languages-initialization.service.interface';
import { LanguageOptionsName } from '@agentmax/shared/enums/language-options-name.enum';
import { NewLanguageCode } from '@agentmax/shared/enums/language-code.enum';

@Injectable({
  providedIn: 'root',
})
export class SessionStorageLanguagesInitializationService
  implements ILanguagesInitializationService
{
  private readonly _selectedLanguageCode = inject(SelectedLanguageCodeSessionStorageService);
  private readonly _languageOptionsName = inject(LanguageOptionsNameSessionStorageService);

  public getLanguageOptionsName(): LanguageOptionsName {
    return this._languageOptionsName.get();
  }

  public getSelectedLanguageCode(): NewLanguageCode {
    return this._selectedLanguageCode.get();
  }
}
